// @flow

import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import ReactDOM from "react-dom";
import { Button } from "components";
import classnames from "classnames";
import { getConfirmationWrapperStyles } from "utils/common";
import "./ConfirmationPopup.scss";

type Props = {
  text: string,
  acceptLabel: string,
  cancelLabel: string,
  onCancel: () => void,
  onAccept: () => void,
  /** when loading the accept button is disabled */
  isLoading?: boolean,
  /** only used when the popup is clipped by overflowing ancestor */
  displaceRefObj?: {
    /** reference element for the popup */
    refEle: Object,
    /** if the popup is for profile icon, add specific styles */
    forProfileIcon?: boolean,
    forListItem?: boolean
  },
  classes?: string
};

const ConfirmationPopup = memo(({
  text,
  onCancel,
  acceptLabel,
  cancelLabel,
  onAccept,
  isLoading = false,
  displaceRefObj: {
    refEle = null,
    forProfileIcon = false,
    forListItem = false
  } = {},
  classes = ""
}: Props) => {
  const [styles, setStyles] = useState({});
  const clickRef = useRef(null);
  const mount = useRef(document.getElementById("portal-root"));
  const ele = useRef(document.createElement("div"));

  const applyStyles = useCallback(() => {
    if(refEle?.current && clickRef?.current) {
      setStyles(getConfirmationWrapperStyles(refEle.current, { forProfileIcon, forListItem, popup: clickRef?.current }));
    }
  }, [refEle, forProfileIcon, forListItem, clickRef]);

  useEffect(() => {
    applyStyles();
  }, [applyStyles]);

  useEffect(() => {
    const ele = refEle?.current;
    ele && window.addEventListener("resize", applyStyles);

    return () => {
      ele && window.removeEventListener("resize", applyStyles);
    };
  }, [refEle, applyStyles]);

  const preventPropagation = useCallback((e) => {
    // prevent click event being propagated to parent element (except for the button)
    if(e.target.nodeName !== "BUTTON") {
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    const ele = clickRef?.current;
    ele && ele.addEventListener("click", preventPropagation);

    return () => {
      ele && ele.removeEventListener("click", preventPropagation);
    }
  }, [preventPropagation]);

  const jsx = (
    <div
      className={classnames(
        "axiom-confirmation-popup",
        classes,
        {
          "hidden": !onCancel,
          "displace": refEle
      })}
      data-testid="Confirmation-Popup"
      style={styles}
      ref={clickRef}
    >
      <div className="confirmation-box">
        <div className="confirm-text">
          <p className="text" data-testid="text">
            {text}
          </p>
        </div>
        <div className="button-confirmation">
          <Button
            text={acceptLabel}
            click={onAccept}
            classes="popup-accept-button"
            buttonType="popup"
            disabled={isLoading}
          />
          <Button
            text={cancelLabel}
            click={onCancel}
            classes="popup-cancel-button"
            buttonType="popup"
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const el = ele.current;
    const container = mount.current;
    if(el && container) {
      container.appendChild(el);
      applyStyles();
    }

    return () => {
      if(el && container) {
        container.removeChild(el);
      }
    };
  }, [applyStyles]);

  if(refEle) {
    return ReactDOM.createPortal(jsx, ele.current);
  } else {
    return jsx;
  }
});

ConfirmationPopup.type.displayName = "ConfirmationPopup";

export { ConfirmationPopup };
