import {
  ADD_FEEDBACKS_SUCCESS,
  UPDATE_FEEDBACKS_SUCCESS,
  DELETE_FEEDBACKS_SUCCESS,
  CLEAR_ALL_FEEDBACKS
} from "action-types";

const initialState = [];
const feedbacks = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
    case ADD_FEEDBACKS_SUCCESS:
      return [...state, ...payload];
    case CLEAR_ALL_FEEDBACKS:
      return initialState;
    case UPDATE_FEEDBACKS_SUCCESS:
      return state.map((feedback) =>
      feedback.id === payload.id ? payload : feedback
      );
    case DELETE_FEEDBACKS_SUCCESS:
      return state.filter((feedback) => feedback.id !== payload.id);
    default:
      return state;
  }
};

export default feedbacks;