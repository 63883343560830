// @flow

import React, { useState, useCallback, useRef, memo } from "react";
import {
  ListContainer,
  ProfilePicture,
  ToolTip,
  ConfirmationPopup,
} from "components";
import { useSelector, useDispatch } from "react-redux";
import { isAdminOrMasteradmin, getErrorMessage, redirectToRoute, isListItemAndNotOpen } from "utils/common";
import { generatePath } from 'react-router-dom';
import { setAlertMsg } from "actions/alertMsg";
import { deleteGroupList } from "actions/groupActions";
import { useClickOutside } from "hooks/useClickOutside";
import GroupService from "services/groupsService";
import { GROUPS, USERS } from "models/routes";
import fixture from "./fixture.json";
import classnames from "classnames";

import "./Group.scss";

const { dropdownData } = fixture;

type Props = {
  name: string,
  id: number,
  count: number,
  members: Array<Object>,
};

const Group = memo(({ name, id, count, members }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const userRole = useSelector((state) => state.auth.role);
  const adminOrMadmin = isAdminOrMasteradmin(userRole);

  const dispatch = useDispatch();

  // if there are 6 exact members in a group show all members
  const limitVal = members.length === 6 ? 6 : 5;

  const shownUsers = members.slice(0, limitVal);
  const hiddenUsers = members.slice(limitVal);

  const popupParent = useRef();
  const popupWrapper = useRef(null);

  const onOutsideClick = useCallback((e) => {
    setShowPopup(false);
    let ele = e.target;
    if(!isListItemAndNotOpen(ele, showDeletePopup)) {
      setShowDeletePopup(false);
    }
  }, [showDeletePopup]);

  useClickOutside(popupParent, onOutsideClick);

  const groupClickHandler = useCallback(
    (id) => {
      redirectToRoute(generatePath(GROUPS, { id }));
    },
    []
  );

  const onSelect = useCallback(
    (listId) => {
      if (listId === 1 || listId === 3) {
        groupClickHandler(id);
      } else {
        setShowDeletePopup(true);
      }
    },
    [id, groupClickHandler]
  );

  const clickHandler = useCallback(
    (e, id) => {
      e.stopPropagation();
      redirectToRoute(generatePath(USERS, { id }));
    },
    []
  );

  const handleDeleteGroup = useCallback(async (id) => {
    setLoading(true);
    try {
      await GroupService.deleteGroup(id);
      dispatch(deleteGroupList({ id }));
    } catch (e) {
      const msg = getErrorMessage(e) || "Error in deleting group";
      dispatch(setAlertMsg({ type: "error", msg }));
    } finally {
      setLoading(false);
      setShowDeletePopup(false);
    }
  }, [dispatch]);

  const dotClasses = classnames({
    "middle-dot": true,
    "dots-active": showPopup,
  });

  const usersContainerClasses = classnames({
    "more-users-container": true,
    active: onHover,
  });

  return (
    <div className="axiom-group-wrapper" onClick={() => groupClickHandler(id)}>
      <div className="title-more-options-wrapper">
        <ToolTip title={name} position="top" displace={true}>
          <div className="title">{name}</div>
        </ToolTip>
        {adminOrMadmin ? (
          <div
            className="more-options"
            ref={popupParent}
            role="button"
            tabIndex={0}
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={dotClasses}></div>
            {showPopup && !showDeletePopup ? (
              <div className="group-container" data-testid="group-popup">
                <ListContainer
                  type="dropdown-list"
                  classname="group-popup"
                  data={dropdownData}
                  onSelect={(id) => onSelect(id)}
                />
              </div>
            ) : null}
            {showDeletePopup ? (
              <div className="delete-confirmation" ref={popupWrapper}>
                <ConfirmationPopup
                  text="Are you sure to delete this group?"
                  acceptLabel="Yes, Delete"
                  cancelLabel="Cancel"
                  onAccept={() => handleDeleteGroup(id)}
                  onCancel={() => setShowDeletePopup(false)}
                  isLoading={loading}
                  displaceRefObj={{
                    refEle: popupWrapper,
                    forListItem: true
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="members-count">{`${count} Members`}</div>
      <div className="members">
        {shownUsers.map(({ id: userId, name: userName }) => (
          <div key={userId} className="shown-user">
            <ToolTip title={userName} position="top">
              <div onClick={(e) => clickHandler(e, userId)}>
                <ProfilePicture
                  key={userId}
                  username={userName}
                  type="profile-icon"
                  classname="profile-group"
                />
              </div>
            </ToolTip>
          </div>
        ))}

        {hiddenUsers.length ? (
          <div className={usersContainerClasses}>
            <ToolTip
              title=""
              position="right"
              displayHTML={hiddenUsers
                .slice(0, 10)
                .map(({ name: userName }) => (
                  <div className="member-name" key={userName}>{userName}</div>
                ))}
              displace={true}
            >
              <div
                className="more-wrapper"
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
              >
                <span>{`${hiddenUsers.length}+`}</span>
              </div>
            </ToolTip>
          </div>
        ) : null}
      </div>
    </div>
  );
});

Group.type.displayName = "Group";

export { Group };
