// @flow

import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { PillButton, SearchList } from "components";
import classnames from "classnames";
import type { TList } from "models/api/list";
import { List } from "models/api/list";
import "./AddTo.scss";

type Props = {
  callFunction: (any) => void,
  isEmail?: boolean,
  internalOnly?: boolean,
  noInternal?: boolean,
  title: string,
  classname: string,
  setSelectedData: (TList) => void,
  selectedData: TList,
  showList?: boolean,
  showTooltipForPb?: boolean,
  tpPos?: string,
  tpText?: string,
  inputRef?: HTMLInputElement
};

const AddTo = memo(({
  callFunction = () => null,
  // if the callFunction is to get list of users with id and email then isEmail must be true, it is false by default
  isEmail = false,
  // internalOnly denotes to get internl users only
  internalOnly = false,
  // when noInternal is true, all groups are shown except the groups where internalOnly is true
  noInternal = false,
  title,
  classname,
  setSelectedData,
  selectedData,
  showList = true, // If not passed, true by default
  // optional params (show tooltip for pill button)
  showTooltipForPb = false,
  tpPos = "",
  tpText = "",
  inputRef
}: Props) => {
  const [value, setValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const searchListContainer = useRef<HTMLDivElement | null>(null);

  const clearValues = useCallback(() => {
    setValue("");
    setFilteredData([]);
  }, []);

  const getResponse = useCallback(async () => {
    const args = [value];
    const obj = {};

    if(isEmail) {
      obj.emailOnly = true;
    }

    if(internalOnly) {
      obj.internalOnly = true;
    }

    if(noInternal) {
      obj.noInternal = true;
    }

    args.push(obj);

    try {
      const { data } = await callFunction(...args);
      const listData = data.map((usr) => new List(usr));
      setResponseData(listData);
    } catch(err) {
    }
  }, [value, callFunction, isEmail, internalOnly, noInternal]);

  const filterData = useCallback(() => {
    if (value) {
      const newFData = [];

      const data = [...responseData];

      // remove the selected data from the filteredData array
      data.forEach((fData) => {
        const dt = selectedData.find((sData) => sData.id === fData.id);
        if (!dt) {
          newFData.push(fData);
        }
      });
      setFilteredData(newFData);
    } else {
      setFilteredData([]);
    }
  }, [responseData, selectedData, value]);

  useEffect(() => {
    filterData();
  }, [value, filterData]);

  const onSelect = useCallback(
    (id) => {
      // get the newly selected data
      const data = filteredData.find((fData) => fData.id === id);

      // add it to the selectedData array
      setSelectedData([...selectedData, data]);
      setValue("");
    },
    [selectedData, setSelectedData, filteredData]
  );

  const onRemove = useCallback(
    (id) => {
      const sData = selectedData.filter((data) => data.id !== id);
      setSelectedData(sData);
    },
    [selectedData, setSelectedData]
  );

  useEffect(() => {
    filterData();
  }, [selectedData, filterData]);


  return (
    <div
      className={classnames({
        "axiom-add-to": true,
        [classname]: classname ? true : false,
      })}
    >
      {title && (
        <div className="title-wrapper">
          <span className="title">{title}</span>
          <span className="optional">(Optional)</span>
        </div>
      )}
      <div className="search-list-container" ref={searchListContainer}>
        <SearchList
          classname=""
          searchObj={{
            value,
            placeholder: "Search",
            updateValue: setValue,
            clearValue: clearValues,
            makeAPICall: getResponse,
            delay: 500,
            inputRef
          }}
          listObj={{
            data: filteredData,
            onSelect
          }}
        />
      </div>
      {showList && (
        <div className="selected-data-wrapper">
          {selectedData.map((data) => {
            return (
              <PillButton
                key={data.id}
                dataId={data.id}
                size="md"
                showTooltip={showTooltipForPb}
                tpPos={tpPos}
                tpText={tpText}
                text={data.name}
                onRemove={onRemove}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});

AddTo.type.displayName = "AddTo";

export { AddTo };
