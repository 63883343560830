import { UPDATE_INFO, CLEAR_INFO } from "action-types";

const initialState = {
  user: null,
  group: null,
  project: null,
  folder: null,
  build: null,
  feedback: null
};

const infoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch(type) {
    case UPDATE_INFO:
      return { ...state, ...payload };
    case CLEAR_INFO:
      return initialState;
    default:
      return state;
  }
};

export default infoReducer;
