import { AUTH_SUCCESS, AUTH_ERROR } from "action-types";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  role: "admin",
  id: null,
  manager: false,
  isTokenVerified: false,
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_SUCCESS:
      return { ...state, ...payload };
    case AUTH_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
