// @flow

import React, { memo } from "react";
import classnames from "classnames";
import { Icon } from "components";
import "./ProfilePicture.scss";

// type is "profile-image" for user profile (small and large size using classname)
// type is "profile-icon" for user icon

type props = {
  username: string,
  type: "profile-image" | "profile-icon",
  classname: string,
  dataId: number,
  onRemove?: (number) => void,
};

const ProfilePicture = memo(({
  username,
  type,
  classname,
  onRemove,
  dataId,
}: props) => {
  const onClick = () => {
    onRemove && onRemove(dataId);
  };

  const initials = username
    ? username
        .split(" ")
        .map((name) => name[0])
        .join("")
        .toUpperCase()
    : null;

  const wrapperclass = classnames({
    [(type: string)]: true,
    [classname]: true,
  });
  return (
    <div data-testid="profile-picture" className={wrapperclass}>
      <div className={`${type}-container`}>{initials}</div>
      {onRemove ? (
        <div className="profile-clear-icon" onClick={onClick} tabIndex={0}>
          <Icon name="clear-icon" />
        </div>
      ) : null}
    </div>
  );
});

ProfilePicture.type.displayName = "ProfilePicture";

export { ProfilePicture };
