// @flow

import React from 'react';
import classnames from "classnames";
import "./Loader.scss";

type Props = {
  type ?: "basic" | "inline",
  className?: string 
};

const Loader = ({ type = "basic", className = "" }: Props) => {
  const isInline = type === "inline";
  const classes = classnames("axiom-loader", className, {
    "inline": isInline 
  });

  return (
    <div className={classes} role="alert" aria-busy="true">
      {!isInline ? (  
        <div className="loader">
          <div className="loader-outter" />
          <div className="loader-inner" />
        </div>
      ) : (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export { Loader };