// User
export const ADD_USER = "add_user";
export const USER_PROFILE_VIEW = "user_profile_view";
export const MY_PROFILE_VIEW = "me";
export const USER_LOGOUT = "log_out";

// Notification
export const NOTIFICATION_VIEW = "notifications";

// Feedback
export const FEEDBACK_VIEW = "feedback";

// Group
export const CREATE_GROUP = "create_group";
export const GROUP_DETAILS_VIEW = "group_details_view";

// Project
export const CREATE_PROJECT = "create_project";
export const EDIT_PROJECT = "edit_project";
export const PROJECT_BUILDS = "builds";
export const PROJECT_TESTERS = "testers";
export const PROJECT_GROUPS = "groups";
export const PROJECT_CI_CD = "ci_cd";
export const PROJECT_SETTINGS = "settings";
export const ANDROID = "android";
export const IOS = "ios";
export const WEB = "web";
export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const DELETED = "deleted";
export const UPLOAD = "upload";
export const UPLOADING = "uploading";
export const UPLOADED = "uploaded";

// Build
export const UPLOAD_BUILD = "upload_build";

// dropdown data texts used in Projects (these text should be same as the text used in project fixture)
export const TXT_ARCHIVE = "Archive";
export const TXT_UNARCHIVE = "Unarchive";
export const TXT_ADD_TESTERS = "Add Testers";
export const TXT_ADD_NEW_BUILD = "Add New Build";

// texts used in MyProfile
export const PROJECTS = "Projects";
export const GROUPS = "Groups";
export const USERS = "Users";

// texts used in Sidebar
export const TXT_PROFILE = "profile";
export const TXT_LOGOUT = "logout";
export const TXT_NOTIFICATION = "notifications";
export const TXT_FEEDBACK = "feedback";

// user roles (roles should match with the ones used in backend)
export const ADMIN = "Admin";
export const MASTER_ADMIN = "Master Admin";
export const USER = "User";
export const INTERNAL = "internal";
export const EXTERNAL = "external";

// company domain
export const COMPANY_DOMAIN = "@codeandtheory.com";

export const M_TEXT_PROJECTS = "projects";
export const M_TEXT_FOLDERS = "folders";
export const M_TEXT_BUILDS = "builds";
export const M_TEXT_LATEST_BUILD = "latest-build";

// max limit after which the element shouldn't be dragged in MDevice ('-' means to the left)
export const M_LEFT_OFFSET_LIMIT = -75;

// notification permission values
export const GRANTED = "granted";
export const DENIED = "denied";
export const DEFAULT = "default";

// query latest build (used as query param)
export const LATEST_BUILD_QUERY = "build=latest";

export const HORIZONTAL = "horizontal";
export const VERTICAL = "vertical";

// mime type for android files
export const ANDROID_MIME_TYPE = "application/vnd.android.package-archive";

// link to slack channel
export const SLACK_CHANNEL = "https://ymedialabs.slack.com/archives/CU46HGYP6";

export const ADD_TESTER_TEXT = "Adding new users to group instead of project is better access management";

export const ADD_MEMBER_TEXT = "User will get access to older builds that the group has access to";
