// @flow

import React, { useRef, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import classnames from "classnames";
import Loadable from 'react-loadable';
import { hideSidePanel } from "actions/sidePanel";
import { useQuery } from "hooks/useQuery";
import { getBasePath } from "utils/common";
import { useLocation, useParams, generatePath } from "react-router-dom";
import { GROUPS, PROJECTS, USERS, FEEDBACKS } from "models/routes";
import { Loader } from "components";
import {
  ADD_USER,
  CREATE_GROUP,
  MY_PROFILE_VIEW,
  NOTIFICATION_VIEW,
  FEEDBACK_VIEW,
  CREATE_PROJECT,
  EDIT_PROJECT,
  PROJECT_BUILDS,
  PROJECT_TESTERS,
  PROJECT_GROUPS,
  PROJECT_CI_CD,
  PROJECT_SETTINGS,
  UPLOAD_BUILD,
} from "constants.js";

import "./SidePanel.scss";

const AddUser = Loadable({
  loader: () => import("views/AddUser"),
  loading: () => <Loader className="md center" />
});

const UserDetails = Loadable({
  loader: () => import("views/UserDetails"),
  loading: () => <Loader className="md center" />
});

const GroupDetails = Loadable({
  loader: () => import("views/GroupDetails"),
  loading: () => <Loader className="md center" />
});

const AddProject = Loadable({
  loader: () => import("views/AddProject"),
  loading: () => <Loader className="md center" />
});

const CreateGroup = Loadable({
  loader: () => import("views/CreateGroup"),
  loading: () => <Loader className="md center" />
});

const MyProfile = Loadable({
  loader: () => import("views/MyProfile"),
  loading: () => <Loader className="md center" />
});

const NotificationView = Loadable({
  loader: () => import("views/NotificationView"),
  loading: () => <Loader className="md center" />
});

const Feedback = Loadable({
  loader: () => import("views/Feedback"),
  loading: () => <Loader className="md center" />
});

const ProjectView = Loadable({
  loader: () => import("views/ProjectView"),
  loading: () => <Loader className="md center" />
});

const BuildView = Loadable({
  loader: () => import("views/BuildView"),
  loading: () => <Loader className="md center" />
});

const FeedbackDetails = Loadable({
  loader: () => import("views/FeedbackDetails"),
  loading: () => <Loader className="md center" />
});

const SidePanel = memo((props: {}) => {
  const { open, closing } = useSelector((state) => state.sidePanel);
  const user = useSelector((state) => state.info.user) || {};
  const group = useSelector((state) => state.info.group) || {};
  const project = useSelector((state) => state.info.project) || {};
  const feedback = useSelector((state) => state.info.feedback) || {};

  const timeOutRef = useRef(null);
  const sidePanel = useRef(null);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const basePath = getBasePath(pathname);
  const params = useParams();

  // hold info about the current panel
  const { panel } = useQuery();

  const my_profile_view = panel === MY_PROFILE_VIEW;
  const notification_view = panel === NOTIFICATION_VIEW;
  const feedback_view = panel === FEEDBACK_VIEW;
  
  let add_user = false;
  let create_group = false;
  let create_project = false;
  let edit_project = false;
  let user_profile_view = false;
  let group_details_view = false;
  let feedback_details = false;
  let project_builds = false;
  let project_testers = false;
  let project_groups = false;
  let project_ci_cd = false;
  let project_settings = false;
  let upload_build = false;

  if(basePath === generatePath(USERS)) {
    if(panel === ADD_USER) {
      add_user = true;
    } else if (params.id || user.id) {
      user_profile_view = true;
    }
  }

  if(basePath === generatePath(FEEDBACKS) && (params.id || feedback.id)) {
    feedback_details = true;
  }

  if(basePath === generatePath(GROUPS)) {
    if(panel === CREATE_GROUP) {
      create_group = true;
    } else if (params.id || group.id) {
      group_details_view = true;
    }
  }

  if(basePath === generatePath(PROJECTS)) {
    switch(panel) {
      case CREATE_PROJECT: create_project = true;
        break;
      case EDIT_PROJECT: edit_project = true;
        break;
      case UPLOAD_BUILD: upload_build = true;
        break;
      default:
    }

    if(params.id || project.id) {
      switch(panel) {
        case PROJECT_BUILDS: project_builds = true;
          break;
        case PROJECT_TESTERS: project_testers = true;
          break;
        case PROJECT_GROUPS: project_groups = true;
          break;
        case PROJECT_CI_CD: project_ci_cd = true;
          break;
        case PROJECT_SETTINGS: project_settings = true;
          break;
        default:
      }
    }
  }

  const containerClasses = classnames({
    "axiom-sidepanel-container": true,
    // handle classes right or left-offset or left  based on the Sidepanel location
    //  show sidepanel from right
    "sp-right":
      add_user ||
      create_group ||
      user_profile_view ||
      create_project ||
      group_details_view ||
      feedback_details ||
      edit_project,

    // show sidepanel from the left with offset (equal to width of the sidebar)
    "sp-left-offset":
      // TODO: update the condition
      ( my_profile_view && !group_details_view ) ||
      notification_view ||
      feedback_view ||
      project_builds ||
      project_testers ||
      project_groups ||
      project_ci_cd ||
      project_settings,

    // show sidepanel from the left
    "sp-left": upload_build,

    // additional classes
    "user-details": user_profile_view|| my_profile_view,
    "group-details": group_details_view,
    "feedback-details": feedback_details,
    "project-details": 
    project_builds ||
    project_testers ||
    project_groups ||
    project_ci_cd ||
    project_settings ||
    feedback_view,
    "add-user": add_user,
    "upload-build": upload_build,
  });

  useEffect(() => {
    const ele = sidePanel.current;

    if(open) {
      if (!closing && (panel || params.id)) {
        ele && ele.classList.add("move-in");
      } else if(closing) {
        ele && ele.classList.remove("move-in");
        // 0.5s is the delay added for the transition to complete
        timeOutRef.current = setTimeout(() => {
          dispatch(hideSidePanel());
        }, 500);
      }
    }
  }, [open, dispatch, closing, panel, params.id]);

  // unmount cleanup
  useEffect(() => () => clearTimeout(timeOutRef.current), []);

  let content;

  switch (true) {
    case add_user:
      content = <AddUser />;
      break;
    case create_group:
      content = <CreateGroup />;
      break;
    case user_profile_view:
      content = <UserDetails />;
      break;
    case create_project:
    case edit_project:
      content = <AddProject />;
      break;
    case group_details_view:
      content = <GroupDetails />;
      break;
    case feedback_details:
      content = <FeedbackDetails />;
      break;
    case project_builds:
    case project_testers:
    case project_groups:
    case project_ci_cd:
    case project_settings:
      content = <ProjectView />;
      break;
    case upload_build:
      content = <BuildView />;
      break;
    case my_profile_view:
      content = <MyProfile />;
      break;
    case notification_view:
      content = <NotificationView />;
      break;
    case feedback_view:
      content = <Feedback />;
      break;
    default:
      return null;
  }
  if (open) {
    return (
      <div className={containerClasses} ref={sidePanel} data-testid="sidepanel">
        {content}
      </div>
    );
  } else {
    return null;
  }
});

SidePanel.type.displayName = "SidePanel";

export { SidePanel };
