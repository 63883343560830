//@flow
import React, { useEffect, memo } from "react";
import signin from "assets/images/signin.png";
import axiomlogo from "assets/images/axiom-logo.png";
import googleimg from "assets/images/google.png";
import fixture from "./fixture.json";
import { tryAutoLogin } from "services/authentication";
import { BACKEND_URL } from "utils/common";
import "./signin.scss";

const logInURL = `${BACKEND_URL}/connect/google`;

const SignIn = memo(() => {
  useEffect(() => {
    tryAutoLogin();
  }, []);

  return (
    <div className="sign-in" data-testid="signin">
      <div className="sign-in-img">
        <img src={signin} alt="SignIn" />
      </div>
      <div className="sign-in-details">
        <div className="sign-in-info">
          <div className="logo">
            <img src={axiomlogo} alt="Axiom Logo" />
          </div>
          <div>
            <p className="sign-in-heading"> {fixture.signIn.signInHeading} </p>
            <p className="sign-in-text">{fixture.signIn.welcomeText}</p>
          </div>
          <div className="sign-in-button">
            <a className="sign-in-btn-wrapper" href={logInURL}>
              <img className="google-img" src={googleimg} alt="Google Icon" />
              <span>Sign In with Google</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

SignIn.type.displayName = "SignIn";

export default SignIn;
