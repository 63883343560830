import React, { useReducer, createContext } from "react";

import { reducer, initialState } from "./reducer";

export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SearchContext.Provider value={{
      search: state,
      searchDispatch: dispatch
    }}>
      {children}
    </SearchContext.Provider>
  );
}