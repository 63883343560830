import { AuthUser } from "./authenticate";
import type { TAuthUser } from "./authenticate";

export type TMobileProfile = TAuthUser & {
  notification: boolean
};

export class MobileProfile extends AuthUser {
  +notification: boolean;

  constructor({ notification, ...rest }: Object) {
    super(rest);
    this.notification = notification;
  }
};