import { SHOW_OVERLAY, HIDE_OVERLAY } from "action-types";

export const showOverlay = () => {
  return {
    type: SHOW_OVERLAY,
  };
};

export const hideOverlay = () => {
  return {
    type: HIDE_OVERLAY,
  };
};
