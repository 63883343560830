// @flow

import React, { useCallback, useState, memo } from "react";
import { Button, Icon, ToolTip } from "components";
import classnames from "classnames";
import { UPLOAD, UPLOADING, UPLOADED } from "constants.js";
import "./ImageUpload.scss";

type AppObj = {
  uploadStatus: UPLOAD | UPLOADING | UPLOADED,
  onClick: () => void,
  completePercentage: number,
};

type Props = {
  /** type of file to be uploaded */
  type?: "image" | "app" | "feedback-img",
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
  /** used when the prop type is image to display the icon */
  icon?: string,
  /** used to check if any item is selected */
  inputRef: Object | null,
  /** used when the prop type is app */
  isFileSelected: boolean,
  appObj?: AppObj,
  onDrop?: Function
};

const ImageUpload = memo(({
  // by default upload images
  type = "image",
  onChange,
  icon,
  inputRef = null,
  isFileSelected = false,
  appObj: { uploadStatus = UPLOAD, onClick, completePercentage } = {},
  onDrop = null
}: Props) => {

  const [counter, setCounter] = useState(0);

  const isTypeApp = type === "app";
  const isTypeFeedbackImg = type === "feedback-img";
  
  const disabled = isTypeApp && uploadStatus !== UPLOAD;

  const imageUploadWrapper = classnames({
    "image-upload-wrapper": true,
    "image-border": icon || isFileSelected ? true : false,
    "app-upload": isTypeApp,
    "feedback-icon": isTypeFeedbackImg,
    "highlight-wrapper": counter !== 0,
    "disabled": disabled
  });
  
  const cancel = useCallback((e) => {
    e.preventDefault();
  }, []);

  const dragEnter = useCallback((e) => {
    cancel(e);
    setCounter(c => c + 1);
  }, [cancel]);

  const dragLeave = useCallback((e) => {
    cancel(e);
    setCounter(c => c - 1);
  }, [cancel, ]);

  const drop = useCallback((e) => {
    cancel(e);
    setCounter(0);
    onDrop && onDrop(e);
  }, [cancel, onDrop]);

  let acceptText;
  let labelIcon;
  let toolTipText;

  switch(true) {
    case isTypeApp:
      acceptText = "";
      labelIcon = "zip-icon";
      toolTipText = "App";
      break;
    case isTypeFeedbackImg:
      acceptText = "image/jpeg, image/png";
      labelIcon = "feedback-upload-icon";
      toolTipText = "Image";
      break;
    default:
      acceptText = "image/jpeg, image/png";
      labelIcon = "upload-icon";
      toolTipText = "Icon";
  }

  const dropEvents = onDrop && !disabled ? {
    onDragEnter: dragEnter,
    onDrop: drop,
    onDragLeave: dragLeave,
    onDragOver: cancel
  }: {
    onDragEnter: null,
    onDrop: null,
    onDragLeave: null,
    onDragOver: null
  };

  return (
    <div className="axiom-image-upload-wrapper">
      <div className={imageUploadWrapper} {...dropEvents}>
        <div className="image-upload-container">
          <input
            id="photo-upload"
            type="file"
            accept={acceptText}
            onChange={onChange}
            ref={inputRef}
            // multiple files can be selected during feedback image
            multiple={isTypeFeedbackImg}
          />
          {isFileSelected || icon ? (
            <ToolTip title={`Change ${toolTipText}`} position="top" showOnlyOnOverflow={disabled}>
              <label
                htmlFor="photo-upload"
                className="custom-file-upload preview"
              >
                {isTypeApp ? (
                  <div>
                    <div className="img-wrap">
                      <Icon name={labelIcon} />
                    </div>
                    {/* The .upload-status container should come here based on the
                    normal flow, but clicking on the button will cause the 'onchange' of
                    input to trigger since the button is inside the label, so moving it outside
                    of label */}
                  </div>
                ) : (
                  <img src={icon} alt="project-icon" />
                )}
              </label>
            </ToolTip>
          ) : (
            <ToolTip title={`Browse ${toolTipText}`} position="top">
              <label
                htmlFor="photo-upload"
                className="custom-file-upload upload"
              >
                <div className="img-wrap">
                  <Icon name={labelIcon} />
                </div>
                {isTypeApp ? (
                  <div className="upload-file-text">Upload file</div>
                ) : (
                      isTypeFeedbackImg ? null : (<div>
                        <p>JPG or PNG</p>
                        <p>Max size of 800K</p>
                      </div>
                    )
                )}
              </label>
            </ToolTip>
          )}
          {isFileSelected && isTypeApp && (
            <div className="upload-status">
              {uploadStatus === UPLOADING ? (
                <div className="uploading">
                  Uploading... {completePercentage}%
                </div>
              ) : (
                <div className={uploadStatus}>
                  <Button classes="cancel-button" click={onClick}>
                    {uploadStatus === UPLOAD ? uploadStatus : "remove"}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

ImageUpload.type.displayName = "ImageUpload";

export { ImageUpload };
