// @flow

import { useEffect, useRef } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "hooks/usePrevious";
import { showSidePanel, closeSidePanel } from "actions/sidePanel";
import { clearInfo } from "actions/info";
import { showOverlay, hideOverlay } from "actions/overlay";

// this hook is responsible for showing, hiding SidePanel, and clearing info reducer based on the changes in route
export const useQuery = () => {
  const { open, closing } = useSelector((state) => state.sidePanel);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const params = useParams();
  const dispatch = useDispatch();

  const { id, fId, bId } = params;
  const panel = query.get("panel");
  const oldPanel = useRef();

  useEffect(() => {
    if(panel || (!panel && !open)) {
      oldPanel.current = panel;
    }
  }, [panel, open]);

  const prevId = usePrevious(id);

  useEffect(() => {
    if(prevId !== id || oldPanel !== panel) {
      if(( id || panel) && (!open )) {
        dispatch(showSidePanel());
        dispatch(showOverlay());
      } else if((!id && !panel) && (prevId || oldPanel) && open) {
        dispatch(closeSidePanel());
        dispatch(hideOverlay());
      }
    }
  }, [dispatch, open, prevId, oldPanel, id, panel]);


  useEffect(() => {
    if(prevId !== id || oldPanel !== panel) {
      if((!panel || !id ) && !open && !closing) {
        dispatch(clearInfo());
      }
    }
  }, [dispatch, open, id, panel, closing, prevId, oldPanel]);

  return {
    id, fId: fId && Number(fId), bId: bId && Number(bId), panel: panel || oldPanel.current
  };
};
