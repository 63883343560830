import React, { useReducer, createContext } from 'react';

import { reducer, initialState } from "./reducer";

export const BuildsContext = createContext();

export const BuildsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BuildsContext.Provider value={{
      builds: state,
      buildsDispatch: dispatch
    }}>
      {children}
    </BuildsContext.Provider>
  );
}