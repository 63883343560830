// @flow

import React, { useCallback, memo } from "react";
import classnames from "classnames";
import { ProfilePicture } from "components";
import { timeDifference } from "utils/common";

import "./Notification.scss";

type clickProps = {
  id: number,
  url: string
};

type Props = {
  id: number,
  viewed: boolean,
  userName: string,
  text: string,
  projectName: string,
  projectIcon: string,
  url: string,
  time: string,
  click: (obj: clickProps) => void
}

const Notification = memo(({
  id,
  viewed,
  userName,
  text,
  projectName,
  projectIcon,
  url,
  time,
  click = () => null
}: Props) => {

  const wrapperClasses = classnames({
    "axiom-notification-wrapper": true,
    "not-viewed": !viewed 
  });

  const handleClick = useCallback(() => click({id, url}), [click, id, url]);

  return (
    <div className={wrapperClasses} onClick={handleClick}>
      <div className="row-1">
        <ProfilePicture
          username={userName}
          type="profile-image"
          classname="average-size"
        />
        <div className="text">
          <span className="highlight">{`${userName}`}</span> <span>{`${text}`}</span> <span className="highlight">{`${projectName}`}</span>
        </div>
        <div className="img-wrapper">
          <img src={`${projectIcon}`} width="56" height="56" alt="project icon" />
        </div>
      </div>
      <div className="row-2">
        {timeDifference(new Date(time))}
      </div>
    </div>
  )
});

Notification.type.displayName = "Notification";

export { Notification };