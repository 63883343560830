import React, { useState, useEffect, useCallback, useRef } from "react";
import axiomLogo from "assets/images/axiom-logo.png";
import classnames from "classnames";
import { Button, Icon, Input } from "components";
import { useDispatch } from "react-redux";
import { isKeyBoardOpen, isEmailValidated, redirectToRoute, getErrorMessage, BACKEND_URL } from "utils/common";
import { showOverlay, hideOverlay } from "actions/overlay";
import UserDataService from "services/usersService";
import { tryAutoLogin, setUserInfo } from "services/authentication";
import { COMPANY_DOMAIN } from "constants.js";
import { MInputPassword } from "mobile/components";
import { FORGOT_PASSWORD } from "models/routes";
import { notify } from "services/browser";
import fixture from "./fixture.json";
import "./MSignIn.scss";

const logInURL = `${BACKEND_URL}/connect/google`;
const { errorSignIn, validEmail } = fixture;

const MSignIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [error, setError] = useState({
    email: "",
    password: ""
  });
  const [showGoogleLoginPopup, setShowGoogleLoginPopup] = useState(false);
  const [isCompanyDomain, setIsCompanyDomain] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialScreenHeight = useRef(window.innerHeight);

  const { email: errorE, password: errorP } = error;

  const handleChange = useCallback(({target: { name, value }}) => {
    name === "email" ? setEmail(value): setPassword(value);
  }, []);

  const forgotPassword = useCallback(() => redirectToRoute(FORGOT_PASSWORD), []);
  
  const onCancel = useCallback((e) => {
    dispatch(hideOverlay());
    setShowGoogleLoginPopup(false);
    /* keep the input disabled when the email has company domain */
    e.currentTarget.type !== "button" && setIsCompanyDomain(false);
  }, [dispatch]);

  const onKeyChange = useCallback((e) => {
    const value = e.target.value;
    if(value.search(`${COMPANY_DOMAIN}$`) !== -1) {
      dispatch(showOverlay());
      setShowGoogleLoginPopup(true);
      setIsCompanyDomain(true);
    } else {
      onCancel(e);
    }
  }, [dispatch, onCancel]);

  const onResize = useCallback(() => {
    if(isKeyBoardOpen(initialScreenHeight.current)) {
      setKeyboardOpen(true);
    } else {
      setKeyboardOpen(false);
    }
  }, []);

  const toggleShowPassword = useCallback(() => setShowPassword(state => !state), []);

  const handleSubmit = useCallback(async () => {
    const data = { identifier: email, password };
    
    if(isEmailValidated(email)) {
      try {
        setLoading(true);
        const res = await UserDataService.localAuthentication(data);
        if(res.data) {
          const { user, jwt } = res.data;
          setUserInfo(user, jwt);
          // register the device browser for notifications
          notify().catch(() => null);
        } else {
          throw new Error(errorSignIn);
        }

      } catch(err) {
        const msg = getErrorMessage(err) || errorSignIn;
        setError(err => ({
          ...err,
          email: "",
          password: msg
        }));
      } finally {
        setLoading(false);
      }
    } else {
      setError(err => ({
        ...err,
        email: validEmail,
        password: ""
      }));
    }
  }, [email, password]);
  
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [onResize]);

  useEffect(() => {
    tryAutoLogin();
  }, []);

  const forgotPasswordClasses = classnames(
    "m-forgot-password", {
    "hide": isCompanyDomain
  });

  return (
    <div className="m-sign-in-container">
      <div className={classnames({
        "m-logo": true,
        "small": keyboardOpen })}
      >
        <img src={axiomLogo} alt="Axiom Logo" />
      </div>
      <div className="m-sign-in-form-wrapper">
        <form>
          <div className="m-sign-in">
            <h2 className="m-sign-in-text">Sign In</h2>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onKeyUp={onKeyChange}
              className={classnames({
                "error": errorE || errorP,
                "no-margin": errorE,
              })}
              onChange={handleChange}
            />
            {errorE && <span className="login-error">{errorE}</span>}
            <MInputPassword
              name="password"
              value={password}
              placeholder="Password"
              disabled={isCompanyDomain}
              className={classnames({
                "error": errorP,
                "no-margin": errorP
              })}
              onChange={handleChange}
              showIcon={password && !isCompanyDomain}
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              error={errorP}
            />
            <div className={forgotPasswordClasses}>
              <Button
                classes=""
                text="Forgot Password?"
                click={forgotPassword}
              />
            </div>
            <div className="m-sign-in-btn">
              <Button 
                classes=""
                text="Sign In"
                click={handleSubmit}
                disabled={loading}
              />
            </div>
          </div>
        </form>
      </div>
      <div className={classnames({
        "m-google-popup-login": true,
        "move-up": showGoogleLoginPopup
      })}>
        <div className="m-close-google-login">
          <Button click={onCancel}>
            <Icon classname="close-icon" name="x-mark" />
          </Button>
        </div>
        <h2 className="m-sign-in-text">Sign In</h2>
        <div className="text">YML employee please continue with Google SSO.</div>
        <div className="sign-in-button">
          <a className="sign-in-btn-wrapper" href={logInURL + `?login_hint=${email}`}>
            <Icon name="google-icon" classname="g-icon" />
            <span>Sign In with Google</span>
          </a>
        </div>
      </div>
    </div>
  )
};

export { MSignIn };

