import {
  SHOW_SIDEPANEL,
  HIDE_SIDEPANEL,
  CLOSING_SIDEPANEL,
  SET_CURRENT_PANEL,
  CLEAR_CURRENT_PANEL,
} from "action-types";

const initialState = {
  open: false,
  closing: false,
};

const sidePanelReducer = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case SHOW_SIDEPANEL:
      return { ...state, open: true};
    case CLOSING_SIDEPANEL:
      return { ...state, closing: true};
    case HIDE_SIDEPANEL:
      return { ...state, open: false, closing: false };
    case SET_CURRENT_PANEL:
      return {...state, curPanel: payload };
    case CLEAR_CURRENT_PANEL:
      return {...state, curPanel: ""};
    default:
      return state;
  }
};

export default sidePanelReducer;
