//@flow

import React, { useRef, useCallback, useEffect } from "react";
import "./BottomBar.scss";

type Props = {
  children: React.Node
};

const BottomBar = ({ children }: Props) => {
  const pos = useRef(window.pageYOffset);
  const ref = useRef(null);

  const setPosition = useCallback(() => {
    const curPos = window.pageYOffset;
    const ele = ref.current;
    if(ele) {
      if(pos.current > curPos) {
        ele.classList.remove("move-down");
      } else {
        ele.classList.add("move-down");
      }
    }
    pos.current = curPos;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", setPosition);
    return () => {
      window.removeEventListener("scroll", setPosition);
    }
  }, [setPosition]);

  return (
    <div className="bottom-nav-bar-container" data-testid="bottom-bar" ref={ref}>
      {children}
    </div>
  );
};

export { BottomBar };