import React from "react";
import Loadable from 'react-loadable';
import { useSelector } from "react-redux";
import { Alert, PrivateRoute, Overlay, Loader } from "components";
import { Router, Switch, Route, Redirect, generatePath } from "react-router-dom";
import SignIn from "views/signin/signin";
import history from "services/history";
import GlobalContexts from "mobile/data/GlobalContexts";
import { isMobile, isInternalUser } from "utils/common";
import {
  LOGIN,
  AUTH_PROVIDER,
  USERS,
  PROJECTS,
  GROUPS,
  FEEDBACKS,
  BASE_PATH,
  FORGOT_PASSWORD,
  DEVICES,
  M_NOTIFICATIONS,
  M_PROFILE,
  RESET_PASSWORD,
  SET_PASSWORD
} from "models/routes";
import "assets/styles/application.scss";
import { MSignIn, MToast } from "mobile/components";
import {
  ForgotPassword,
} from "mobile/views";

const Main = Loadable({
  loader: () => import("views/Main"),
  loading: () => <Loader className="intermediate-loader" />
});

const MMain = Loadable({
  loader: () => import("mobile/views/MMain"),
  loading: () => <Loader className="md center" />
});

const SetResetPassword = Loadable({
  loader: () => import("views/SetResetPassword"),
  loading: () => <Loader className="md center" />
});

const pathProject = generatePath(PROJECTS);

function App() {
  const email = useSelector((state) => state.auth.email);
  const isInternal = isInternalUser(email);
  const mobile = isMobile();

  let mobileUI = false;

  if(mobile) {
    mobileUI = true;
  } else if(email && !isInternal) {
    mobileUI = true;
  }

  return (
    <Router history={history}>
      <div className="App">
          {!mobileUI ?
            <Switch>
              <Route
                key="sign-in"
                exact
                path={LOGIN}
                render={(props) => <SignIn {...props} by="sign-in" />}
              />
              <Route
                key="google"
                path={AUTH_PROVIDER}
                render={(props) => <Main {...props} by="google" />}
              />
              <Route
                path={RESET_PASSWORD}
                render={(props) => <SetResetPassword {...props} />}
              />
              <Route
                path={SET_PASSWORD}
                render={(props) => <SetResetPassword {...props} type="set" />}
              />
              <PrivateRoute
                path={[BASE_PATH, USERS, PROJECTS, GROUPS, FEEDBACKS]}
                exact
                component={Main}
              />
              <PrivateRoute
                path={[FORGOT_PASSWORD, M_PROFILE, DEVICES, M_NOTIFICATIONS]}
                exact
                component={() => <Redirect to={pathProject} />}
              />
              <Route render={() => <div>Not found</div>} />
            </Switch> :
            <div className="mobile">
              <GlobalContexts>
                <Switch>
                  <Route
                    exact
                    path={LOGIN}
                    render={(props) => <MSignIn {...props} />}
                  />
                  <Route
                    path={AUTH_PROVIDER}
                    render={(props) => <MMain {...props} />}
                  />
                  <Route
                    path={RESET_PASSWORD}
                    render={(props) => <SetResetPassword {...props} />}
                  />
                  <Route
                    path={SET_PASSWORD}
                    render={(props) => <SetResetPassword {...props} type="set" />}
                  />
                  <Route
                    path={FORGOT_PASSWORD}
                    render={(props) => <ForgotPassword {...props} />}
                  />
                  <PrivateRoute
                    path={[BASE_PATH, M_PROFILE, PROJECTS, DEVICES, M_NOTIFICATIONS]}
                    exact
                    component={MMain}
                  />
                  <PrivateRoute
                    path={[USERS, GROUPS, FEEDBACKS]}
                    exact
                    component={() => <Redirect to={pathProject} />}
                  />
                  <Route render={() => <div>Not found</div>} />
                </Switch>
                <Overlay />
                <MToast />
              </GlobalContexts>
            </div>
          }
          
        <Alert />
      </div>
    </Router>
  );
}

export default App;
