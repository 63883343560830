import { SET_COUNT } from "action-types";

const initialState = {
  users: -1,
  groups: -1
};

const count = (state = initialState, action) => {
  const { type, payload } = action;
  if(type === SET_COUNT) {
    return {...state, ...payload};
  } else {
    return state;
  }
};

export default count;