import {
  CLEAR_ALL_USERS,
  ADD_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
} from "action-types";

const initialState = [];
const users = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_USERS_SUCCESS:
      return [...state, ...payload];
    case CLEAR_ALL_USERS:
      return initialState;
    case UPDATE_USERS_SUCCESS:
      return state.map((user) => (user.id === payload.id ? payload : user));
    default:
      return state;
  }
};

export default users;
