import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, Icon } from 'components';
import { M_LEFT_OFFSET_LIMIT, HORIZONTAL, VERTICAL } from "constants.js";
import type { TDevice } from "./device";
import "./MDevice.scss";

type Props = TDevice & {
  click: (number) => void
};

const MDevice = ({
  id,
  type,
  model,
  name,
  os_version,
  current_device,
  click = () => null
}: Props) => {

  const [listeners, setListeners] = useState(false);
  const ele = useRef(null);
  const iniPos = useRef(null);
  const [curPos, setCurPos] = useState({});
  const [newPos, setNewPos] = useState({});

  const clearValues = useCallback(() => {
    iniPos.current = null;
    setCurPos({});
    setNewPos({});
  }, []);

  const handleTouch = useCallback((e) => {
    if(iniPos.current) {
      const element = e.target;
  
      if(ele.current) {
        if(ele.current.nextElementSibling === element.parentElement) {
          return;
        } else {
          setNewPos({});
        }
      }
    }
  }, []);


  useEffect(() => {
    document.addEventListener("touchstart", handleTouch);
    return () => document.removeEventListener("touchstart", handleTouch);
  }, [handleTouch]);



  const deleteDevice = useCallback(() => {
    click(id, setNewPos);
  }, [click, id]);

  const closeDragEvent = useCallback(() => {
    setListeners(false);
    clearValues();
  }, [clearValues]);

  const elementDrag = useCallback(e => {
    const event = e.touches?.[0] || e;
    const { dir } = iniPos.current;

    // don't allow the element to be moved or dragged to the right side from the initial position
    if(iniPos.current.x < event.clientX) return;

    // if the element is moved above 75px to the left, stop it from moving or dragging further
    if(ele.current.offsetLeft < M_LEFT_OFFSET_LIMIT) {
      setListeners(false);
      return setNewPos({ left: ele.current.offsetLeft });
    }

    // calculate the new cursor position
    const x = curPos.x - event.clientX;
    const y = curPos.y - event.clientY;

    if(dir) {
      if(dir === VERTICAL) {
        setCurPos(pos => ({ ...pos, y: event.clientY + y }));
      } else if (dir === HORIZONTAL) {
        setCurPos((pos) => ({ ...pos, x: event.clientX }));
        setNewPos({ left: ele.current.offsetLeft - x });
      }
    } else {
      iniPos.current.dir = window.Math.abs(y) > x ? VERTICAL: HORIZONTAL;
    }

  }, [curPos]);

  const mouseDown = useCallback(e => {
    const event = e.touches?.[0] || e;

    // set the initial position of x
    iniPos.current = {
      x: event.clientX,
      y: event.clientY
    };

    // set the current position of x
    setCurPos({...iniPos.current});
    setListeners(true);
  }, []);

  const listenerProps = listeners ? {
    onMouseUp: closeDragEvent,
    onMouseMove: elementDrag,
    onTouchEnd: closeDragEvent,
    onTouchMove: elementDrag
  }: {
    onMouseUp: null,
    onMouseMove: null,
    onTouchEnd: null,
    onTouchMove: null
  };

  return (
    <div className="m-device-container">
      <div className="device-wrapper" style={newPos} onMouseDown={mouseDown} onTouchStart={mouseDown} ref={ele} {...listenerProps} >
        <div className="phone-icon">
          <Icon name={`m-${type}-phone`} classname={type} />
        </div>
        <div className="model-name-wrapper">
          <div className="model">{model}</div>
          <div className="name-device-wrapper">
            <div className="name-version">{`${name} ${os_version}`}</div>
            { current_device && (
              <div className="current-device">
                <span className="text">Current Device</span></div>
            )}
          </div>
        </div>
      </div>
      <Button click={deleteDevice} classes="delete-btn">
        <Icon name="delete-icon" classname="delete-icon"/>
      </Button>
    </div>
  );
};

export { MDevice };