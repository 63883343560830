import React, { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { useParams, generatePath, useLocation } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { usePrevious } from "hooks/usePrevious";

import { MSearch } from "mobile/views";
import { Loader } from "components";
import { BottomBar, MobileHeader, MProjectCard, MobileCard, MBuildDetails, MNavBar } from "mobile/components";

import { ProjectsContext } from "mobile/data/projects";
import { FoldersContext } from "mobile/data/folders";
import { BuildsContext } from "mobile/data/builds";
import { InfoContext } from "mobile/data/info";

import { Project as ProjectData } from "models/api/project";
import { Folder } from "models/api/folder";
import { BuildList } from "models/api/builds";
import { MBuildDetails as BuildDetails } from "models/api/mBuildDetails";
import { LatestBuild } from "models/api/latestBuild";

import BuildDataService from "services/buildService";
import FolderDataService from "services/folder";
import ProjectsDataService from "services/projectsService";

import { addToProjectList, clearProjectList } from "actions/projectActions";
import { addToFolderList, clearFolderList } from "actions/folderActions";
import { addToBuildList, clearBuildList } from "actions/buildActions";
import { updateInfo, clearInfo } from "actions/info";

import {
	M_TEXT_PROJECTS,
	M_TEXT_FOLDERS,
	M_TEXT_BUILDS,
	M_TEXT_LATEST_BUILD,
	LATEST_BUILD_QUERY
} from "constants.js";
import {
	generateProjectsURL,
	generateBuildsURL,
	PROJECT_LIST_LIMIT,
	getErrorMessage,
	redirectToRoute
} from "utils/common";
import { PROJECTS } from "models/routes";
import history from "services/history";
import "./MMobileObserver.scss";

const [key, value] = LATEST_BUILD_QUERY.split("=");

const MMobileObserver = () => {
  const params = useParams();

  const { projects, projectsDispatch } = useContext(ProjectsContext);
  const { folders, foldersDispatch } = useContext(FoldersContext);
  const { builds, buildsDispatch } = useContext(BuildsContext);
  const { info, infoDispatch } = useContext(InfoContext);
  
  const obj = useRef({
    id: -1,
    type: "",
    fun: null,
    model: "",
    addData: null,
    clearData: null,
    dataArr: []
  });

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const prevInView = usePrevious(inView);
  const [pageCount, setPageCount] = useState(0);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customMsg, setCustomMsg] = useState("");

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { id, fId, bId } = params;
  const latestBuild = useMemo(() => query.get(key) === value, [query]);

  useEffect(() => {
    if(latestBuild) {
      obj.current = {
        id: fId,
        type: M_TEXT_LATEST_BUILD,
        fun: () => FolderDataService.getLatestBuild(fId),
        model: LatestBuild,
        addData: (data) => infoDispatch(updateInfo(data)),
        clearData: () => infoDispatch(clearInfo()),
        component: MBuildDetails
      }
    } else if(bId) {
      obj.current = {
        id: bId,
        type: "",
        fun: () => BuildDataService.buildDetails(bId),
        model: BuildDetails,
        addData: (data) => infoDispatch(updateInfo(data)),
        clearData: () => infoDispatch(clearInfo()),
        component: MBuildDetails
      }
    } else if(fId) {
      obj.current = {
        id: fId,
        type: M_TEXT_BUILDS,
        fun: () => FolderDataService.getBuildsList(generateBuildsURL({count: pageCount, folderId: fId, oldBuilds: true, type: true})),
        model: BuildList,
        addData: (data) => buildsDispatch(addToBuildList(data)),
        clearData: () => buildsDispatch(clearBuildList()),
        dataArr: builds,
        component: MobileCard
      }
    } else if(id) {
      obj.current = {
        id: id,
        type: M_TEXT_FOLDERS,
        fun: () => ProjectsDataService.getProjectFolders(id),
        model: Folder,
        addData: (data) => foldersDispatch(addToFolderList(data)),
        clearData: () => foldersDispatch(clearFolderList()),
        dataArr: folders,
        component: MobileCard
      }
    } else {
      obj.current = {
        fun: () => ProjectsDataService.projectsList(
          generateProjectsURL({
            sort: {by: "name", ASC: true},
            count: pageCount,
            filterArr: [{
              type: "state",
              val: "Active Projects"
            }]
          })
        ),
        type: M_TEXT_PROJECTS,
        model: ProjectData,
        addData: (data) => projectsDispatch(addToProjectList(data)),
        clearData: () => projectsDispatch(clearProjectList()),
        dataArr: projects,
        component: MProjectCard
      }
    }
  }, [bId, fId, id, pageCount, buildsDispatch, foldersDispatch, projectsDispatch, infoDispatch, builds, folders, projects, info.folder, latestBuild, info.project]);

  useEffect(() => {
    setCustomMsg("");
  }, [id, fId, bId]);

  useEffect(() => {
    if(!bId) {
      infoDispatch(updateInfo({ build: null }));
    }
  }, [bId, infoDispatch]);

  useEffect(() => {
    if(!bId || !latestBuild || !info.build?.id) {
      setPageCount(0);
      setComplete(false);
    }
    projectsDispatch(clearProjectList());
    foldersDispatch(clearFolderList());
    buildsDispatch(clearBuildList());
  }, [id, fId, bId, latestBuild, info.build, projectsDispatch, foldersDispatch, buildsDispatch]);

  useEffect(() => {
    return () => {
      projectsDispatch(clearProjectList());
    }
  }, [projectsDispatch]);

  const handleArray = useCallback((data) => {
    const newData = data.map((item) => new obj.current.model(item));
    if (newData.length < PROJECT_LIST_LIMIT) {
      setComplete(true);
    }
    return newData;
  }, []);

  const handleData = useCallback((data) => {
    let newData, info;

    switch(obj.current.type) {
      case M_TEXT_LATEST_BUILD: {
        newData = { build: new obj.current.model(data) };
        !bId && history.replace({
          pathname: generatePath(PROJECTS, {id, fId, bId: newData.build.id }),
          search
        });
        setCustomMsg("No latest build exists");
        break;
      }
      case M_TEXT_PROJECTS: {
        newData = handleArray(data);
        setCustomMsg("You are not part of any project");
        break;
      }
      case M_TEXT_FOLDERS: {
        const { project, folders } = data;
        newData = handleArray(folders);
        if(project) {
          info = { project };
        }
        setCustomMsg("No folder exist for the project");
        break;
      }
      case M_TEXT_BUILDS: {
        const { folder, builds } = data;
        newData = handleArray(builds);
        if(folder) {
          info = { folder };
        }
        setCustomMsg("No old builds exist");
        break;
      }
      default:
        newData = { build: new obj.current.model(data) };
        setCustomMsg("Error in getting build details");
    }

    info && infoDispatch(updateInfo(info));

    return newData;
  }, [handleArray, infoDispatch, id, fId, bId, search]);

  const getData = useCallback(async () => {
    try {
      const { data } = await obj.current.fun();
      obj.current.addData(handleData(data));
      setPageCount((count) => count + 1);
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in getting data";
      setCustomMsg(msg);
    } finally {
      setLoading(false);
      
      if(obj.current.type === M_TEXT_LATEST_BUILD || !obj.current.type) {
        setComplete(true);
      }
    }
  }, [handleData]);

  useEffect(() => {
    if (prevInView !== inView && inView) {
      if(obj.current.type !== M_TEXT_LATEST_BUILD || !info.build) {
        getData();
      } else {
        setLoading(false);
        setComplete(true);
      }
    }
  }, [inView, prevInView, getData, info.build]);

  const click = useCallback(async object => {
    const { id: itemId } = object;
    let newObject = {};
    let routeObj = { id };
    let queryParams = "";

    if(fId) {
      // clicking on build in build list
      newObject = { build: object };
      routeObj = { ...routeObj, fId, bId: itemId };
    } else {
      // clicking on folder in folder list
      newObject = { folder: object };
      routeObj = { ...routeObj, fId: itemId };
      queryParams = LATEST_BUILD_QUERY;
    }

    infoDispatch(updateInfo(newObject));
    redirectToRoute(generatePath(PROJECTS, routeObj), queryParams);
  }, [id, fId, infoDispatch]);

  const buildsList = useCallback(() => {
    redirectToRoute(generatePath(PROJECTS, { id, fId }));
  }, [id, fId]);

  const cardType = projects.length < 3? "grid": "list";

  const listClasses = classnames(
    "m-common-list", [cardType], {
    "no-flex": id
  });

	return  (
    <>
      <MobileHeader
        showLogo={!id}
        withSearch={!id}
        centeredText={
          (latestBuild && "Latest Build") ||
          (bId && info.build?.name) ||
          (fId && info.folder?.name) ||
          (id && info.project?.name) 
        }
        lastIcon={
          (latestBuild && info.build?.oldBuilds && "m-previous-builds") ||
          ((fId || bId) && "no-icon") ||
          (id && "m-search") ||
          (!id && "m-notifications")
        }
        iconFun={buildsList}
      />
      <section className="m-project-wrapper">
        <MSearch curType={obj.current.type} />

        {/* header shown only for project list */}
        {!id && <h2 className="heading-text">Projects</h2>}

        {bId  ? (
          !loading && info.build?.name && <MBuildDetails {...info?.build} isLatest={latestBuild} />
        ) : (
          <div className={listClasses}>
            {obj.current.dataArr?.map(item => {
              return (
                <obj.current.component key={item.id} {...item} click={click} type={cardType} />
              ) 
            })}
          </div>
        )}

        { !loading && (!obj.current.dataArr?.length && !info.build?.name) && customMsg && (
            <div className="m-center-content">{customMsg}</div>
          )
        }

        {!complete && (
          <div className="observer" ref={ref}>
            {loading && <Loader className="sm" />}
          </div>
        )}
        
        {!id && (
          <BottomBar>
            <MNavBar />
          </BottomBar>
        )}
      </section>
    </>
  );
}

export { MMobileObserver };