// @flow

import React, { Fragment, memo } from "react";
import { User, UserHeadingTab } from "components";
import { useObserver } from "hooks/useObserver";

import "./UsersList.scss";

const UsersList = memo((props: {}) => {
  const { data, isEmpty, observer } = useObserver({ type: "users" });

  // display the users list only for users page
    return (
      <Fragment>
        <UserHeadingTab />
        <div className="axiom-userlist-container">
          <div className="users-list-wrapper">
            {data.map((user) => {
              const {
                id,
                name,
                email,
                role,
                DOJ,
                blocked,
                confirmed,
                inviter,
                userType,
              } = user;
              return (
                <User
                  key={email}
                  id={id}
                  name={name}
                  email={email}
                  role={role}
                  DOJ={DOJ}
                  blocked={blocked}
                  confirmed={confirmed}
                  inviter={inviter}
                  userType={userType}
                />
              );
            })}
          </div>

          {isEmpty && <div className="empty-users-list">No users found</div>}

          {observer}
        </div>
      </Fragment>
    );
});

UsersList.type.displayName = "UsersList";

export { UsersList };
