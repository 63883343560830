// @flow

import React, { useRef, useEffect, useState, useCallback, memo } from "react";
import classnames from "classnames";
import { ListContainer, Icon } from "components";
import "./UserRole.scss";

type option = {
  id: string | number,
  title: string,
};

type common = {|
  options: Array<option>,
  placeholder: string,
  value: Object,
  onChange: (Object) => void,
  classname: string,
  showDefaultValue: boolean,
  disable: boolean
|};

const UserRole = memo(({
  options,
  placeholder,
  value,
  onChange,
  classname,
  showDefaultValue = false,
  disable = false
}: common) => {
  const roleContainer = useRef<HTMLDivElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const isClicked = useRef(null);

  const handleRoleClick = (e) => {
      if(disable) return;
      e.stopPropagation();
      setOpen((state) => !state);
  };

  const onFocus = useCallback(() => {
    // if the focus is due to click ignore
    if(options.length && !isClicked.current) {
      setOpen(true);
    }
  }, [options]);

  const onBlur = useCallback(() => {
    setOpen(false);
    isClicked.current = null;
  }, []);

  const onSelect = useCallback(
    (id) => {
      const data = options.find((data) => data.id === id);
      onChange(data ? data : "");
      setOpen(false);
    },
    [options, onChange]
  );

  const handleClick = useCallback((event: MouseEvent) => {
    if (
      roleContainer.current &&
      event.target instanceof Node &&
      !roleContainer.current.contains(event.target)
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div
      ref={roleContainer}
      className={classnames("axiom-list-box", classname, { disabled: disable })}
      role="listbox"
      tabIndex={disable ? -1: 0}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={() => isClicked.current = true}
    >
      <div
        className="axiom-user-role-value"
        data-testid="user-role"
        onClick={(e) => handleRoleClick(e)}
      >
        {value ? value.name : placeholder}
        <span className="arrow">
          {isOpen ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />}
        </span>
      </div>
      {isOpen ? (
        <ListContainer
          data={options}
          type="dropdown-list"
          onSelect={onSelect}
          defaultValue={showDefaultValue ? value : false}
        />
      ) : null}
    </div>
  );
});

UserRole.type.displayName = "UserRole";

export { UserRole };
