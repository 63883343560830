import http from "http-common";

// can be used for the following cases, for getting
// 1) id & name : searchUsers(text)
// 2) id, name, email & type: searchUsers(text, { withType: true })
// 3) id & email: searchUsers(text, { emailOnly: true });

export const searchUsers = (text, obj = {}) => {
  const { withType = false, emailOnly = false, internalOnly = false } = obj;

  let params = `?_q=${text}`;

  if (withType) params += `&with-type=true`;
  if (emailOnly) params += `&emailOnly=true`;
  if(internalOnly) params += `&internal-only=true`;

  return http.get(`/users/search${params}`);
};

// the result will be array of object with properties id and name
export const searchGroups = (text, obj = {}) => {
  const { withMembers = false, noInternal = false } = obj;

  let params = `?_q=${text}`;
  if(withMembers) params += `&withMembers=true`;
  if(noInternal) params += `&noInternal=true`;
  return http.get(`/groups/search${params}`);
};

export const searchProjects = (text) => {
  const params = `?_q=${text}`;
  return http.get(`/projects/search${params}`);
};