// @flow

import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { USERS, GROUPS } from "models/routes";
import classnames from "classnames";
import { getBasePath, isActivePath, redirectToRoute } from "utils/common"; 
import "./TypeCounter.scss";

type Props = {
  type: string,
  /** path defined in routes.js */
  path: string,
};

const TypeCounter = memo(({ type, path }: Props) => {
  const { pathname } = useLocation();
  const basePath = getBasePath(path);
  const count = useSelector(state => state.count[type.toLowerCase()]);

  const onClick = () => {
    redirectToRoute(basePath);
  };

  const wrapperClasses = classnames({
    "axiom-typecounter-wrapper": true,
    active: isActivePath(pathname, path),
  });

  const countClasses = classnames({
    "count": true,
    "hide-count": count === -1
  });

  if ( isActivePath(pathname, USERS) || isActivePath(pathname, GROUPS) ) {
    return (
      <div className="axiom-typecounter-container" data-testid="type-counter">
        <div className={wrapperClasses} onClick={onClick}>
          <div className="type">{type}</div>
          <div className={countClasses} data-testid="count">
            {count}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
});

TypeCounter.type.displayName = "TypeCounter";

export { TypeCounter };
