import http from "http-common";

const getNotifications = () => {
  return http.get("/notifications");
};

const markAsRead = (data) => {
  return http.put("/notifications", data);
}

export default {
  getNotifications,
  markAsRead
};