import {
  ACTIVE,
  ARCHIVED,
  DELETED,
  MASTER_ADMIN,
  ADMIN,
  USER,
  COMPANY_DOMAIN,
  INTERNAL,
  ANDROID,
  IOS
} from "constants.js";
import history from "services/history";
import { generatePath as genPath } from "react-router-dom";
import { PROJECTS } from "models/routes";

export const isAdminOrMasteradmin = (userRole) => {
  return userRole === "Admin" || userRole === "Master Admin" ? true : false;
};

export const adminOrHigherRoles = (userRole) => {
  switch (userRole) {
    case MASTER_ADMIN:
    case ADMIN:
      return true;
    default:
      return false;
  }
};

export const isUser = (userRole) => userRole === USER;

export const isMAdmin = (userRole) => userRole === MASTER_ADMIN;

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const setToken = (authToken) => {
  localStorage.setItem("token", authToken);
};

export const clearToken = () => {
  localStorage.removeItem("token");
};

// an unauthenticated user trying to access a protected route is redirected to login page. But after successful login, the user is taken back to the same protected route
export const getPrevRoute = () => localStorage.getItem("prev-route");

export const setPrevRoute = (route) => localStorage.setItem("prev-route", route);

export const clearPrevRoute = () => localStorage.removeItem("prev-route");

export const isEmailValidated = (email) => {
  let formIsValid = true;
  /* eslint-disable no-useless-escape */
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!email || !mailformat.test(email)) {
    formIsValid = false;
  }
  return formIsValid;
};

export const generateUrl = ({ by, ASC }, count, filterArr) => {
  let i,
    params,
    sortObj = {},
    // for the State:- Active or Inactive users
    // the value stored in db is blocked:- false or true
    // generated url for Active or Inactive users will have 'blocked=false' or 'blocked=true'
    arr = filterArr.map((ele) => {
      if (ele.type === "blocked") {
        return { ...ele, val: ele.val === "Active" ? false : true };
      } else {
        return ele;
      }
    });

  // for the sort by field 'doj', the backend value is created_at
  // 'name' is combination of first_name and last_name, so sort by first_name
  switch (by) {
    case "doj":
      sortObj.by = "DOJ";
      break;
    case "name":
      sortObj.by = "first_name";
      break;
    default:
      sortObj.by = by;
  }

  sortObj.ASC = ASC ? "ASC" : "DESC";

  params = `?_sort=${sortObj.by}:${sortObj.ASC}&_start=${count *
    USER_LIST_LIMIT}&_limit=${USER_LIST_LIMIT}`;

  for (i = 0; i < arr.length; i++) {
    params += `&${arr[i].type}=${arr[i].val}`;
  }
  return params;
};

export const generateProjectsURL = ({
  sort: { by, ASC },
  count,
  filterArr,
  _q = ""
}) => {
  let i, params;

  let arr = filterArr.map((ele) => {
    let { val, type } = ele;
    if (type === "platform") {
      return ele;
    } else if(type === "me") {
      return { ...ele, val: true };
    } else {
      return { ...ele, val: val.split(" ")[0].toLowerCase() };
    }
  });

  params = `?_sort=${by}:${ASC ? "ASC" : "DESC"}&_start=${count *
    PROJECT_LIST_LIMIT}&_limit=${PROJECT_LIST_LIMIT}`;

  for (i = 0; i < arr.length; i++) {
    if (arr[i].type === "platform") {
      params += `&${arr[i].type}_contains=${arr[i].val}`;
    } else {
      params += `&${arr[i].type}=${arr[i].val}`;
    }
  }

  _q && (params += `&_q=${_q}`);

  return params;
};

export const generateFeedbackURL = ({
  sort: { by, ASC },
  filters,
  count
  }) => {
    let str = `?_sort=${by}:${ASC?"ASC":"DESC"}&_start=${count *
      FEEDBACK_LIST_LIMIT}&_limit=${FEEDBACK_LIST_LIMIT}`;
    for(let i=0; i < filters.length; i++) {
      str += `&${filters[i].type}=${filters[i].val}`;
    }
    return str;
};

export const generateBuildsURL = ({ count, folderId, oldBuilds = false, type = false, minData = false }) => {
  let str = `?_start=${count * BUILD_LIST_LIMIT}&_limit=${BUILD_LIST_LIMIT}&folder=${folderId}`;
  oldBuilds && (str += `&oldBuilds=true`);
  type && (str += `&type=true`);
  minData && (str += `&minData=${minData}`);
  return str;
};

export const getDateFormat = (date, { 
  shortMonth = false,
  withHyphen = true
} = {}) => {
  var options = {
    day: "2-digit",
    month: shortMonth ? "short": "long",
    year: "numeric",
  };
  return new Date(date).toLocaleDateString("en-GB", options).replace(/ /g, withHyphen ? "-": " ");
};

export const dateTime = (dateString) => {
  const dateObj = new Date(dateString);

  const date = dateObj
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " ");

  const time = dateObj.toLocaleString("en-GB", {
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h12"
  });

  return `${date}, ${time}`;
};

// TODO: fix issue with observer when the limit is 10 (doesn't work the second time)
export const USER_LIST_LIMIT = 15;
export const FEEDBACK_LIST_LIMIT = 15;
export const GROUP_LIST_LIMIT = 20;
export const PROJECT_LIST_LIMIT = 20;
export const BUILD_LIST_LIMIT = 20;

export const generateGroupsURL = (count, filters) => {
  let params = `?_start=${count * GROUP_LIST_LIMIT}&_limit=${GROUP_LIST_LIMIT}`;

  for (let i = 0; i < filters.length; i++) {
    params += `&${filters[i].type}=${filters[i].val
      .split(" ")[0]
      .toLowerCase()}`;
  }

  return params;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// project functions
export const isActive = (status) => (status === ACTIVE ? true : false);
export const isArchived = (status) => (status === ARCHIVED ? true : false);
export const isDeleted = (status) => (status === DELETED ? true : false);

// send the error object and it returns the error message if everything's correct
export const getErrorMessage = (err) => {
  const response = err.response;
  let msg;
  if(response) {
    msg = response.data?.message;
  } else {
    msg = err.message;
  }
  return typeof msg === "string" ? msg: "";
};

// generates a Globally Unique IDentifier
export const guidGenerator = () => {
  var S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

// unique number generator
export const uniqueNumber = () => {
  const n1 = Math.trunc(Math.random() * 300);
  const n2 = Math.trunc(Math.random() * 700);
  const n3 = Math.trunc(Math.random() * 1500);
  const n = String(Date.now());
  return Number(n1 + n.slice(0, 3) + n2 + n.slice(3, 6) + n3);
};

// path functions
export const getBasePath = (fullPath) => {
  const arr = fullPath.split("/");
  const topLevelName = arr[1];
  return `/${topLevelName}`;
};

export const isActivePath = (pathname, fullPath) => {
  const arr = pathname.split("/");
  const topLevelName = arr[1];
  return getBasePath(fullPath).search(topLevelName) > -1
};

export const redirectToRoute = (route, search = "") => {
  if (search) {
    history.push({pathname: route, search});
  } else {
    history.push(route);
  }
};

// This function is used to generate path for redirection
// This function is also used in server to generate the path
export const generatePath = ({
  userId,
  groupId,
  projectId,
  folderId,
  buildId
}) => {
  if(userId) {
    return `/users/${userId}`;
  }

  if(groupId) {
    return `/groups/${groupId}`;
  }

  if(projectId) {
    const baseURL = `/projects/${projectId}`;
    const searchParam = "?panel=builds";

    if(folderId) {
      if(buildId) {
        return `${baseURL}/${folderId}/${buildId}${searchParam}`;
      }

      return `${baseURL}/${folderId}${searchParam}`;
    }

    return `${baseURL}${searchParam}`;
  }
};

// function to get relative time
export const timeDifference = (previous, current = new Date()) => {

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  const getText = (diviser, key) => {
    const val = Math.round(elapsed/diviser);
    return `${val} ${key}${val === 1?"": "s"} ago`;
  };

  if (elapsed < msPerMinute) {
    return getText(1000, "second");
  }

  else if (elapsed < msPerHour) {
    return getText(msPerMinute, "minute");
  }

  else if (elapsed < msPerDay ) {
    return getText(msPerHour, "hour");
  }

  else if (elapsed < msPerMonth) {
    return getText(msPerDay, "day");
  }

  else if (elapsed < msPerYear) {
    return getText(msPerMonth, "month");
  }

  else {
    return getText(msPerYear, "year");
  }
}

export const isListItemAndNotOpen = (ele, showPopup) => {
  return ele.nodeName === "LI" && ele.classList.contains("list-item") && !showPopup;
};

export const properImageFormat = (ext) => {
  switch(ext) {
    case "png":
    case "jpg":
      return true;
    default:
      return false;
  }
};

export const getIconRestrictionMsg = (file) => {
  let msg = "";

  if(!file) {
    return "No file selected";
  }

  const fileName = file.name;
  const ext = fileName.slice(fileName.lastIndexOf(".") + 1);

  switch(true) {

    case !properImageFormat(ext):
      msg = "Only jpg or png images are supported";
      break;
    case file.size > 800000:
      msg = "Image size is too large. Please select another image";
      break;
    default:
  }

  return msg;
};

export const getConfirmationWrapperStyles = (ele, additionProps = {}) => {
  const refEle = ele.parentElement;
  const rect = refEle.getBoundingClientRect();

  const { popup, forProfileIcon, forListItem } = additionProps;

  let style = {
    top: rect.top + refEle.offsetHeight - (0.25 * refEle.offsetHeight) - 3,
    left: rect.left + (refEle.offsetWidth / 2)
  };
  
  const windowWidth = window.document.body.offsetWidth;

  const diff = windowWidth - ( style.left + popup.offsetWidth + 20 );

  if(diff < 0) {
    style = {...style, left: style.left + diff - 40};
  }

  // decrease additional height for profile icon
  if(forProfileIcon) {
    style = {...style, top: style.top - (0.25 * refEle.offsetHeight)};
  } else if (forListItem) {
    style = {...style, top: rect.top + refEle.offsetHeight + 10, left: style.left - (diff < 0 ? 0 : 100)};
  } else {
    style = { ...style, top: style.top + 11 };
  }
  
  return style;
};

export const  isInternalUser = (email) => {
  return email.search(COMPANY_DOMAIN) !== -1 ? true: false;
}

export const getRolesArray = ({ rolesArr, userRole, roleName, email }) => {
  let arr = [];
  if(!isInternalUser(email)) {
    arr = rolesArr.filter(r => r.name !== ADMIN && r.name !== roleName);
  } else {
    arr = rolesArr.filter(r => r.name !== (userRole !== MASTER_ADMIN? ADMIN: "") && r.name !== roleName);
  }

  return arr;
}

export const isMobile = () => {
  const userAgent= window.navigator.userAgent;
  let mobile = false;

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
    mobile = true;
  } else if(userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    // also consider ipad as mobile https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
    mobile = true;
  }

  if(!mobile) {
    const root = window.document.documentElement;
    window.document.body.classList.add("is-desktop");
    if(!isSafari()) {
      root.style.setProperty("--viewport-width", "calc(100vw * 1.4285)");
      root.style.setProperty("--viewport-height", "calc(100vh * 1.4285)");
    }
  }

  return mobile;
};

export const isKeyBoardOpen = (initialHeight) => {
  const { document: { documentElement: { offsetHeight } } } = window;
  return offsetHeight < initialHeight;
};

export const goBack = () => {
  // if the history is empty, redirect to Projects route
  if(history.length === 1) {
    redirectToRoute(genPath(PROJECTS));
  } else {
    history.goBack();
  }
};

export const isOffline = () => {
  return !navigator.onLine;
};

export const isPlural = count => count > 1 ? "s": "";

export const isSortEqual = (newSort, oldSort) => newSort.by === oldSort.by && newSort.ASC === oldSort.ASC;

export const isFilterEqual = (newFilter, oldFilter) => {
  if(oldFilter.length !== newFilter.length) {
    return false;
  }
  
  for(let i=0; i < oldFilter.length; i++) {
    if(oldFilter[i].type !== newFilter[i].type || oldFilter[i].val !== newFilter[i].val) {
      return false;
    }
  }

  return true;
};

// width and height of tooltip arrow
export const TOOLTIP_ARROW_WIDTH = 10;

export const getTooltipStyles = ({
  position,
  toolTipEle,
  containerEle
}) => {
  const style = {};
  const rect = containerEle.getBoundingClientRect();

  switch(position) {
    case "top":
      style.top = rect.top - toolTipEle.offsetHeight - TOOLTIP_ARROW_WIDTH * 1.5;
      style.left = rect.left + containerEle.offsetWidth / 2;
      break;
    case "left":
      style.top = rect.top + containerEle.offsetHeight / 2;
      style.left = rect.left - toolTipEle.offsetWidth - TOOLTIP_ARROW_WIDTH * 1.5;
      break;
    case "right":
      style.top = rect.top + containerEle.offsetHeight / 2;
      style.left = rect.left + containerEle.offsetWidth + TOOLTIP_ARROW_WIDTH * 1.5;
      if(document.body.offsetWidth < rect.left + containerEle.offsetWidth + toolTipEle.offsetWidth + TOOLTIP_ARROW_WIDTH) {
        toolTipEle.classList.remove("right");
        toolTipEle.classList.add("left");
        style.left = rect.left - toolTipEle.offsetWidth - TOOLTIP_ARROW_WIDTH * 1.5;
      }
      break;
    case "bottom":
      style.top = rect.top + containerEle.offsetHeight + TOOLTIP_ARROW_WIDTH * 1.5;
      style.left = rect.left + containerEle.offsetWidth / 2;
      break;
    default:
  }

  return style;
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// returns an object with ids as key, and selected as value for it, used with ShareWith component
export const getSharedObj = (prevVal, { id, selected }) => ({ [id]: selected, ...prevVal });

// checks if any new changes are made in ShareWith component
export const isValueChanged = (arr, obj) => {
  for(let { id, selected } of arr) {
    const exist = id in obj;
    if((!exist && selected) || (exist && selected !== obj[id])) {
      return true;
    }
  }
};

export const isTypeInternal = type => type === INTERNAL;

// checks if the members array has external users
export const hasExternalMembers = members => members.reduce((prevVal, { userType }) => prevVal || userType !== INTERNAL , false);


// password validator
export const passwordValidator = password => {
  const oneDigit = /[\d]/;
  const oneUppercase = /[A-Z]/;
  const specialSymbol = /[!@#$%^&*]/;
  const condition = /^[a-zA-Z0-9!@#$%^&*_-]{8,12}$/;

  if(password.length < 8) {
    throw new Error("Password must be minimum of 8 characters");
  }

  if(password.length > 12) {
    throw new Error("Password cannot contain more than 12 characters");
  }

  // backend requirement for hashing password
  if(password.split("$").length > 3) {
    throw new Error("Password cannot contain  more than three times the symbol '$'");
  }

  if(!oneDigit.test(password)) {
    throw new Error("Password must contain atleast 1 digit");
  }

  if(!oneUppercase.test(password)) {
    throw new Error("Password must contain atleast 1 uppercase letter");
  }

  if(!specialSymbol.test(password)) {
    throw new Error("Password must contain atleast 1 special symbol");
  }

  if(!condition.test(password)) {
    throw new Error("Password do not match the required condition");
  }

  return true;
};

// sort the array of objects in ascending order based on the name
export const sortByName = arr => arr.sort((a, b) => {
  const fa = a.name.toLowerCase();
  const fb = b.name.toLowerCase();

  if(fa < fb) {
    return -1;
  }

  if (fa > fb) {
    return 1;
  }

  return 0;
});

export const getBuildTypeText = type => {
  switch(type) {
    case ANDROID:
      return "Android";
    case IOS:
      return "iOS";
    default:
      return "";
  }
};

export const downloadBuildUsingEle = url => {
  const ele = document.createElement("a");
  ele.href = url;
  ele.setAttribute("download", "");
  document.body.appendChild(ele);
  ele.click();
  document.body.removeChild(ele);
};