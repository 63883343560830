// @flow

import React, { useState, useRef, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isAdminOrMasteradmin,
  capitalize,
} from "utils/common";
import { ConfirmationPopup, Icon, ListContainer } from "components";
import { useClickOutside } from "hooks/useClickOutside";
import { useProjectStateUpdate } from "hooks/useProjectLogic";
import {
  isActive,
  isArchived,
  getErrorMessage,
  redirectToRoute,
  isListItemAndNotOpen
} from "utils/common";
import ProjectService from "services/projectsService";
import { Project as ProjectData } from "models/api/project";
import { updateProjectList } from "actions/projectActions";
import { generatePath } from "react-router-dom";
import { setAlertMsg } from "actions/alertMsg";
import { updateInfo } from "actions/info";
import { PROJECTS } from "models/routes";
import fixture from "./fixture.json";
import {
  PROJECT_BUILDS,
  PROJECT_TESTERS,
  PROJECT_GROUPS,
  EDIT_PROJECT,
  ACTIVE,
  ARCHIVED,
  DELETED,
  TXT_ARCHIVE,
  TXT_UNARCHIVE,
  TXT_ADD_TESTERS,
  TXT_ADD_NEW_BUILD,
  UPLOAD_BUILD,
} from "constants.js";
import classnames from "classnames";

import "./Project.scss";

type Props = {
  id: number,
  name: string,
  iconURL: string,
  platform: Array<string>,
  description: string,
  managerId: number,
  state: ACTIVE | ARCHIVED | DELETED,
};

const Project = memo(({
  id,
  name,
  iconURL,
  platform,
  description,
  managerId,
  state,
}: Props) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showCommonPopup, setShowCommonPopup] = useState("");
  const [loading, setLoading] = useState(false);
  const userRole = useSelector((state) => state.auth.role);
  const userId = useSelector((state) => state.auth.id);
  const manager = useSelector((state) => state.auth.manager);
  const adminOrMadmin = isAdminOrMasteradmin(userRole);
  const { activeData, deleteData, archiveData } = fixture;
  const updateProjectState = useProjectStateUpdate();

  const popupParent = useRef();
  const popupWrapper = useRef(null);

  const onOutsideClick = useCallback((e) => {
    setShowPopup(false);
    let ele = e.target;
    if(!isListItemAndNotOpen(ele, showCommonPopup)) {
      setShowCommonPopup("");
    }
  }, [showCommonPopup]);

  useClickOutside(popupParent, onOutsideClick);

  const dotClasses = classnames({
    "middle-dot": true,
    "dots-active": showPopup,
  });

  const handleUpdate = useCallback(
    async (state) => {
      setLoading(true);
      try {
        const { data } = await ProjectService.updateProjectState(id, {
          state,
        });
        const updatedProject = new ProjectData(data);
        const { name, state: newState } = updatedProject;
        dispatch(updateProjectList(updatedProject));
        dispatch(
          setAlertMsg({
            type: "success",
            msg: `Project ${name} is now ${newState}`,
          })
        );
        setShowCommonPopup("");
      } catch (err) {
        const msg = getErrorMessage(err) || "Error in updating project state";
        dispatch(
          setAlertMsg({
            type: "error",
            msg,
          })
        );
      } finally {
        setLoading(false);
        setShowPopup(false);
        setShowCommonPopup(false);
      }
    },
    [dispatch, id]
  );

  const onSelect = useCallback(
    (listId) => {
      switch (listId) {
        case 1:
          dispatch(updateInfo({ project: { id, name } }));
          redirectToRoute(
            generatePath(PROJECTS, { id }),
            `panel=${UPLOAD_BUILD}`
          );
          break;
        case 2:
          redirectToRoute(
            generatePath(PROJECTS, { id }),
            `panel=${PROJECT_TESTERS}`
          );
          break;
        case 3:
          redirectToRoute(
            generatePath(PROJECTS, { id }),
            `panel=${PROJECT_GROUPS}`
          );
          break;
        case 4:
          redirectToRoute(
            generatePath(PROJECTS, { id }),
            `panel=${EDIT_PROJECT}`
          );
          break;
        case 5:
          setShowCommonPopup("archive");
          break;
        case 6:
        case 22:
          setShowCommonPopup("delete");
          break;
        case 11:
          setShowCommonPopup("restore");
          break;
        case 21:
          setShowCommonPopup("unarchive");
          break;
        default:
      }
    },
    [dispatch, id, name]
  );

  const projectClickHandler = useCallback(() => {
    redirectToRoute(generatePath(PROJECTS, { id }), `panel=${PROJECT_BUILDS}`);
  }, [id]);

  const handlePopup = useCallback(() => {
    handleUpdate(updateProjectState(showCommonPopup));
  }, [handleUpdate, updateProjectState, showCommonPopup]);

  const iconWrapperClasses = classnames({
    "project-icon-wrapper": true,
    dull: !isActive(state),
  });

  const moreOptionsClasses = classnames({
    "more-options": true,
    light: !isActive(state),
  });

  const projectWrapperClasses = classnames({
    "axiom-project-wrapper": true,
  });

  let dropdownData = [];
  switch (state) {
    // don't show the opt(options) 'Archive' & 'Unarchive' for non-admin roles
    // only show the options 'Add Testers' & 'Add New Build' for Testers
    case ACTIVE:
      dropdownData = activeData.filter(
        ({ name }) =>
          adminOrMadmin ||
          (manager && userId === managerId  && name !== TXT_ARCHIVE) ||
          name === TXT_ADD_TESTERS ||
          name === TXT_ADD_NEW_BUILD
      );
      break;
    case ARCHIVED:
      dropdownData = archiveData.filter(
        ({ name }) => adminOrMadmin || name !== TXT_UNARCHIVE
      );
      break;
    case DELETED:
      dropdownData = deleteData;
      break;
    default:
  }

  return (
    <div className={projectWrapperClasses} onClick={projectClickHandler}>
      <div className="image-more-options-wrapper">
        <div className={iconWrapperClasses}>
          <img alt="project-icon" src={iconURL} width="56" height="56" />
        </div>
        {adminOrMadmin || (manager && userId === managerId) || state === ACTIVE ? (
          <div
            className={moreOptionsClasses}
            ref={popupParent}
            role="button"
            tabIndex={0}
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={dotClasses}></div>
            {showPopup && !showCommonPopup ? (
              <div className="group-container" data-testid="group-popup">
                <ListContainer
                  type="dropdown-list"
                  classname="group-popup"
                  data={dropdownData}
                  onSelect={onSelect}
                />
              </div>
            ) : null}
            {showCommonPopup && (
              <div className="delete-confirmation" ref={popupWrapper}>
                <ConfirmationPopup
                  text={`Are you sure to ${showCommonPopup} this project?`}
                  acceptLabel={`Yes, ${capitalize(showCommonPopup)}`}
                  cancelLabel="Cancel"
                  onAccept={handlePopup}
                  onCancel={() => setShowCommonPopup("")}
                  isLoading={loading}
                  displaceRefObj={{
                    refEle: popupWrapper,
                    forListItem: true
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={classnames({ dull: !isActive(state) })}>
        <p className="project-name-title">{name}</p>
        <p className="project-description">{description}</p>
        <div className="project-platforms-wrapper">
          {platform &&
            platform.map((type) => <Icon key={type} name={`${type}-icon`} />)}
        </div>
      </div>
      {!isActive(state) && (
        <div
          className={classnames({ state: true, archived: isArchived(state) })}
        >
          <span className="circle"></span>
          {state}
        </div>
      )}
    </div>
  );
});

Project.type.displayName = "Project";

export { Project };
