import {
  UPDATE_INFO,
  CLEAR_INFO
} from "action-types";

export const initialState = {
  project: null,
  folder: null,
  build: null
};

export function reducer (state, action) {
  const { type, payload } = action;

  switch(type) {
    case UPDATE_INFO:
      return { ...state, ...payload };
    case CLEAR_INFO:
      return initialState;
    default:
      return state;
  }
}