import { generatePath } from "utils/common";
import {
  PROJECTS,
  GROUPS,
  USERS
} from "constants.js";

type TSearchResult = {
  id: string,
  name: string,
  url: string,
  state?: string,
  confirmed?: boolean,
  blocked?: boolean,
}

export type TGlobalSearch = {
  key: string,
  arr: Array<TSearchResult>
};

export class GlobalSearch {
  +key: string;
  +arr: Array<TSearchResult>;

  constructor(raw: Object) {
    this.key = raw.key;
    this.arr = raw.arr.map(obj => {
      const { id } = obj;
      const type = {};

      switch(raw.key) {
        case PROJECTS:
          type.projectId = id;
          break;
        case GROUPS:
          type.groupId = id;
          break;
        case USERS:
          type.userId = id;
          break;
        default:
      }
      
      return {
        ...obj,
        url: generatePath(type)
      }
    });
  }
}
