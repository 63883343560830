import {
  SET_FILTER,
  CLEAR_FILTER,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PLATFORMS,
} from "action-types";

const initialState = [];

const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTER:
      return state.concat(payload);
    case CLEAR_FILTER:
      const reg = Array.isArray(payload) ? RegExp(payload.join("|")): payload;
      return state.filter((ele) => ele.val.search(reg) === -1);
    case CLEAR_ALL_FILTERS:
      return initialState;
    case CLEAR_ALL_PLATFORMS:
      return state.filter((ele) => ele.type !== "platform");
    default:
      return state;
  }
};

export default filterReducer;
