import {
  SHOW_TOAST,
  HIDE_TOAST
} from "action-types";
import { guidGenerator } from "utils/common";

export const showToast = obj => ({
  type: SHOW_TOAST,
  payload: { ...obj, id: guidGenerator() }
});

export const hideToast = id => ({
  type: HIDE_TOAST,
  payload: id
});