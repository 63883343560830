import {
  MY_PROFILE_VIEW,
  USER_LOGOUT,
  NOTIFICATION_VIEW,
  FEEDBACK_VIEW
} from "constants.js";
export const BASE_PATH = "/";
export const LOGIN = "/login";
export const AUTH_PROVIDER = "/auth/:provider";
export const USERS = "/users/:id?";
export const PROJECTS = "/projects/:id?/:fId?/:bId?";
export const FEEDBACKS = "/feedbacks/:id?";
export const GROUPS = "/groups/:id?";
export const FORGOT_PASSWORD = "/forgot-password";
export const DEVICES = "/devices";
export const M_NOTIFICATIONS = "/notifications";
export const M_PROFILE = "/profile/:type?";
export const M_CHANGE_PASSWORD = "/change-password";
export const M_EDIT_NAME = "/edit-name";
export const RESET_PASSWORD = "/reset-password";
export const SET_PASSWORD = "/set-password";

export const searchParam = {
  PROFILE: `?panel=${MY_PROFILE_VIEW}`,
  LOGOUT: `?panel=${USER_LOGOUT}`,
  NOTIFICATION: `?panel=${NOTIFICATION_VIEW}`,
  FEEDBACK: `?panel=${FEEDBACK_VIEW}`
}
