//@flow

import React, { useEffect, memo, useCallback, useRef, useContext } from "react";
import Loadable from 'react-loadable';
import { logIn } from "services/authentication";
import { Route, Switch, Redirect, generatePath, useLocation } from "react-router-dom";
import { BASE_PATH, DEVICES, PROJECTS, M_NOTIFICATIONS, M_PROFILE, M_CHANGE_PASSWORD, M_EDIT_NAME } from "models/routes";
import { Loader } from "components";
import { PromptContext } from "mobile/data/installprompt";
import { setPrompt } from "actions/prompt";
import { isOffline } from "utils/common";

import "./MMain.scss";

const MDeviceView = Loadable({
  loader: () => import("mobile/views/MDeviceView"),
  loading: () => <Loader className="md center" />
});

const MProfile = Loadable({
  loader: () => import("mobile/views/MProfile"),
  loading: () => <Loader className="md center" />
});

const MNotification = Loadable({
  loader: () => import("mobile/views/MNotification"),
  loading: () => <Loader className="md center" />
});

const MChangePassword = Loadable({
  loader: () => import("mobile/views/MChangePassword"),
  loading: () => <Loader className="md center" />
});

const MEditName = Loadable({
  loader: () => import("mobile/views/MEditName"),
  loading: () => <Loader className="md center" />
});

const MProjectList = Loadable({
  loader: () => import("mobile/views/MProjectList"),
  loading: () => <Loader className="md center" />
});

const MFolder = Loadable({
  loader: () => import("mobile/views/MFolder"),
  loading: () => <Loader className="md center" />
});

const MBuild = Loadable({
  loader: () => import("mobile/views/MBuild"),
  loading: () => <Loader className="md center" />
});

const MBuildDetails = Loadable({
  loader: () => import("mobile/views/MBuildDetails"),
  loading: () => <Loader className="md center" />
});


const MMain = memo(({
  match: { params: { provider }},
  location: { search },
  by,
}) => {
  const { pathname } = useLocation();
  const { prompt: { dismissed }, promptsDispatch } = useContext(PromptContext);
  const banner = useRef(null);

  useEffect(() => {
    if(provider && search) {
      logIn(`/auth/${provider}/callback${search}`);
    }
  }, [provider, search]);

  // don't set the prompt event, if the user has dismissed it before
  const ready = useCallback(e => {
    e.preventDefault();
    !dismissed && promptsDispatch(setPrompt(e));
  }, [dismissed, promptsDispatch]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    }
  }, [ready]);

  const handleBanner = useCallback(() => {
    const ele = banner.current;

    if(ele) {
      if(isOffline()) {
        ele.classList.add("show-banner");
      } else {
        ele.classList.remove("show-banner");
      }
    }
  }, []);

  useEffect(() => {
    // check if offline after the initial render
    handleBanner();

    window.addEventListener("offline", handleBanner);
    window.addEventListener("online", handleBanner);

    return () => {
      window.removeEventListener("offline", handleBanner);
      window.removeEventListener("online", handleBanner);
    }
  }, [handleBanner]);

  if(pathname === BASE_PATH) {
    return <Redirect to={generatePath(PROJECTS)} />;
  }

  return (
    <div className="m-main">
      <div className="offline-banner" ref={banner}>Seems like you're offline</div>
      <Switch>
        {/* TODO: optimize the code to handle it in single component (prevent error when clicking back button continuously if made in single component) */}
        <Route path={PROJECTS}>
          <Switch>
            <Route exact path="/projects" component={MProjectList} />
            <Route exact path="/projects/:id" component={MFolder} />
            <Route exact path="/projects/:id/:fId" component={MBuild} />
            <Route exact path="/projects/:id/:fId/:bId" component={MBuildDetails} />
          </Switch>
        </Route>
        <Route path={DEVICES} component={MDeviceView} />
        <Route path={M_PROFILE}>
          <Switch>
            <Route path={M_PROFILE + M_CHANGE_PASSWORD} component={MChangePassword} />
            <Route path={M_PROFILE + M_EDIT_NAME} component={MEditName} />
            <Route exact path={M_PROFILE} component={MProfile} />
          </Switch>
        </Route>
        <Route path={M_NOTIFICATIONS} component={MNotification} />
      </Switch>
    </div>
  )
});

MMain.type.displayName = "MMain";

export { MMain };