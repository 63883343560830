// @flow

import React, { memo } from "react";
import { Group} from "components";
import { useObserver } from "hooks/useObserver";

import "./GroupList.scss";

const GroupList = memo(() => {
  const { data, isEmpty, observer } = useObserver({ type: "groups" });

  return (
    <div className="axiom-grouplist-container">
      <div className="group-list-wrapper">
        {data.map((group) => {
          const { name, id, membersCount, members } = group;
          return (
            <Group
              key={id}
              id={id}
              name={name}
              count={membersCount}
              members={members}
            />
          );
        })}
      </div>

      {isEmpty && <div className="empty-list">You are not part of any group</div>}

      {observer}
    </div>
  );
});

GroupList.type.displayName = "GroupList";

export { GroupList };
