// @flow

import React, { useState, useCallback, useEffect, memo, useMemo } from "react";
import { SearchList } from "components";
import type { TList } from "models/api/list";
import { List } from "models/api/list";
import classnames from "classnames";

import "./DataList.scss";

type Props = {
  label: string,
  classname: string,
  /** dataList is used to filter data from an array passed as prop */
  dataList?: Array<TList>,
  /** callbackFun is mainly used to get data by making api call */
  callbackFun?: () => void,
  /** this function is called on selecting a data */
  setSelectedData: (TList) => void,
  selectedData: TList,
  /** if a value is selected by default */
  defaultValue?: string,
  /** used in the component ListContainer */
  withCreateFolder?: boolean,
  createFolderObj?: Object,
};

const DataList = memo(({
  label,
  classname,
  callbackFun = () => null,
  setSelectedData,
  selectedData = {},
  dataList,
  defaultValue = "",
  withCreateFolder = false,
  createFolderObj = {},
}: Props) => {
  const [value, setValue] = useState(defaultValue);
  const [filteredData, setFilteredData] = useState([]);

  const isValueSet = useMemo(() => selectedData.id && selectedData.name ? true: false, [selectedData]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const clearValues = useCallback(() => {
    setValue("");
    setFilteredData([]);
    setSelectedData({
      id: null,
      name: "",
    });
  }, [setSelectedData]);

  const updateValue = useCallback(
    (val) => {
      setValue(val);
      setSelectedData({
        id: null,
        name: "",
      });
    },
    [setSelectedData]
  );

  const getResponse = useCallback(async () => {
    if(!isValueSet) {
      if(dataList?.length) {
      // filter data array based on the search text
      // remove the selected data from the filteredData array
      const data = dataList.filter(
        (item) => item.name.toLowerCase().search(value.toLowerCase()) !== -1 && item.id !== selectedData.id
      );

      setFilteredData(data);
      } else {
        try {
          const { data } = await callbackFun(value);
          const listData = data.map((usr) => new List(usr));
          setFilteredData(listData);
        } catch (err) {}
      }
    }
  }, [value, callbackFun, selectedData, dataList, isValueSet]);


  useEffect(() => {
    if(dataList?.length) {
      if(!value) {
        setFilteredData(dataList);
      }
    }
  }, [dataList, value]);

  const onSelect = useCallback(
    (id) => {
      // get the newly selected data
      const data: TList = filteredData.find((fData) => fData.id === id);

      setSelectedData(data);
      setFilteredData([]);
      setValue(data.name);
    },
    [setSelectedData, filteredData]
  );

  const containerClasses = classnames({
    "axiom-datalist-container": true,
    [classname]: classname ? true : false,
    "show-folder": withCreateFolder,
  });

  return (
    <div className={containerClasses}>
      {label ? <p className="axiom-datalist-label">{label}</p> : null}

      <SearchList
        classname=""
        searchObj={{
          classes: "has-border",
          value,
          placeholder: "Search",
          updateValue,
          clearValue: clearValues,
          makeAPICall: getResponse,
          delay: 500,
        }}
        listObj={{
          type: "search-list",
          data: filteredData,
          onSelect,
          withCreateFolder,
          createFolderObj
        }}
      />
    </div>
  );
});

DataList.type.displayName = "DataList";

export { DataList };
