import {
  ADD_FEEDBACKS_SUCCESS,
  UPDATE_FEEDBACKS_SUCCESS,
  DELETE_FEEDBACKS_SUCCESS,
  CLEAR_ALL_FEEDBACKS
} from "action-types";

export const addToFeedbackList = feedback => {
  return {
    type: ADD_FEEDBACKS_SUCCESS,
    payload: feedback
  };
};

export const updateFeedbackList = feedback => {
  return {
    type: UPDATE_FEEDBACKS_SUCCESS,
    payload: feedback
  };
};

export const deleteFeedbackList = feedback => {
  return {
    type: DELETE_FEEDBACKS_SUCCESS,
    payload: feedback
  };
};

export const clearFeedbackList = () => {
  return {
    type: CLEAR_ALL_FEEDBACKS
  };
};