import { getDateFormat } from "utils/common";

export type TFeedback = {
  id: number,
  type: string,
  complete: boolean,
  description: string,
  platform: string,
  user: string,
  date: string
};

export class Feedback {
  +id: number;
  +type: string;
  +complete: boolean;
  +description: string;
  +platform: string;
  +user: string;
  +date: string;

  constructor(raw: Object) {
    this.id = raw.id;
    this.type = raw.type;
    this.complete = raw.complete;
    this.description = raw.description;
    this.platform = raw.platform;
    this.user = raw.user;
    this.date = getDateFormat(raw.date, { shortMonth: true });
  }
}