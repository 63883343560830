// @flow

import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MobileHeader } from 'mobile/components';
import { ToastContext } from 'mobile/data/toast';
import { showToast } from 'mobile/data/toast/action';
import UserDataService from "services/usersService";
import { Button, Input } from 'components';
import { getErrorMessage, goBack, isOffline } from "utils/common";
import { Name } from "models/api/myProfile";
import { setAuthUser } from 'actions/auth';
import "./MEditName.scss";

const MEditName = () => {
  const { firstName, lastName } = useSelector((state) => state.auth);
  const { toastDispatch } = useContext(ToastContext);
  const [fn, setFn] = useState(firstName);
  const [ln, setLn] = useState(lastName);
  const [loading, setLoading] = useState(false);
  const input = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if(input && input.current) {
      input.current.focus();
    }
  }, []);

  const save = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await UserDataService.updateMyProfile({
        firstName: fn,
        lastName: ln,
      });
      const modifiedData = new Name(data);
      dispatch(setAuthUser(modifiedData));
      toastDispatch(showToast({
        type: "success",
        msg: "Name updated successfully"
      }));
      goBack();
    } catch (err) {
      if(!isOffline()) {
        const msg = getErrorMessage(err) || "Error in updating your profile info";
        toastDispatch(showToast({
          type: "error",
          msg
        }));
      }
    } finally {
      setLoading(false);
    }
  }, [toastDispatch, dispatch, fn, ln]);

  return (
    <>
      <MobileHeader
        showLogo={false}
        withSearch={false}
        centeredText=""
        lastIcon="no-icon"
      />
      <section className="m-edit-name-wrapper">
        <h2 className="edit-text">Edit Name</h2>
        <Input
          className="name first"
          type="text"
          name="first-name"
          placeholder="First Name"
          required={true}
          value={fn}
          onChange={e => setFn(e.target.value)}
          reference={input}
        />
        <Input
          className="name last"
          type="text"
          name="last-name"
          placeholder="Last Name"
          required={true}
          value={ln}
          onChange={e => setLn(e.target.value)}
        />
        <div className="save-btn-wrapper">
          <Button
            classes="save-button"
            click={save}
            disabled={(firstName === fn && lastName === ln) || loading}
            text="Save"
          />
        </div>
      </section>
    </>
  );
};

export { MEditName };