import projectIcon from "assets/images/project-icon.png";

export type TProject = {
  id: number,
  name: string,
  description: string,
  iconURL: string,
  platform: Array<string>,
  managerId: number,
  state: string,
};

export class Project {
  +id: number;
  +name: string;
  +description: string;
  +iconURL: string;
  +platform: Array<string>;
  +managerId: number;
  +state: string;

  constructor(raw: Object) {
    this.id = raw.id;
    this.name = raw.name;
    this.description = raw.description;
    this.iconURL = raw.iconURL || projectIcon;
    this.platform = raw.platform ? raw.platform.split(","): [];
    this.managerId = raw.managerId;
    this.state = raw.state;
  }
}
