import { getDateFormat } from "utils/common";

export type TUsersList = {
  id: number,
  name: string,
  email: string,
  role: string,
  DOJ: string,
  blocked: boolean,
  confirmed: boolean,
  inviter: string,
  userType: string,
};

export class UsersList {
  +id: number;
  +name: string;
  +email: string;
  +role: string;
  +DOJ: string;
  +blocked: boolean;
  +confirmed: boolean;
  +inviter: string;
  +userType: string;

  constructor(raw: Object) {
    this.id = raw.id;
    this.name = raw.name;
    this.email = raw.email;
    this.role = raw.role;
    this.DOJ = getDateFormat(raw.DOJ, { shortMonth: true, withHyphen: false});
    this.blocked = raw.blocked;
    this.confirmed = raw.confirmed;
    this.inviter = raw.inviter;
    this.userType = raw.userType;
  }
}
