export type TAuthUser = {
  email: string,
  id: number,
  firstName: string,
  lastName: string,
  role: string,
  manager: boolean,
};

export class AuthUser {
  +email: string;
  +id: number;
  +firstName: string;
  +lastName: string;
  +role: string;
  +manager: boolean;

  constructor(raw: Object) {
    this.email = raw.email;
    this.id = raw.id;
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
    this.role = raw.role;
    this.manager = raw.manager;
  }
}
