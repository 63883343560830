import {
  ADD_GROUPS_SUCCESS,
  UPDATE_GROUPS_SUCCESS,
  CLEAR_ALL_GROUPS,
  DELETE_GROUP_SUCCESS,
} from "action-types";

const initialState = [];
const groups = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_GROUPS_SUCCESS:
      return [...state, ...payload];
    case CLEAR_ALL_GROUPS:
      return initialState;
    case UPDATE_GROUPS_SUCCESS:
      return state.map((group) => (group.id === payload.id ? payload : group));
    case DELETE_GROUP_SUCCESS:
      const newGroupList = state.filter((group) => group.id !== payload.id);
      return newGroupList;
    default:
      return state;
  }
};

export default groups;
