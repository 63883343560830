// @flow

import React, { useCallback } from "react";
import classnames from "classnames";
import { Build } from "components";
import { useDispatch } from "react-redux";
import { BuildUpdate } from "models/api/buildUpdated";
import BuildDataService from "services/buildService";
import { useObserver } from "hooks/useObserver";
import { updateBuildList } from "actions/buildActions";
import { redirectToRoute } from "utils/common";
import { generatePath } from 'react-router-dom';
import { useParamPanelValue } from "hooks/useParamPanelValue";
import { PROJECTS } from "models/routes";

import "./BuildList.scss";

type Props = {
  setBuildFocusType: Function
};

const BuildList = ({ setBuildFocusType }: Props) => {
  const dispatch = useDispatch();

  const { id: projectId, fId: folderId, bId: buildId } = useParamPanelValue();
  const { data, isEmpty, observer } = useObserver({ type: "builds", folderId });

  const setBuildInfo = useCallback(
    (id, type) => {
      if(type) {
        setBuildFocusType(type);
      }
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("isGraph");
      redirectToRoute(generatePath(PROJECTS, {id: projectId, fId: folderId, bId: id}), searchParams.toString());
    },
    [projectId, folderId, setBuildFocusType]
  );

  const handleDeleteBuild = useCallback(
    async (id) => {
      const build = await BuildDataService.deleteOrRestoreBuild(id);
      const updatedBuild = new BuildUpdate(build.data);
      dispatch(updateBuildList(updatedBuild));
    },
    [dispatch]
  );

  const getBuildClasses = (id) =>
    classnames({
      active: id === buildId,
    });

  return (
    <div className="axiom-build-list-container">
      <div className="build-list-wrapper">
        {data.map((build) => {
          const { id, name, uploadedOn, link, deleted } = build;
          return (
            <Build
              key={id}
              id={id}
              name={name}
              date={uploadedOn}
              link={link}
              deleted={deleted}
              classname={getBuildClasses(id)}
              click={setBuildInfo}
              handleDeleteBuild={() => handleDeleteBuild(id)}
            />
          );
        })}
      </div>
      {observer}
      {isEmpty && (
        <div className="no-builds">No builds under this folder</div>
      )}
    </div>
  );
};

export { BuildList };
