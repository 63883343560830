import { useLocation, useParams } from 'react-router-dom';

// this hook returns the id, fId (folder id), bId (build id), and the search param 'panel' value
export const useParamPanelValue = () => {
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { id, fId, bId } = params;
  const panel = query.get("panel");

  return {
    id: Number(id),
    fId: Number(fId),
    bId: Number(bId),
    panel
  };
};