export type TGroup = {
  id: number,
  membersCount: number,
  name: string,
  members: Array<Object>,
};

export class Group {
  +id: number;
  +membersCount: number;
  +name: string;
  +members: Array;

  constructor(raw: Object) {
    this.id = raw.id;
    this.membersCount = raw.membersCount;
    this.name = raw.name;
    this.members = raw.members;
  }
}
