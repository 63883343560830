// @flow

import React from "react";
import AlertMsg from "./AlertMsg";
import { useSelector } from "react-redux";
import "./AlertPanel.scss";

const Alert = (props: {}) => {
  // TODO:  Use this same alert panel for displaying selected users in users view
  //        get the isOpen parameter from the usersSelected redux state
  //        if true add it to the components array

  const components = [];
  const isAlertMsgOpen = useSelector((state) => state.alert.message.isOpen);

  if (isAlertMsgOpen) {
    components.push(<AlertMsg key="msg" />);
  }

  if (components.length) {
    return (
      <div className="axiom-alert-panel-container" data-testid="alert-panel">
        {components}
      </div>
    );
  } else {
    return null;
  }
};

export default Alert;
