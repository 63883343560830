import {
  SET_PROMPT_VALUE,
  CLEAR_PROMPT_VALUE
} from "action-types";

export const setPrompt = e => ({
  type: SET_PROMPT_VALUE,
  payload: e
});

export const clearPrompt = () => ({
  type: CLEAR_PROMPT_VALUE
});