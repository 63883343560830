import {
  OPEN_SEARCH,
  CLOSE_SEARCH,
  UPDATE_SEARCH_VALUE,
  ADD_SEARCH_DATA
} from "action-types";

export const openSearch = () => ({
  type: OPEN_SEARCH
});

export const closeSearch = () => ({
  type: CLOSE_SEARCH
});

export const updateSearch = (value) => ({
  type: UPDATE_SEARCH_VALUE,
  payload: value
});

export const addSearchData = (data) => ({
  type: ADD_SEARCH_DATA,
  payload: data
});

