import { MBuildDetails } from "./mBuildDetails";
import type { TMBuildDetails } from "./mBuildDetails";

export type TLatestBuild = TMBuildDetails & {
  oldBuilds: boolean
};

export class LatestBuild extends MBuildDetails {
  +oldBuilds: boolean;

  constructor({ oldBuilds, ...rest }: Object) {
    super(rest);
    this.oldBuilds = oldBuilds;
  }
}