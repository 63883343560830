// @flow

import React, { useState, memo } from "react";
import { Icon, ToolTip } from "components";
import classnames from "classnames";
import "./PillButton.scss";

// 2 types of pill buttons: sm, md
// size: "sm" - small sized pill button styles
// size: "md" - medium sized pill button styles

type Props = {
  size: "sm" | "md",
  text: string,
  dataId: number,
  /** show tooltip when hovering on the close icon */
  showTooltip?: boolean,
  tpPos?: string,
  tpText?: string,
  /** function called on clicking the close icon */
  onRemove?: (number) => void,
};

const PillButton = memo(({
  size,
  text,
  onRemove,
  dataId,
  // optional params
  // tooltip not shown by default
  showTooltip = false,
  // tooltip position
  tpPos = "",
  // tooltop text
  tpText = "",
}: Props) => {
  const [onHover, setOnHover] = useState(false);

  const onClick = () => {
    onRemove && onRemove(dataId);
  };

  const pillContainerClasses = classnames({
    "axiom-pill-btn-container": true,
    [size]: size ? true : false,
  });

  // styled clear icon classes
  const styledclClasses = classnames({
    "styled-cl": true,
    highlight: onHover,
  });

  return (
    <div className={pillContainerClasses}>
      <div className="pill-wrapper">
        <div className="text">{text}</div>
        {showTooltip ? (
          <div>
            {onRemove && (
              <div className={styledclClasses}>
                <ToolTip title={tpText} position={tpPos}>
                  <div
                    className="clear-icon"
                    onClick={onClick}
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon name="clear-icon" />
                  </div>
                </ToolTip>
              </div>
            )}
          </div>
        ) : (
          <div>
            {onRemove && (
              <div
                className="clear-icon"
                onClick={onClick}
                role="button"
                tabIndex={0}
              >
                <Icon name="clear-icon" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

PillButton.type.displayName = "PillButton";

export { PillButton };
