import http from "http-common";

// common function to create feature, bug or help
const createFeedback = (data) => {
  return http.post("/feedbacks", data);
};

const getFeedbacks = (query) => {
  return http.get(`/feedbacks${query}`);
};

const getFeedbackDetails = id => {
  return http.get(`/feedbacks/${id}`);
};

const deleteFeedback = id => {
  return http.delete(`/feedbacks/${id}`);
};

// invert the value of complete parameter in feedback
const updateFeedback = id => {
  return http.put(`/feedbacks/${id}`);
};

export default {
  createFeedback,
  getFeedbacks,
  getFeedbackDetails,
  deleteFeedback,
  updateFeedback
};