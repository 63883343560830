export type TDevice = {
  id: number,
  type: "android" | "ios",
  model: "string",
  name: "string",
  os_version: "string",
  current_device: boolean
};

export class Device {
  +id: number;
  +type: string;
  +model: string;
  +name: string;
  +os_version: string;
  +current_device: boolean;

  constructor(raw: Object) {
    this.id = raw.id;
    this.type = raw.type;
    this.model = raw.model;
    this.name = raw.name;
    this.os_version = raw.os_version;
    this.current_device = raw.current_device;
  }
}