//@flow

import React, { useCallback } from 'react';
import { Icon } from 'components';
import { TList } from "models/api/list"
import "./MobileCard.scss";

type Props = {
  id: number,
  name: String,
  click: (TList) => void
};

const MobileCard = ({ id, name, click }: Props) => {
  const clickFun = useCallback(() => {
    click({id, name});
  }, [id, name, click]);

  return (
    <div className="m-card-container" onClick={clickFun} data-testid="mobile-card">
      <div className="m-card-wrapper">
        <span className="name">{name}</span>
        <Icon classname="right-arrow" name="m-left-arrow" />
      </div>
    </div>
  );
};

export { MobileCard };