import { SHOW_OVERLAY, HIDE_OVERLAY } from "action-types";

const initialState = false;

const overlayReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_OVERLAY:
      return true;
    case HIDE_OVERLAY:
      return initialState;
    default:
      return state;
  }
};

export default overlayReducer;
