import {
  ADD_PROJECTS_SUCCESS,
  UPDATE_PROJECTS_SUCCESS,
  DELETE_PROJECTS_SUCCESS,
  CLEAR_ALL_PROJECTS,
} from "action-types";

const initialState = [];
const projects = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_PROJECTS_SUCCESS:
      return [...state, ...payload];
    case CLEAR_ALL_PROJECTS:
      return initialState;
    case UPDATE_PROJECTS_SUCCESS:
      return state.map((project) =>
        project.id === payload.id ? payload : project
      );
    case DELETE_PROJECTS_SUCCESS:
      const newGroupList = state.filter((project) => project.id !== payload.id);
      return newGroupList;
    default:
      return state;
  }
};

export default projects;
