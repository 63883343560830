import {
  ADD_PROJECTS_SUCCESS,
  UPDATE_PROJECTS_SUCCESS,
  DELETE_PROJECTS_SUCCESS,
  CLEAR_ALL_PROJECTS,
} from "action-types";

export const initialState = [];

export function reducer(state, action) {
  const { payload, type } = action;

  switch(type) {
    case ADD_PROJECTS_SUCCESS:
      return [...state, ...payload];
    case CLEAR_ALL_PROJECTS:
      return initialState;
    case UPDATE_PROJECTS_SUCCESS:
      return state.map((project) =>
        project.id === payload.id ? payload : project
      );
    case DELETE_PROJECTS_SUCCESS:
      return state.filter((project) => project.id !== payload.id);
    default:
      return state;
  }
}