//@flow

import React, { useContext } from 'react';
import classnames from "classnames";
import { Button, Icon } from "components";
import { redirectToRoute } from "utils/common";
import { useLocation } from "react-router-dom";
import { PromptContext } from "mobile/data/installprompt";
import { clearPrompt } from "actions/prompt";
import fixture from "./fixture.json";
import "./MNavBar.scss";

const { navItems } = fixture;

const MNavBar = () => {
  const { pathname } = useLocation();
  const { prompt: { prompt }, promptsDispatch } = useContext(PromptContext);
  return (
    <div className="m-navbar-container" data-testid="m-navbar">
      { navItems.map(navItem => {
        const { classes, iconName, iconText, route } = navItem;

        const classNames = classnames(
          "m-nav-btn", [classes],
          {
          "active": pathname.search(route) > -1
          }
        );

        return (
          <Button key={iconName} click={async () => {
            redirectToRoute(route);
            // when the user clicks on any bottom navigation item, the install app prompt is shown (if the user has not dismissed it) 
            if(prompt) {
              prompt.prompt().catch(err => {
                console.error(err);
              });
              try {
                const { outcome } = await prompt.userChoice;
                if(outcome !== "accepted") {
                  promptsDispatch(clearPrompt());
                }
              } catch (err) {
                console.error(err);
              }
            }
          }} classes={classNames}>
            <Icon classname="nav-icons" name={iconName} />
            <span className="nav-icon-name">{iconText}</span>
          </Button>
        );
      })}
    </div>
  )
};

export { MNavBar };