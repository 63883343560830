// @flow

import React, { useState, memo } from "react";
import classnames from "classnames";
import { Button, Icon, Input } from "components";
import "./Search.scss";

type Props = {
  classname: string,
  search: string,
  placeholder: string,
  onSearch: (SyntheticInputEvent<HTMLInputElement>) => void,
  onClear: () => void,
  onInputFocus?: () => void,
  onKeyDown?: () => void,
  inputRef?: string,
  withCancelObj?: {
    showCancelBtn: false,
    click: () => void
  }
};

const Search = memo(({
  classname,
  search,
  placeholder,
  onSearch,
  onClear,
  // onInputFocus is optional
  onInputFocus,
  onKeyDown,
  inputRef = null,
  withCancelObj: {
    showCancelBtn = false,
    click = () => null
  } = {}
}: Props) => {
  const [isFocussed, setIsFocussed] = useState(false);

  const onFocus = () => {
    onInputFocus && onInputFocus();
    setIsFocussed(true);
  };

  const searchClasses = classnames({
    "axiom-search": true,
    [classname]: classname ? true : false,
    "with-cancel-btn": showCancelBtn
  });

  const iconClasses = classnames({
    "search-icon": true,
    focussed: isFocussed ? true : false,
  });

  return (
    <div className={searchClasses}>
      <div className="input-wrapper">
        <Input
          className="search-bar-wrapper"
          type="text"
          name="search"
          placeholder={placeholder}
          required={false}
          value={search}
          onChange={onSearch}
          onFocus={onFocus}
          onBlur={() => setIsFocussed(false)}
          onKeyDown={onKeyDown}
          reference={inputRef}
        />
        <div className={iconClasses}>
          <Icon name="search-icon" />
        </div>
        {search.length ? (
          <div className="clear-icon" onClick={onClear} tabIndex={0}>
            <Icon name="clear-icon" />
          </div>
        ) : null}
        
        {showCancelBtn && (
          <div className="cancel-btn">
            <Button
              className=""
              click={click}
              text="Cancel"
            />
          </div>
        )}
      </div>
    </div>
  );
});

Search.type.displayName = "Search";

export { Search };
