import { SET_ALERT_MSG, CLEAR_ALERT_MSG } from "action-types";

const initialState = {
  message: {
    type: "",
    msg: "",
    btnText: "",
    isOpen: false,
  },
};

const alertReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT_MSG:
    case CLEAR_ALERT_MSG:
      return { ...state, message: payload };
    default:
      return state;
  }
};

export default alertReducer;
