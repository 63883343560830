import { SET_SORT_VALUE } from "action-types";

const initialState = {
  by: null,
  ASC: true,
};

const sortReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SET_SORT_VALUE) {
    return { ...state, ...payload };
  }
  return state;
};

export default sortReducer;
