// @flow

import React, { useState, useCallback, useEffect, memo, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterComponent, PillButton } from "components";
import { isAdminOrMasteradmin, getBasePath, isMAdmin } from "utils/common";
import { setFilter, clearFilter } from "actions/filter";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { PROJECTS, USERS, GROUPS, FEEDBACKS } from "models/routes";
import { clearAllFilters } from "actions/filter";
import fixture from "components/Filter/fixture.json";

import "./Filter-Pill-Wrapper.scss";

const FilterPillWrapper = memo(() => {
  const selectedFilters = useSelector((state) => state.filter);
  const manager = useSelector((state) => state.auth.manager);
  const userRole = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userFilter, projectFilter, groupFilter, feedbackFilter } = fixture;
  const allProjects = useRef(null);

  // check whether current user has admin roles or not
  const adminOrMadmin = isAdminOrMasteradmin(userRole);
  const mAdmin = useMemo(() => isMAdmin(userRole), [userRole]);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(data);

  const pathUsers = pathname.search(getBasePath(USERS)) > -1;
  const pathProjects = pathname.search(getBasePath(PROJECTS)) > -1;
  const pathGroups =  pathname.search(getBasePath(GROUPS)) > -1;
  const pathFeedbacks = pathname.search(getBasePath(FEEDBACKS)) > -1;

  // get only the top level path
  const topLevelPath = pathname.split("/")[1];

  useEffect(() => {
    switch (true) {
      case pathUsers:
        setData(
          userFilter.filter((ele) =>
            !adminOrMadmin && ele.key === "blocked" ? false : true
          )
        );
        break;
      case pathProjects:
        setData(
          projectFilter.filter((ele) =>
            !(adminOrMadmin || manager) && /state|me/.test(ele.key)
              ? false
              : true
          )
        );
        break;
      case pathGroups:
        setData(groupFilter);
        break;
      case pathFeedbacks:
        setData(feedbackFilter.filter(ele => !mAdmin && /state/.test(ele.key)? false: true));
        break;
      default:
    }
  }, [
    topLevelPath,
    adminOrMadmin,
    projectFilter,
    userFilter,
    groupFilter,
    userRole,
    pathUsers,
    pathProjects,
    pathGroups,
    manager,
    feedbackFilter,
    pathFeedbacks,
    mAdmin
  ]);

  useEffect(() => {
    if (data.length) {
      setFilterData(data.map((ele) => ele.data.map(() => false)));
      if(pathProjects) {
        allProjects.current = true;
      }
    }
  }, [data, pathProjects]);

  const clearPlatformsState = useCallback(() => {
    dispatch(clearAllFilters());
  }, [dispatch]);

  useEffect(() => {
    if(!selectedFilters.length) {
      setFilterData(data.map((ele) => ele.data.map(() => false)));
    }
  }, [selectedFilters, data]);

  useEffect(() => {
    // enable 'all', 'active', 'archive' and 'deleted' options in filter to display all projects on entering the projects path
    if(pathProjects && filterData?.[1]?.length === data?.[1]?.data?.length && allProjects.current) {
      const newState = filterData.map(arr => arr);
      // index 1 refers to the key state in projectFilter fixture
      const index = 1;
      const len = newState[index]?.length;

      for(let i=0; i<len; i++) {
        newState[index][i] = true;
      }

      setFilterData(newState);
      allProjects.current = false;
    }

    if(!pathProjects) {
      allProjects.current = false;
    }
  }, [pathProjects, dispatch, projectFilter, filterData, data]);

  const onClick = useCallback(
    (index, i, dataVal) => {
      // TODO: change logic for filter
      const newState = filterData.map((arr) => arr);
      const value = newState[index][i];
      if(pathProjects && index === 1) {
        const no = newState[index].length;
        const newArr = [];

        newState[index][i] = !value;

        if(i === 0) {
          // if all projects is true, select active, archived and deleted projects
          if(value) {
            for(let j=1; j < no; j++) {
              if(newState[index][j]) {
                newState[index][j] = !value;
                newArr.push(projectFilter[index]["data"][j]);
              }
            }

            dispatch(clearFilter(newArr));
          } else {
            for(let j=1; j < no; j++) {
              if(!newState[index][j]) {
                newState[index][j] = !value;
                newArr.push((() => {
                  const { key, data } = projectFilter[index];
                  return {
                    type: key,
                    val: data[j]
                  };
                })());
              }
            }

            dispatch(setFilter(newArr));
          }

          return setFilterData(newState);
        } else {
          if(value) {
            newState[index][0] = false;
          } else {
            const allTrue = newState[index].slice(1).reduce((v, iV) =>  v && iV, true);
            
            if(allTrue) {
              // if select active, archived and deleted projects are true, select all projects
              newState[index][0] = true;
            }
          }
        }
      } else {
        newState[index][i] = !value;
      }
      setFilterData(newState);
      if (!value) {
        dispatch(setFilter({ type: data[index].key, val: dataVal }));
      } else {
        dispatch(clearFilter(dataVal));
      }
    },
    [dispatch, filterData, data, pathProjects, projectFilter]
  );

  const onRemove = useCallback(
    (dataVal) => {
      let i, j;
      for (i = 0; i < data.length; i++) {
        for (j = 0; j < data[i].data.length; j++) {
          if (data[i].data[j] === dataVal) {
            const newState = filterData.map((arr) => arr);
            newState[i][j] = false;
            if(pathProjects && i === 1) {
              // on removing any project state, deselect all projects
              newState[i][0] = false;
            }
            setFilterData(newState);
            dispatch(clearFilter(dataVal));
            break;
          }
        }
      }
      return;
    },
    [dispatch, filterData, data, pathProjects]
  );

  const containerClasses = classnames({
    "filter-pill-container": true,
    "selected-filters": selectedFilters.length > 0,
    "is-projects": pathname === PROJECTS,
  });

  // show the filter only for admin roles for the path GROUPS
  // don't show the filter for routes other than USERS and PROJECTS
  if(!pathUsers && !pathProjects && !(pathGroups && adminOrMadmin) && !pathFeedbacks) {
    return null;
  }

  return (
    <div className={containerClasses}>
      <FilterComponent
        data={data}
        filterData={filterData}
        onClick={onClick}
        boxTyped={pathname === PROJECTS}
        clearPlatformsState={clearPlatformsState}
        showClear={!pathGroups}
      />
      <div className="selected-filter-wrapper">
        {selectedFilters.map((selFil) => {
          let text = "";
          if (pathname === USERS) {
            // Append text Users at the end for path USERS
            text = `${selFil.val} Users`;
          } else {
            text = selFil.val;
          }

          return (
            <PillButton
              key={selFil.val}
              dataId={selFil.val}
              size="sm"
              text={text}
              onRemove={onRemove}
              showTooltip={true}
              tpPos="top"
              tpText="Remove"
            />
          );
        })}
      </div>
    </div>
  );
});

FilterPillWrapper.type.displayName = "FilterPillWrapper";

export { FilterPillWrapper };
