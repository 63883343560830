import http from "http-common";
import { GRANTED, DENIED, DEFAULT } from "constants.js";
import firebase from "firebase.js";

const registerBrowser = token => {
  return http.post("/browsers", { token });
};

const unRegisterBrowser = token => {
  return http.delete(`/browsers/${token}`);
};

// request the user to take action on notifications
export const notify = async () => {
  let token;
  if(!isDenied()) {
    try {
      const res =  await Notification.requestPermission();
      if(res === GRANTED) {
        const messaging = firebase.messaging();
        if(messaging) {
          token = await messaging.getToken();

          // add the token to the database
          await registerBrowser(token);
        }
      }
    } catch (err) {
      return err;
    }
  }
};

export const unNotify = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    const deleted = await messaging.deleteToken();
    if(deleted) {
      // remove the token from the server
      await unRegisterBrowser(token);
    } else {
      throw new Error("Error deleting the token");
    }
  } catch (err) {
    return err;
  }
};

export const isDefault = () => window.Notification.permission === DEFAULT;
export const isGranted = () => window.Notification.permission === GRANTED;
export const isDenied = () => window.Notification.permission === DENIED;

export default {
  registerBrowser,
  unRegisterBrowser
};