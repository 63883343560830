// @flow

import React, { useState, useCallback, memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SearchList } from "components";
import UserDataService from "services/usersService";
import { GlobalSearch as GlobalSearchClass } from "models/api/globalSearch";
import { setAlertMsg } from "actions/alertMsg";
import { getErrorMessage, redirectToRoute } from "utils/common";

import "./GlobalSearch.scss";

const GlobalSearch = memo((props: {}) => {
  const [searchValue, setSearchValue] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getResponse = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await UserDataService.globalSearch(searchValue);
      const modifiedData = data.map((obj) => new GlobalSearchClass(obj));
      setResponseData(modifiedData);
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in search";
      dispatch(setAlertMsg({ type: "error", msg }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    !searchValue && setResponseData([]);
  }, [searchValue]);

  const onClear = useCallback(() => {
    setSearchValue("");
  }, []);

  const onSelect = useCallback((url) => {
    setSearchValue("");
    redirectToRoute(url);
  }, []);

  return (
    <div className="axiom-global-search-container">
      <SearchList
        classname="global-search"
        isLoading={loading}
        searchObj={{
          value: searchValue,
          updateValue: setSearchValue,
          clearValue: onClear,
          makeAPICall: getResponse,
          delay: 500,
          placeholder: "Search",
        }}
        listObj={{
          type: "global-search",
          data: responseData,
          onSelect
        }}
      />
    </div>
  );
});

GlobalSearch.type.displayName = "GlobalSearch";

export { GlobalSearch };
