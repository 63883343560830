// @flow

import React from 'react';
import { Button, Icon, Input } from 'components';
import "./MInputPassword.scss";

type Props = {
  name: string,
  value: string,
  placeholder: string,
  disabled: boolean,
  className: string,
  onChange: () => void,
  showIcon: boolean,
  error: string,
  showPassword: boolean,
  toggleShowPassword: () => void
}

const MInputPassword = ({
  // input props
  name,
  value,
  placeholder,
  disabled = false,
  className,
  onChange,
  // other props
  showIcon,
  error,
  showPassword,
  toggleShowPassword
}: Props) => {
  return (
    <div className="m-input-password-wrapper">
      <div className="password-wrapper">
        <Input
          name={name}
          type={showPassword? "password": "text"}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          className={className}
          onChange={onChange}
        />
        {
          showIcon && (
            <div className="eye-wrapper">
              <Button click={toggleShowPassword}>
                <Icon classname="eyes" name={showPassword ? "open-eye": "closed-eye"} />
              </Button>
            </div>
          )
        }
      </div>
      { error && <span data-testid="error" className="password-error">{error}</span>}
    </div>
  );
};

export { MInputPassword };