// @flow
import React, { memo } from "react";
import classnames from "classnames";

import "./TextArea.scss";

type props = {|
  placeholder: string,
  onChange: (Object) => void,
  label: string,
  className: string,
  id: number | string,
  name: string,
  value: string,
  maxLength: number,
|};

const TextArea = memo(({
  placeholder,
  onChange,
  label,
  className,
  id,
  name,
  value,
  maxLength,
}: props) => {
  const textAreaWrapper = classnames({
    "axiom-textarea-field": true,
    className,
  });
  return (
    <div>
      {label ? (
        <label htmlFor={id} className="axiom-textarea-label">
          {label}
        </label>
      ) : null}
      <div className={textAreaWrapper}>
        <textarea
          className="axiom-textarea"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          id={id}
          name={name}
          maxLength={maxLength}
        />
        <p className="text-area-count">{`${value.length}/${maxLength}`}</p>
      </div>
    </div>
  );
});

TextArea.type.displayName = "TextArea";

export { TextArea };
