import http from "http-common";

const createFolder = (data) => {
  return http.post("/folders", data);
};

const renameFolder = (id, data) => {
  return http.put(`/folders/${id}/name`, data);
};

const getBuildsList = (filter) => {
  return http.get(`/builds/${filter}`);
};

const deleteRestoreFolder = (id) => {
  return http.delete(`/folders/${id}`);
};

const getLatestBuild = (id) => {
  return http.get(`/folders/${id}/latest-build`);
};

const getFolderDefault = (id) => {
  return http.get(`/folders/${id}/default`);
};

const updateFolderDefault = (id, data) => {
  return http.put(`/folders/${id}/default`, data);
};

const getBuildsGraph = id => {
  return http.get(`folders/${id}/graph`);
};

export default {
  createFolder,
  renameFolder,
  getBuildsList,
  deleteRestoreFolder,
  getLatestBuild,
  getFolderDefault,
  updateFolderDefault,
  getBuildsGraph
};
