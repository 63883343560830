import {
  ADD_BUILDS_SUCCESS,
  UPDATE_BUILDS_SUCCESS,
  DELETE_BUILDS_SUCCESS,
  CLEAR_ALL_BUILDS,
} from "action-types";

const initialState = [];

const builds = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_BUILDS_SUCCESS:
      return [...state, ...payload];
    case UPDATE_BUILDS_SUCCESS:
      return state.map((build) => (build.id === payload.id ? ({...build, ...payload}) : build));
    case DELETE_BUILDS_SUCCESS:
      return state.filter((build) => build.id !== payload.id);
    case CLEAR_ALL_BUILDS:
      return initialState;
    default:
      return state;
  }
};

export default builds;
