import React, { useReducer, createContext } from "react";

import { reducer, initialState } from "./reducer";

export const PromptContext = createContext();

export const PromptContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PromptContext.Provider value={{
      prompt: state,
      promptsDispatch: dispatch
    }}>
      {children}
    </PromptContext.Provider>
  );
};