import React from 'react';
import { Button, Icon } from 'components';
import "./BackButton.scss";

type Props = {
  click: () => void
};

const BackButton = ({ click = () => null}: Props) => {
  return (
    <div className="m-back-button-wrapper" data-testid="back-button">
      <Button click={click}>
        <Icon classname="left-arrow" name="m-left-arrow" />
      </Button>
    </div>
  );
};

export { BackButton };