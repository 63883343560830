// @flow

import React, { useMemo, useCallback, memo, useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSortInfo } from "actions/sort";
import { Icon } from "components";
import { getBasePath } from "utils/common";
import { USERS, FEEDBACKS } from "models/routes";
import fixture from "./fixture.json";
import "./UserHeadingTab.scss";

type Sort = {
  by: string,
  ASC: boolean,
};

const { userheading: userHeadingObj, feedbackHeading: feedbackObj } = fixture;


const UserHeadingTab = memo((props: {}) => {
  const sort: Sort = useSelector((state) => state.sort);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [dataObj, setDataObj] = useState({});

  const pathUsers = pathname.search(getBasePath(USERS)) > -1;
  const pathFeedbacks = pathname.search(getBasePath(FEEDBACKS)) > -1;

  useEffect(() => {
    let obj = [];

    switch(true) {
      case pathUsers:
        obj = userHeadingObj;
        break;
      case pathFeedbacks:
        obj = feedbackObj;
        break;
      default:
    }

    setDataObj(obj);
  }, [pathUsers, pathFeedbacks]);

  const setSortValues = useCallback(
    (sortBy) => {
      const sortObj = {};
      if (sort.by !== sortBy) {
        sortObj.by = sortBy;
        // doj and date are based on fixture values
        sortObj.ASC = /doj|date/.test(sortBy) ? false : true;
      } else {
        sortObj.ASC = !sort.ASC;
      }
      dispatch(setSortInfo(sortObj));
    },
    [dispatch, sort]
  );

  const displayHeadings = useMemo(() => {
    return (
      <div className="heading-wrapper">
        {Object.keys(dataObj).map((key) => {
          const headingStyles = classnames(
            {
              "user-heading": true,
              active: key === sort.by ? true : false,
              "arrow-down": !sort.ASC ? true : false,
            },
            key
          );
          return (
            <div className={headingStyles} key={key} data-testid="user-heading">
              <div className="text" onClick={() => setSortValues(key)}>
                {dataObj[key]}
                <Icon name="dropdown-icon" classname="dropdown-icon" />
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [setSortValues, sort, dataObj]);

  const containerStyles = classnames(
    "axiom-userheading-container",
    {
      "users-list": pathUsers,
      "feedback-list": pathFeedbacks
    }
  );
  
  return (
    <div className={containerStyles} data-testid="user-heading-tab">
      {displayHeadings}
    </div>
  );
});

UserHeadingTab.type.displayName = "UserHeadingTab";

export { UserHeadingTab };
