// @flow

import React, { useState, useRef, useCallback } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { Icon, ConfirmationPopup, ToolTip } from "components";
import "./Device.scss";

type Props = {
  id: number,
  name: string,
  model: string,
  version: string,
  deleteFun?: (id) => void | null,
  showDeleteIcon: boolean
};

const Device = ({ id, name, model, version, deleteFun = null, showDeleteIcon }: Props) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const deleteEle = useRef();

  const onOutsideClick = useCallback(() => setDeleteConfirm(false), []);

  useClickOutside(deleteEle, onOutsideClick);

  const handleClick = useCallback(() => setDeleteConfirm((state) => !state), []);

  const handleCancel = useCallback(() => setDeleteConfirm(false), []);

  const deleteClick = useCallback(() => deleteFun(id), [id, deleteFun]);

  return (
    <div key={id} className="user-device-details">
      <p className="model-text">{model}</p>
      <p className="name-text">{name}</p>
      <p className="version-text">{version}</p>
      {showDeleteIcon ? (
        <div className="delete-device-wrapper" ref={deleteEle}>
          <div className="delete-device-button">
            <ToolTip position="top" title="Delete">
              <button onClick={handleClick}>
                <Icon name="delete-icon" />
              </button>
            </ToolTip>
          </div>
          {deleteConfirm ? (
            <div className="device-delete-confirmation">
              <ConfirmationPopup
                text="Are you sure to delete the device?"
                acceptLabel="Delete"
                cancelLabel="Cancel"
                onAccept={deleteClick}
                onCancel={handleCancel}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export { Device };
