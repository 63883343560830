// @flow

/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your views
 */

import React from "react";
import { useSelector } from "react-redux";
import { tryAutoLogin } from "services/authentication";
import { Route } from "react-router-dom";
import { Loader } from "components";
import "./PrivateRoute.scss";

type Props = {
  component: Object,
  rest: Object,
};

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const tokenVerified = useSelector((state) => state.auth.isTokenVerified);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!tokenVerified) {
          // try logging in users if token is not verified, it handles both the cases
          // 1) if token exists but is not verified
          // 2) token does not exist
          tryAutoLogin();
          return <div className="intermediate-loader"><Loader /></div>;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
