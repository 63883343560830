export type TEmailConfirmation = {
  email: string,
  sent: boolean,
};
export class EmailConfirmation {
  +email: string;
  +sent: boolean;
  constructor(raw: Object) {
    this.email = raw.email;
    this.sent = raw.sent;
  }
}
