import React, { useReducer, createContext } from 'react';

import { reducer, initialState } from "./reducer";

export const FoldersContext = createContext();

export const FoldersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FoldersContext.Provider value={{
      folders: state,
      foldersDispatch: dispatch
    }}>
      {children}
    </FoldersContext.Provider>
  );
}