import {
  ADD_BUILDS_SUCCESS,
  UPDATE_BUILDS_SUCCESS,
  DELETE_BUILDS_SUCCESS,
  CLEAR_ALL_BUILDS,
} from "action-types";
import BuildDataService from "services/buildService";
import { setAlertMsg } from "actions/alertMsg";
import { getErrorMessage, downloadBuildUsingEle } from "utils/common";

export const addToBuildList = (build) => {
  return {
    type: ADD_BUILDS_SUCCESS,
    payload: build,
  };
};

export const updateBuildList = (build) => {
  return {
    type: UPDATE_BUILDS_SUCCESS,
    payload: build,
  };
};

export const deleteBuildList = (build) => {
  return {
    type: DELETE_BUILDS_SUCCESS,
    payload: build,
  };
};

export const clearBuildList = () => {
  return {
    type: CLEAR_ALL_BUILDS,
  };
};

export const onBuildDownload = link => {
  return dispatch => {
    return BuildDataService.getBuildLink(link)
      .then(res => {
        const fileLink = res.data;
        downloadBuildUsingEle(fileLink);
      })
      .catch(err => {
        const msg = getErrorMessage(err) || "Error in downloading build";
        dispatch(setAlertMsg({
          type: "error",
          msg
        }));
      })
  }
};