import {
  SHOW_SIDEPANEL,
  CLOSING_SIDEPANEL,
  HIDE_SIDEPANEL,
  SET_CURRENT_PANEL,
  CLEAR_CURRENT_PANEL,
} from "action-types";

export const showSidePanel = (type, id) => {
  return {
    type: SHOW_SIDEPANEL,
    payload: {
      type,
      id,
    },
  };
};

export const closeSidePanel = () => {
  return {
    type: CLOSING_SIDEPANEL
  };
};

export const hideSidePanel = () => {
  return {
    type: HIDE_SIDEPANEL,
  };
};

export const setCurrentPanel = (val) => {
  return {
    type: SET_CURRENT_PANEL,
    payload: val
  };
};

export const clearCurrentPanel = () => {
  return {
    type: CLEAR_CURRENT_PANEL
  };
};
