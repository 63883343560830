import http from "http-common";

const createProject = (data) => {
  return http.post("/projects", data);
};

const editProject = (id, data) => {
  return http.put(`/projects/${id}`, data);
};

const updateProjectState = (id, data) => {
  return http.put(`/projects/${id}/state`, data);
};

const updateProjectRelations = (id, data) => {
  return http.put(`/projects/${id}/relations`, data);
};

const basicProjectDetails = (id) => {
  return http.get(`/projects/${id}/basic`);
};

const fullProjectDetails = (id) => {
  return http.get(`/projects/${id}/full`);
};

const projectsList = (filters) => {
  return http.get(`/projects/${filters}`);
};

const getProjectFolders = (id, term = "") => {
  return http.get(`/projects/${id}/folders?_q=${term}`);
};

const getProjectToken = (id) => {
  return http.get(`/projects/${id}/token`);
};

const getProjectSettings = id => {
  return http.get(`/projects/${id}/settings`)
};

const updateProjectSettings = (id, data) => {
  return http.put(`/projects/${id}/settings`, data);
}

export default {
  createProject,
  editProject,
  basicProjectDetails,
  fullProjectDetails,
  projectsList,
  updateProjectState,
  updateProjectRelations,
  getProjectFolders,
  getProjectToken,
  getProjectSettings,
  updateProjectSettings
};
