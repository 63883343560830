// @flow

import React, { memo } from "react";
import fixture from "./fixture.json";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { USERS } from "models/routes";
import { isActivePath } from "utils/common";
import "./UserIndicator.scss";

const { internal, external } = fixture.header;

const UserIndicator = memo((props: {}) => {
  const selectedFilter = useSelector((state) => state.filter);
  const { pathname } = useLocation();

  const getClasses = (text) => {
    return classnames({
      "indicator-wrapper": true,
      light:
        !selectedFilter.length || selectedFilter.find((obj) => obj.val === text)
          ? false
          : true,
    });
  };

  if (isActivePath(pathname, USERS)) {
    return (
      <div className="user-indicator-container">
        <div className={getClasses(internal)}>
          <div className="icon internal"></div>
          <div className="text">{`${internal} Users`}</div>
        </div>
        <div className={getClasses(external)}>
          <div className="icon external"></div>
          <div className="text">{`${external} Users`}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
});

UserIndicator.type.displayName =  "UserIndicator";

export { UserIndicator };
