import React, { useContext, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { ToastContext } from 'mobile/data/toast';
import { Icon } from "components";
import { hideToast } from 'mobile/data/toast/action';
import "./MToast.scss";

// in seconds
const DELAY = 3;

const Toast = ({ type, msg }) => {
  const icon = <Icon name={`alert-${type === "success"? "success": "failure"}`} classname="center" />;
  return (
    <>
      <div className="icon">{icon}</div>
      <div className="msg">{msg}</div>
    </>
  );
};

const MToast = (props: {}) => {
  const { toast: { list }, toastDispatch } = useContext(ToastContext);
  const toastContainer = useRef(null);
  const toastRef = useRef({});

  const toastObj = toastRef.current;
  const container = toastContainer.current;

  useEffect(() => {
    list.forEach(item => {
      if(!(item.id in toastObj)) {
        toastObj[item.id] = item;
        const toastWrapper = document.createElement("div");
        toastWrapper.className = "toast-wrapper";
        container && container.prepend(toastWrapper);
        toastWrapper.classList.add("move-center");
        render(<Toast {...item} />, toastWrapper);

        // add class 'move-right' to animate to the right
        setTimeout(() => {
          toastWrapper.style.transform = "none";
          toastWrapper.classList.add("move-right");
        }, DELAY * 1000);
        
        // additional 500ms is added for the element to move to the right
        setTimeout(() => {
          // remove the item from the toastObj and list
          delete toastObj[item.id];
          toastDispatch(hideToast(item.id));

          // remove the child from the dom
          container && container.removeChild(toastWrapper);
        }, DELAY * 1000 + 500);
      }
    });
  }, [list, toastObj, toastDispatch, container]);

  return (
    <div ref={toastContainer} className="axiom-m-toast-container">
    </div>
  )
};

export { MToast };