// @flow

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearAlertMsg } from "actions/alertMsg";
import Icon from "components/Icon";
import "./AlertMsg.scss";

function AlertMsg(props: {}) {
  const dispatch = useDispatch();
  const { type, msg, btnText, isOpen } = useSelector(
    (state) => state.alert.message
    );
  const timeOutId = useRef(null);
  const icon =
    type === "success" ? (
      <Icon name="alert-success" classname="center" />
    ) : (
      <Icon name="alert-failure" classname="center" />
    );

  useEffect(() => {
    const id = timeOutId.current;
    if(id) {
      clearTimeout(id);
    }
    // remove the alertPanel 5s after showing the message
    timeOutId.current = setTimeout(() => {
      dispatch(clearAlertMsg());
    }, 5000);
  }, [dispatch, msg]);

  if (isOpen) {
    return (
      <div className="axiom-alert-msg-container" data-testid="alert-msg">
        <div className="icon">{icon}</div>
        <div className="msg-text">{msg}</div>
        <button
          className="close-btn"
          type="button"
          onClick={() => dispatch(clearAlertMsg())}
        >
          {btnText}
        </button>
      </div>
    );
  } else {
    return null;
  }
}

export default AlertMsg;
