// @flow

import React, { useCallback, useState, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { Button, ConfirmationPopup, Icon, ListContainer, ToolTip } from "components";
import ProjectService from "services/projectsService";
import { Project as ProjectData } from "models/api/project";
import { updateProjectList } from "actions/projectActions";
import { setAlertMsg } from "actions/alertMsg";
import { updateInfo } from "actions/info";
import { generatePath } from 'react-router-dom';
import { PROJECTS } from "models/routes";
import {
  isAdminOrMasteradmin,
  getErrorMessage,
  capitalize,
  isActive,
  isArchived,
  redirectToRoute,
  isListItemAndNotOpen
} from "utils/common";
import {
  PROJECT_TESTERS,
  PROJECT_GROUPS,
  EDIT_PROJECT,
  ACTIVE,
  ARCHIVED,
  DELETED,
  TXT_ARCHIVE,
  TXT_UNARCHIVE,
  TXT_ADD_TESTERS,
  TXT_ADD_NEW_BUILD,
  UPLOAD_BUILD,
} from "constants.js";
import { useClickOutside } from "hooks/useClickOutside";
import { useProjectStateUpdate } from "hooks/useProjectLogic";
import fixture from "components/Project/fixture.json";

import "./ProjectInfo.scss";

type Props = {
  id: number,
  name: string,
  iconURL: string,
  platform: string,
  state: string,
  managerId: number,
  managerName: string,
  managerEmail: string,
  description: string,
};

const ProjectInfo = memo(({
  id,
  name,
  iconURL,
  platform,
  state,
  managerId,
  managerName,
  managerEmail,
  description,
}: Props) => {
  const userId = useSelector((state) => state.auth.id);
  const userRole = useSelector((state) => state.auth.role);
  const projectInfo = useSelector((state) => state.info.project) || {};
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showCommonPopup, setShowCommonPopup] = useState("");
  const [loading, setLoading] = useState(false);
  const popupParent = useRef();
  const updateProjectState = useProjectStateUpdate();
  const manager = useSelector((state) => state.auth.manager);

  const onOutsideClick = useCallback((e) => {
    setShowPopup(false);
    let ele = e.target;
    if(!isListItemAndNotOpen(ele, showCommonPopup)) {
      setShowCommonPopup("");
    }
  }, [showCommonPopup]);

  useClickOutside(popupParent, onOutsideClick);

  // check whether current user has admin roles or not
  const adminOrMadmin = isAdminOrMasteradmin(userRole);
  const { activeData, deleteData, archiveData } = fixture;

  const handleClose = useCallback(() => {
    redirectToRoute(generatePath(PROJECTS));
  }, []);

  const handleUpdate = useCallback(
    async (state) => {
      setLoading(true);
      try {
        const { data } = await ProjectService.updateProjectState(id, {
          state,
        });
        const updatedProject = new ProjectData(data);
        const { name, state: newState } = updatedProject;
        dispatch(updateProjectList(updatedProject));
        dispatch(updateInfo({project: {...projectInfo, state: newState }}));
        dispatch(
          setAlertMsg({
            type: "success",
            msg: `Project ${name} is now ${newState}`,
          })
        );
        setShowPopup(false);
        setShowCommonPopup("");
      } catch (err) {
        const msg = getErrorMessage(err) || "Error in updating project state";
        dispatch(
          setAlertMsg({
            type: "error",
            msg,
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, id, projectInfo]
  );

  const onSelect = useCallback(
    (listId) => {
      switch (listId) {
        case 1:
          redirectToRoute(generatePath(PROJECTS, { id }), `panel=${UPLOAD_BUILD}`);
          break;
        case 2:
          redirectToRoute(generatePath(PROJECTS, { id }), `panel=${PROJECT_TESTERS}`);
          break;
        case 3:
          redirectToRoute(generatePath(PROJECTS, { id }), `panel=${PROJECT_GROUPS}`);
          break;
        case 4:
          redirectToRoute(generatePath(PROJECTS, { id }), `panel=${EDIT_PROJECT}`);
          break;
        case 5:
          setShowCommonPopup("archive");
          break;
        case 6:
        case 22:
          setShowCommonPopup("delete");
          break;
        case 11:
          setShowCommonPopup("restore");
          break;
        case 21:
          setShowCommonPopup("unarchive");
          break;
        default:
      }
    },
    [id]
  );

  const handlePopup = useCallback(() => {
    handleUpdate(updateProjectState(showCommonPopup));
  }, [handleUpdate, updateProjectState, showCommonPopup]);

  const displayPlatformIcons = useCallback(() => {
    const icons = platform.split(",");
    return icons.map((icn) => (
      <Icon key={icn} name={`${icn}-icon`} dataTestId={icn} />
    ));
  }, [platform]);

  const toggleShowPopup = useCallback(() => setShowPopup((state) => !state), []);

  const moreIconClasses = classnames({
    "more-icon": true,
    active: showPopup,
  });

  const infoWrapperClasses = classnames({
    "info-wrapper": true,
    "pop-out": !isActive(state)
  })

  let dropdownData = [];
  switch (state) {
    // don't show the opt(options) 'Archive' & 'Unarchive' for non-admin roles
    // only show the options 'Add Testers' & 'Add New Build' for Testers
    case ACTIVE:
      dropdownData = activeData.filter(
        ({ name }) =>
          adminOrMadmin ||
          (manager && userId === managerId  &&  name !== TXT_ARCHIVE) ||
          name === TXT_ADD_TESTERS ||
          name === TXT_ADD_NEW_BUILD
      );
      break;
    case ARCHIVED:
      dropdownData = archiveData.filter(
        ({ name }) => adminOrMadmin || name !== TXT_UNARCHIVE
      );
      break;
    case DELETED:
      dropdownData = deleteData;
      break;
    default:
  }

  return (
    <div className="axiom-project-info-container">
      <div className={infoWrapperClasses}>
        <div className="icon-content">
          <div className="icon-wrapper">
            <img src={iconURL} alt="project-icon" />
          </div>
          <div className="content-wrapper">
            <div className="platform-icons">{displayPlatformIcons()}</div>
            <h3 className="name">{name}</h3>
            <h5 className="manager-name">PM - {managerName}</h5>
            <h5 className="manager-email">{managerEmail}</h5>

            {!isActive(state) && (
              <div
                className={classnames({ state: true, archived: isArchived(state) })}
              >
                <span className="circle"></span>
                {state}
              </div>
            )}
          </div>
        </div>

        {(adminOrMadmin || (manager && userId === managerId) || state === ACTIVE) && (
          <div className="more-wrapper">
            <div className="btn-wrapper" ref={popupParent}>
              <ToolTip position="top" title="More Options">
                <Button click={toggleShowPopup} buttonType="popup">
                  <Icon name="more-icon" classname={moreIconClasses} />
                </Button>
              </ToolTip>
              {showPopup && !showCommonPopup && (
                <div className="group-container" data-testid="group-popup">
                  <ListContainer
                    type="dropdown-list"
                    classname="group-popup"
                    data={dropdownData}
                    onSelect={(id) => onSelect(id)}
                  />
                </div>
              )}
              {showCommonPopup && (
                <div className="delete-confirmation">
                  <ConfirmationPopup
                    text={`Are you sure to ${showCommonPopup} this project?`}
                    acceptLabel={`Yes, ${capitalize(showCommonPopup)}`}
                    cancelLabel="Cancel"
                    onAccept={handlePopup}
                    onCancel={() => setShowCommonPopup("")}
                    isLoading={loading}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="close-btn-wrapper">
          <ToolTip position="top" title="Close">
            <Button click={handleClose} buttonType="popup" classes="close-btn">
              <Icon name="closeicon" />
            </Button>
          </ToolTip>
        </div>
      </div>
      <div className="description">{description}</div>
    </div>
  );
});

ProjectInfo.type.displayName = "ProjectInfo";

export { ProjectInfo };
