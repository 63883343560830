// @flow

import React, { Fragment, useCallback, useRef, useEffect, memo } from "react";
import classnames from "classnames";
import type TList from "models/api/list";
import { Button, Icon, Input, ToolTip } from "components";
import { ACTIVE, PROJECTS, USERS } from "constants.js";
import "./ListContainer.scss";

// 2 types of list container: search-list & dropdown-list
// .search-list for displaying search results
// .dropdown-list for displaying dropdown options

type CreateFolderObj = {
  onChange: () => void,
  onFocus: () => void,
  onBlur: () => void,
  onSave: () => void,
  onCancel: () => void,
  onAddClick: () => void,
  inputFocus: boolean,
  inputValue: string,
  showInput: boolean,
};

type Props = {
  /** data for showing the list */
  data: Array<TList>,
  type: "search-list" | "dropdown-list" | "global-search",
  onSelect: (TList) => void,
  classname: "string",
  defaultValue?: Object | boolean,
  /** show additional option to create folder in the list */
  withCreateFolder?: boolean,
  /** inputs for the showing create folder option */
  createFolderObj?: CreateFolderObj,
};

const ListContainer = memo(({
  data,
  type = "search-list",
  onSelect,
  classname,
  defaultValue = false,
  withCreateFolder = false,
  createFolderObj = {},
}: Props) => {

  const input = useRef(null);

  const {
    onChange,
    onFocus,
    onBlur,
    onSave,
    onCancel,
    onAddClick,
    inputFocus,
    inputValue,
    showInput,
  } = createFolderObj;

  useEffect(() => {
    if(showInput) {
      const ele = input.current;
      ele && ele.focus();
    }
  }, [showInput]);

  const selectItem = (e) => {
    const id = Number(e.target.getAttribute("data-id"));
    const url = e.target.getAttribute("data-url");

    if (id) {
      onSelect(id);
    } else if(url) {
      onSelect(url);
    } else {
      return;
    }
  };

  const listContainerClasses = classnames({
    "axiom-list-container": true,
    "with-create-folder": withCreateFolder,
    "no-border": withCreateFolder && !data.length ? true : false,
    [classname]: classname ? true : false,
    [type]: true,
  });

  const liClasses = useCallback((id) =>
    classnames({
      "list-item": true,
      "folder-box": withCreateFolder,
      active: defaultValue ? (defaultValue.id === id ? true : false) : false,
    }), [defaultValue, withCreateFolder]);

  const inputFolderStyles = classnames({
    "create-folder-input-wrapper folder-box": true,
    "is-focussed": inputFocus,
  });

  const getAnyAdditionalUserInfo = useCallback((key, blocked, confirmed) => {
    if(key !== USERS) return null;
    let text = "";
    
    if(!confirmed) {
      text = "Not Confirmed"; 
    }

    if(blocked) {
      text = "Deleted"
    }

    if(!text) return null;

    return <span className={classnames("additional-info", {
      "blocked": blocked,
      "confirmed": confirmed
    })}>{text}</span>;
  }, []);

  const displayGlobalSearchList = useCallback(() => {
    let isEmpty = true;

    const content = data.map((item) => {
      const { key, arr = [] } = item;

      if(!arr.length) return null;

      isEmpty = false;
      
      return (
        <Fragment key={key}>
          <li className="title">{key}</li>
          {arr.map(({id, name, url, state, blocked, confirmed}) => {
            return (
              <li className={liClasses(id)} data-url={url} key={id || name}>
                {name}
                {key === PROJECTS && state !== ACTIVE && <span className={classnames("additional-info", state)}>{state}</span>}
                {getAnyAdditionalUserInfo(key, blocked, confirmed)}
              </li>
            )
          })}
        </Fragment>
      )
    });

    return isEmpty ? <li className="list-item">No results found</li> : content;
  }, [data, getAnyAdditionalUserInfo, liClasses])

  return (
    <div className={listContainerClasses} data-testid="list-container">
      <ul
        className="list-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          selectItem(e);
        }}
      >
        {type === "global-search" && displayGlobalSearchList()}
        {withCreateFolder && (
          <Fragment>
            <div className="create-folder folder-box">
              <span className="name">Create Folder</span>
              <ToolTip position="top" title="Add" displace={true}>
                <Button click={onAddClick} buttonType="popup" classes="plus-btn">
                  <Icon name="plus-button" classname="" />
                </Button>
              </ToolTip>
            </div>
            {showInput && (
              <div className={inputFolderStyles} data-testid="input-wrapper">
                <Input
                  type="text"
                  id="create-folder-input"
                  className="folder-input"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={inputValue}
                  onChange={onChange}
                  reference={input}
                />
                <Button
                  type="popup"
                  text="Cancel"
                  classes="cancel-btn"
                  click={onCancel}
                />
                <Button
                  type="popup"
                  text="Save"
                  classes="save-btn"
                  click={onSave}
                />
              </div>
            )}
          </Fragment>
        )}
        {type !== "global-search" && (
          data.length ? data.map((list) => {
            // if the 'selected key is present, then the list item will have green check mark icon on the right end
            const { id, name, selected } = list;
            return (
              <li className={liClasses(id)} data-id={id} key={id || name}>
                {defaultValue && (
                  <div className="icon-wrapper">
                    <span className="inner-box"></span>
                  </div>
                )}
                {name}
                {selected && <Icon classname="selected-item" name="user-checked-icon" />}
              </li>
            );
          }) : !withCreateFolder && <li className="empty-item">No options available</li> )}
      </ul>
    </div>
  );
});

ListContainer.type.displayName = "ListContainer";

export { ListContainer };
