// @flow

import React, { memo, useMemo } from "react";
import { Icon } from "components";
import classnames from "classnames";
import "./Checkbox.scss";

type Props = {
  classname: string,
  /** label for the checkbox */
  labelText: string,
  name: string,
  isChecked: boolean,
  value: string,
  valueChange: () => void,
  /** a box typed checkbox */
  boxTyped?: boolean,
  disable?: boolean
};

const Checkbox = memo(({
  classname,
  labelText,
  name,
  isChecked,
  value,
  valueChange,
  boxTyped = false,
  disable = false
}: Props) => {
  const containerClasses = classnames({
    "axiom-checkbox-container": true,
    [classname]: true,
  });

  const handleClick = (e) => {
    e.stopPropagation();
    !disable && valueChange && valueChange();
  };

  const disabledClass = useMemo(() => disable? "disabled": "", [disable]);

  const innerContent = boxTyped ? (
    <div className="icon-wrapper">
      <span className="inner-box"></span>
    </div>
  ) : (
    <div className={classnames("checked", disabledClass)} onClick={handleClick}>
      <Icon name="user-checked-icon" />
    </div>
  );

  return (
    <div className={containerClasses}>
      <div className="wrapper">
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
          onChange={handleClick}
          id={`cb-${labelText}-${name}`}
          disabled={disable}
        />
        {isChecked ? (
          innerContent
        ) : (
          <div className={classnames("unchecked", disabledClass)} onClick={handleClick}></div>
        )}
      </div>
      {labelText ? (
        <label
          htmlFor={`cb-${labelText}-${name}`}
          className={classnames({ active: isChecked }, disabledClass)}
        >
          {labelText}
        </label>
      ) : null}
    </div>
  );
});

Checkbox.type.displayName = "Checkbox";

export { Checkbox };
