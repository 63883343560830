import { ACTIVE, ARCHIVED, DELETED } from "constants.js";

export const useProjectStateUpdate = () => {
  return (value) => {
    let type = "";
    switch (value) {
      case "delete":
        type = DELETED;
        break;
      case "archive":
        type = ARCHIVED;
        break;
      case "unarchive":
      case "restore":
        type = ACTIVE;
        break;
      default:
    }

    return type;
  };
};
