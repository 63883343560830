// @flow

import React, { useRef, useState, useCallback, memo } from "react";
import { Icon, Checkbox, ToolTip } from "components";
import { useClickOutside } from "hooks/useClickOutside";

import "./Filter.scss";

type Props = {
  data: Array<Object>,
  filterData: Array<Object>,
  onClick: Function,
  boxTyped?: boolean,
  /** used to show clear button (to clear applied filters) */
  showClear: boolean,
  /** function called on clicking the clear button */
  clearPlatformsState: () => void,
};

const FilterComponent = memo(({
  data,
  filterData,
  onClick,
  boxTyped = false,
  clearPlatformsState,
  showClear = false
}: Props) => {
  const dropdownContainer = useRef<HTMLDivElement | null>(null);
  const [dropdown, setDropdown] = useState(false);

  const onOutsideClick = useCallback(() => setDropdown(false), []);

  useClickOutside(dropdownContainer, onOutsideClick);

  return (
    <div
      className="filter-dropdown"
      ref={dropdownContainer}
      data-testid="filter"
    >
      <ToolTip position="top" title="Filter">
        <div
          className="filter-icon"
          onClick={() => {
            setDropdown((state) => !state);
          }}
        >
          <Icon name="filter-icon" />
        </div>
      </ToolTip>
      {dropdown ? (
        <div className="dropdown">
          {data.map((type, index) => {
            let condition = false;
            filterData.forEach(ar => {
              condition =  condition || ar.reduce((iniVal, val) => iniVal || val, false)
            });

            return (
              <div className="dropdown-content" key={index}>
                <p className="dropdown-title">
                  {type.text}
                  {
                    showClear  &&
                    !index &&
                    condition && (
                      <span className="clear-text" onClick={clearPlatformsState}>
                        Clear
                      </span>
                    )
                  }
                </p>
                {type.data.map((dataVal, i) => (
                  <div className="checkbox-text" key={i}>
                    <Checkbox
                      classname="filter-cb"
                      name={dataVal}
                      isChecked={filterData?.[index]?.[i]}
                      value={dataVal}
                      valueChange={() => onClick(index, i, dataVal)}
                      labelText={dataVal}
                      boxTyped={boxTyped && type.key !== "platform"}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
});

FilterComponent.type.displayName = "FilterComponent";

export { FilterComponent };
