import http from "http-common";
import { getToken, BACKEND_URL } from "utils/common";

const deleteOrRestoreBuild = (id) => {
  return http.put(`/builds/${id}/state`);
};

const updateBuild = (id, data) => {
  return http.put(`/builds/${id}/relations`, data);
};

const updateInfo = (id, data) => {
  return http.put(`/builds/${id}/info`, data);
}

const updateFreeze = id => {
  return http.put(`/builds/${id}/freeze`);
};

const buildDetails = (id) => {
  return http.get(`/builds/${id}`);
};

// permanently delete the build
const permanentDelete = id => {
  return http.delete(`/builds/${id}`);
};

// keepalive option keeps the request alive even when the page unloads 
const permanentDeleteUsingFetch = id => {
  return fetch(`${BACKEND_URL}/builds/${id}`, {
    headers: {
      'Accept': "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
    method: "DELETE",
    keepalive: true
  });
};

// keep track of when the build was installed
const updateTracking = id => {
  return http.put(`/trackings/${id}`);
};

const getBuildLink = link => {
  return http.get(link);
};

export default { deleteOrRestoreBuild, updateBuild, updateInfo, updateFreeze, buildDetails, permanentDelete, permanentDeleteUsingFetch, updateTracking, getBuildLink };
