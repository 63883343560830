//@flow
import { useEffect } from "react";
import type { Node } from "react";

export const useClickOutside = (node: Object, onOutsideClick: () => void) => {
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      let ele = node ? node.current : null;
      // if the clicked element is popup button then don't dismiss the popup
      if ((ele && ele instanceof Node && ele.contains(e.target)) || e.target.classList.contains("popup-accept-button")) {
        return;
      }
      onOutsideClick(e);
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [node, onOutsideClick]);
};
