import { dateTime } from "utils/common";
import type { TList } from "./list";

export type TMBuildDetails = {
  id: number,
  link: string,
  name: string,
  date: string,
  size: number,
  bundleID: string,
  uploadedBy: TList,
  support: number,
  type: string,
  jobId: string,
  commitMessage: string,
  releaseNotes: { text: string },
  knownIssues: { text: string },
  moreInfo: { text: string }
};

export class MBuildDetails {
  +id: number;
  +link: string;
  +name: string;
  +date: string;
  +size: number;
  +bundleID: string;
  +uploadedBy: TList;
  +support: number;
  +type: string;
  +jobId: string;
  +commitMessage: string;
  +releaseNotes: Object;
  +knownIssues: Object;
  +moreInfo: Object;

  constructor(raw: Object) {
    this.id = raw.id;
    this.link = raw.link;
    this.name = raw.name;
    this.date = dateTime(raw.date).split(",")[0];
    this.size = raw.size;
    this.bundleID = raw.bundleID;
    this.uploadedBy = raw.uploadedBy;
    this.support = raw.support;
    this.type = raw.type;
    this.jobId = raw.jobId;
    this.commitMessage = raw.commitMessage;
    this.releaseNotes = raw.releaseNotes;
    this.knownIssues = raw.knownIssues;
    this.moreInfo = raw.moreInfo;
  }
}
