// @flow

import React, { useState, useRef, useEffect, useCallback } from "react";
import { generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { useParamPanelValue } from "hooks/useParamPanelValue";
import { redirectToRoute } from "utils/common";
import { PROJECTS } from "models/routes";
import { PROJECT_BUILDS, PROJECT_TESTERS, PROJECT_GROUPS, PROJECT_CI_CD, PROJECT_SETTINGS } from "constants.js";
import "./Overlay.scss";

const Overlay = (props: {}) => {
  const isShown = useSelector((state) => state.overlay);
  const [show, setShow] = useState(isShown);
  const timeOutRef = useRef(null);
  const overlay = useRef(null);
  const { panel } = useParamPanelValue();

  useEffect(() => {
    if (isShown) {
      setShow(true);
    } else {
      let ele = overlay.current;
      if(show) {
        ele && ele.classList.remove("open");
        // 0.5s is the delay added for the transition to complete
        timeOutRef.current = setTimeout(() => setShow(false), 500);
      }
    }
  }, [isShown, show]);

  useEffect(() => {
    if (show) {
      let ele = overlay.current;
      ele && ele.classList.add("open");
    }
  }, [show]);

  const click = useCallback(() => {
    switch(panel) {
      case PROJECT_BUILDS:
      case PROJECT_TESTERS:
      case PROJECT_GROUPS:
      case PROJECT_CI_CD:
      case PROJECT_SETTINGS:
        redirectToRoute(generatePath(PROJECTS));
        break;
      default:
    }
  }, [panel]);

  // unmount cleanup
  useEffect(() => () => clearTimeout(timeOutRef.current), []);

  const containerClasses = classnames({
    "axiom-overlay-container": true,
  });

  if (show) {
    return (
      <div
        ref={overlay}
        onClick={click}
        className={containerClasses}
        data-testid="overlay"
      ></div>
    );
  } else {
    return null;
  }
};

export { Overlay };
