import axios from "axios";
import { getToken, BACKEND_URL } from "utils/common";

const instance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-type": "application/json",
  },
});

// before sending each request add the stored token in the authorization header (if exists)
instance.interceptors.request.use((request) => {
  const authToken = getToken();
  if (authToken) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }
  return request;
});

export default instance;
