//@flow

import React, { useState, useCallback, useEffect, useContext } from "react";
import { ToastContext } from 'mobile/data/toast';
import { showToast } from 'mobile/data/toast/action';
import { MobileHeader } from "mobile/components";
import { Button, Input } from "components";
import fixture from "./fixture.json";
import { isEmailValidated, getErrorMessage, isInternalUser } from "utils/common";
import emailSentImg from "mobile/assets/images/email-sent.png";
import UserDataService from "services/usersService";
import "./ForgotPassword.scss";

const {
  forgotPassword,
  forgotPasswordText,
  emailSent,
  emailSentText,
  errorResettingPassword,
  internalUserError
} = fixture;

const ForgotPassword = () => {
  const { toastDispatch } = useContext(ToastContext);
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const isFirstPage = page === 1;

  useEffect(() => {
    if(!isEmailValidated(email)) {
      setDisabled(true);
      setError("");
    } else if(isInternalUser(email)) {
      setDisabled(true);
      setError(internalUserError);
    } else {
      setDisabled(false);
      setError("");
    }
  }, [email]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data } = await UserDataService.forgotPassword({ email });
      if(data.ok) {
        if(isFirstPage) {
          setPage(page => (page + 1));
        } else {
          toastDispatch(showToast({ type: "success", msg: "Email sent successfully" }));
        }
      } else {
        throw Error(errorResettingPassword);
      }
    } catch (err) {
      const msg = getErrorMessage(err) || err.message;
      setError(msg);
    } finally {
      setLoading(false);
    }
  }, [toastDispatch, email, isFirstPage]);
  

  return (
    <>
      <MobileHeader
        showLogo={false}
        withSearch={false}
        centeredText=""
        lastIcon="no-icon"
      />
      <section className="m-forgot-password-container">
        <form onSubmit={e => e.preventDefault()}>
          <h2 className="heading">{isFirstPage ? forgotPassword: emailSent}</h2>
          <div className="text">{isFirstPage ? forgotPasswordText: emailSentText}</div>
          {
            isFirstPage ?
            <>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                className=""
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="internal-user-err">{error}</span>
              <div className="btn-wrapper">
                <Button
                  classes="save-button send-btn"
                  click={onSubmit}
                  disabled={disabled || loading}
                >
                  Send
                </Button>
              </div>
            </>
            :
            <>
              <img alt="" src={emailSentImg} className="img-email-sent" />
              <div className="resend-wrapper">
                <span className="resend-text">Didn't receive the link? </span>
                <Button classes="resend-btn" disabled={loading} click={onSubmit}> Resend</Button>
              </div>
            </>
          }
        </form>
      </section>
    </>
  );
};

export { ForgotPassword };