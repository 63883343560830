import React, { useCallback, useState, useContext } from 'react';
import { MInputPassword, MobileHeader } from 'mobile/components';
import { Button } from 'components';
import { ToastContext } from 'mobile/data/toast';
import { showToast } from 'mobile/data/toast/action';
import UserDataService from "services/usersService";
import { getErrorMessage, isOffline, goBack, passwordValidator } from "utils/common";
import fixture from "./fixture.json";
import "./MChangePassword.scss";

const { emptyPassword } = fixture;

const MChangePassword = () => {
  const { toastDispatch } = useContext(ToastContext);
  const [curPwd, setCurPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmedPwd, setConfirmedPwd] = useState("");
  const [loading, setLoading] = useState(false);

  const [showCurPwd, setShowCurPwd] = useState(true);
  const [showNewPwd, setShowNewPwd] = useState(true);
  const [showConfirmedPwd, setShowConfirmedPwd] = useState(true);

  const [error, setError] = useState({
    eCurPwd: "",
    eNewPwd: "",
    eConfirmedPwd: ""
  });

  const toggleCurPwd = () => setShowCurPwd(st => !st);
  const toggleNewPwd = () => setShowNewPwd(st => !st);
  const toggleConfirmedPwd = () => setShowConfirmedPwd(st => !st);

  const {
    eCurPwd,
    eNewPwd,
    eConfirmedPwd
  }  = error;

  const changePassword = useCallback(async () => {
    switch(true) {
      case !curPwd:
        return setError({
          eCurPwd: emptyPassword,
          eNewPwd: "",
          eConfirmedPwd: ""
        });
      case !newPwd:
        return setError({
          eCurPwd: "",
          eNewPwd: emptyPassword,
          eConfirmedPwd: ""
        });
      case !confirmedPwd:
        return setError({
          eCurPwd: "",
          eNewPwd: "",
          eConfirmedPwd: emptyPassword
        });
      case newPwd !== confirmedPwd:
        return setError({
          eCurPwd: "",
          eNewPwd: "Passwords don't match",
          eConfirmedPwd: ""
        });
      default:
    }

    try {
      passwordValidator(newPwd);
    } catch (err) {
      return setError({
        eCurPwd: "",
        eNewPwd: err.message,
        eConfirmedPwd: ""
      });
    }

    try {
      setLoading(true);
      const { data } = await UserDataService.changePassword({
        currentPassword: curPwd,
        newPassword: newPwd,
        confirmNewPassword: confirmedPwd
      });

      if(data.msg) {
        toastDispatch(showToast({
          type: "success",
          msg: data.msg
        }));

        goBack();
      }
      
    } catch (err) {
      if(!isOffline()) {
        const msg = getErrorMessage(err) || "Error in changing your password";
        setError({
          eCurPwd: msg,
          eNewPwd: "",
          eConfirmedPwd: ""
        });
      }
    } finally {
      setLoading(false);
    }
  }, [curPwd, newPwd, confirmedPwd, toastDispatch]);

  return (
    <>
      <MobileHeader
        showLogo={false}
        withSearch={false}
        centeredText=""
        lastIcon="no-icon"
      />
      <section className="m-change-password-wrapper">
        <h2 className="change-password-text">Change Password</h2>
        <MInputPassword
          name="current-password"
          value={curPwd}
          placeholder="Current Password"
          disabled={false}
          className="password current"
          onChange={e => setCurPwd(e.target.value)}
          showIcon={curPwd}
          showPassword={showCurPwd}
          toggleShowPassword={toggleCurPwd}
          required={true}
          error={eCurPwd}
        />
        <MInputPassword
          name="new-password"
          value={newPwd}
          placeholder="New Password"
          disabled={false}
          className="password new"
          onChange={e => setNewPwd(e.target.value)}
          showIcon={newPwd}
          showPassword={showNewPwd}
          toggleShowPassword={toggleNewPwd}
          reference={null}
          error={eNewPwd}
        />
        <MInputPassword
          name="confirm-password"
          value={confirmedPwd}
          placeholder="Confirm New Password"
          disabled={false}
          className="password confirm-new"
          onChange={e => setConfirmedPwd(e.target.value)}
          showIcon={confirmedPwd}
          showPassword={showConfirmedPwd}
          toggleShowPassword={toggleConfirmedPwd}
          required={true}
          error={eConfirmedPwd}
        />
        <Button
          classes="save-button"
          click={changePassword}
          disabled={!curPwd || !newPwd || !confirmedPwd || loading}
          text="Save"
        />
      </section>
    </>
  );
};

export { MChangePassword };