import {
  ADD_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_SUCCESS,
  MARK_ALL_AS_READ
} from "action-types";

const initialState = [];

const notifications = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NOTIFICATION_SUCCESS:
      return [...payload];
    case UPDATE_NOTIFICATION_SUCCESS:
      return state.map((notification) =>
        notification.id === payload.id ? payload : notification
      );
    case MARK_ALL_AS_READ:
      return state.map((notification) => ({...notification, viewed: true}));
    default:
      return state;
  }
};

export default notifications;
