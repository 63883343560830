import {
  SHOW_TOAST,
  HIDE_TOAST
} from "action-types";

export const initialState = {
  list: []
};

export function reducer (state, action) {
  const { payload, type } = action;
  switch(type) {
    case SHOW_TOAST:
      return {...state, list: [...state.list, payload]};
    case HIDE_TOAST:
      return {...state, list: state.list.filter(t => t.id !== payload)};
    default:
      return state;
  }
}