import {
  UPDATE_INFO,
  CLEAR_INFO
} from "action-types";

export const updateInfo = (data) => {
  return {
    type: UPDATE_INFO,
    payload: data
  };
};

export const clearInfo = () => {
  return {
    type: CLEAR_INFO,
  }
}