import React, { useCallback, useContext } from 'react';
import { generatePath } from "react-router-dom";
import classnames from "classnames";
import { InfoContext } from "mobile/data/info";
import { Icon } from "components";
import { redirectToRoute } from "utils/common";
import { updateInfo } from "actions/info";
import { PROJECTS } from "models/routes";
import "./MProjectCard.scss";

type Props = {
  id: number,
  type: "grid" | "list",
  name: string,
  iconURL: String,
  platform: Array<string>
}

const MProjectCard = ({
  id,
  type = "grid",
  name = "",
  iconURL = "",
  platform = []
}: Props) => {

  const { infoDispatch } = useContext(InfoContext);
  
  const handleClick = useCallback(() => {
    infoDispatch(updateInfo({project: { id, name }}));
    redirectToRoute(generatePath(PROJECTS, { id }));
  }, [id, name, infoDispatch]);

  const classes = classnames("m-project-card-wrapper", [type]);

  return (
    <div className={classes} onClick={handleClick} data-testid="m-project-card">
      <div className="m-logo-wrapper">
        <img src={iconURL} alt="" />
      </div>
      <div className="m-name-icon-wrapper">
        <p className="name">{name}</p>
      </div>
      {
        type === "list" && (
          <div className="right-arrow">
            <Icon name="arrow-right" />
          </div>
        )
      }
    </div>
  )
};

export { MProjectCard };