import React, { useCallback, useContext } from 'react';
import { generatePath } from 'react-router-dom';
import classnames from "classnames";
import axiomlogo from "assets/images/axiom-logo2.png";
import { Icon } from 'components';
import { BackButton } from "mobile/components";
import { openSearch } from "mobile/data/search/action";
import { SearchContext } from "mobile/data/search";
import { goBack, redirectToRoute } from "utils/common";
import { M_NOTIFICATIONS, PROJECTS } from 'models/routes';
import "./MobileHeader.scss";

type Props = {
  showLogo: boolean,
  withSearch: boolean,
  centeredText: string,
  lastIcon: "m-search" | "m-previous-builds" | "no-icon" | "clear" | "m-notifications",
  iconFun: () => void
};

const MobileHeader = ({
  showLogo = true,
  withSearch = true,
  centeredText = "",
  lastIcon = "",
  iconFun = null
}: Props) => {
  // adding object as fallback since the reset password component can also be used in desktop where the context is not used (to prevent error)
  const { searchDispatch } = useContext(SearchContext) || {};

  const search = useCallback(() => {
    searchDispatch(openSearch());
  }, [searchDispatch]);

  const showNotifications = useCallback(() => {
    redirectToRoute(M_NOTIFICATIONS);
  }, []);

  const toProjects = useCallback(() => redirectToRoute(generatePath(PROJECTS)), []);

  let lastItemFunction = null;
  let noLastIcon = false;
  let isTextClear = false;

  switch(lastIcon) {
    case "m-search":
      lastItemFunction = search;
      break;
    case "m-previous-builds":
      lastItemFunction = iconFun;
      break;
    case "no-icon":
      noLastIcon = true;
      lastItemFunction = null;
      break;
    case "clear":
      lastItemFunction = iconFun;
      isTextClear = true;
      break;
    case "m-notifications":
      lastItemFunction = showNotifications;
      break;
    default:
  }

  const lastNavItemClasses = classnames("last-nav-item", {
    "clear": isTextClear
  });

  const headerClasses = classnames(
    "m-header",
    // to adjust the back button with axiom-logo
    { "top-margin": !showLogo }
  );

  return (
    <header className="m-header-wrapper" data-testid="mobile-header">
      <nav className={headerClasses}>
        <ul>
          {
            showLogo ? (
              <li className="m-axiom-logo" onClick={toProjects}>
                <img src={axiomlogo} alt="axiom-logo" />
              </li>
            ) : (
              <li className="m-back-btn">
                <BackButton click={goBack} />
              </li>
            )
          }
          {
            showLogo && withSearch && (
              <li className="m-search-icn" onClick={search}>
                <Icon classname="nav-item" name="m-search" />
              </li>
            )
          }
          {
            !showLogo && centeredText && <li className="m-centered-text">{centeredText}</li>
          }
          {
            <li className={lastNavItemClasses} onClick={lastItemFunction}>
              {!noLastIcon && (
                isTextClear ? "clear" : <Icon classname="nav-item" name={lastIcon} />
              )}
            </li>
          }
        </ul>
      </nav>
    </header>
  )
};

export { MobileHeader };