// @flow

import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { Icon, ProfilePicture, ConfirmationPopup, Button, ToolTip } from "components";
import axiomlogo from "assets/images/axiom-logo2.png";
import fixture from "./fixture.json";
import { logOut } from "services/authentication";
import history from "services/history";
import { setAlertMsg } from "actions/alertMsg";
import { searchParam, USERS, GROUPS } from "models/routes";
import { getErrorMessage, redirectToRoute, capitalize, isAdminOrMasteradmin } from "utils/common";
import { generatePath } from 'react-router-dom';
import NotificationService from "services/notification";
import { addToNotificationList } from "actions/notificationActions";
import { Notification as NotificationClass} from "models/api/notification";
import {
  MY_PROFILE_VIEW,
  NOTIFICATION_VIEW,
  FEEDBACK_VIEW,
  USER_LOGOUT,
  PROJECT_BUILDS,
  PROJECT_TESTERS,
  PROJECT_GROUPS,
  PROJECT_CI_CD,
  PROJECT_SETTINGS,
  TXT_PROFILE,
  TXT_LOGOUT,
  TXT_NOTIFICATION,
  TXT_FEEDBACK
} from "constants.js";

import "./Sidebar.scss";

type Props = {
  isLogin: boolean
};

const Sidebar = ({ isLogin = false }: Props) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [popup, setPopup] = useState(false);
  const user = useSelector((state) => state.auth);
  const pm = useSelector(state => state.info.project?.managerId);
  const popupParent = useRef();
  const profileIcon = useRef();
  const notificationIcon = useRef();
  const feedbackIcon = useRef();
  const { projectsNavLink } = fixture;
  const { PROFILE, LOGOUT, NOTIFICATION, FEEDBACK }  = searchParam;
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);

  const type = query.get("panel");

  const { firstName, lastName, username, role, id } = user;
  const usrName = firstName && lastName ? `${firstName} ${lastName}` : username;

  const adminRoles = useMemo(() => isAdminOrMasteradmin(role), [role]);
  const isPM = useMemo(() => pm === id, [pm, id]);

  // show the project settings if the user has adminRoles or is the project manager for that project
  const showProjectSettings = adminRoles || isPM;

  const handleOutsideClick = useCallback(
    (e) => {
      const popupEle = popupParent.current;
      const profileEle = profileIcon.current;
      if (popupEle && popupEle.contains(e.target)) {
        return;
      }
      if (profileEle && profileEle.contains(e.target)) {
        popupEle && popupEle.classList.remove("active");
        return;
      }
      setPopup(false);
      popupEle && popupEle.classList.remove("active");
    },
    [popupParent, profileIcon]
  );

  useEffect(() => {
    // pEle, nEle - profile & notification element
    const pEle = profileIcon.current;
    const nEle = notificationIcon.current;
    const fEle = feedbackIcon.current;

    pEle && pEle.classList.remove("active");
    nEle && nEle.classList.remove("active");
    fEle && fEle.classList.remove("active");

    if(!popup) {
      switch(type) {
        case MY_PROFILE_VIEW:
          pEle && pEle.classList.add("active");
          break;
        case NOTIFICATION_VIEW:
          nEle && nEle.classList.add("active");
          break;
        case FEEDBACK_VIEW:
          fEle && fEle.classList.add("active");
          break;
        default:
      }
    }
  }, [type, popup]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const getNotifications = useCallback(async () => {
    try {
      const { data } = await NotificationService.getNotifications();
      const notificationData = data.map(nt => new NotificationClass(nt));
      dispatch(addToNotificationList(notificationData));
    } catch(err) {
      const msg = getErrorMessage(err) || "Error in getting notification list";
      dispatch(
        setAlertMsg({
          type: "error",
          msg,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    // get list of notifications when the sidebar is not rendered during login (as Main comes into view)
    if(!isLogin) {
      getNotifications();
    }
  }, [getNotifications, isLogin]);

  const userIconClick = useCallback((path) => {
    switch(path) {
      case PROFILE:
        redirectToRoute(generatePath(window.location.pathname), `panel=${MY_PROFILE_VIEW}`);
        break;
      case NOTIFICATION:
        redirectToRoute(generatePath(window.location.pathname), `panel=${NOTIFICATION_VIEW}`);
        break;
      case FEEDBACK:
        redirectToRoute(generatePath(window.location.pathname), `panel=${FEEDBACK_VIEW}`);
        break;
      default:
      }
  }, [NOTIFICATION, PROFILE, FEEDBACK]);

  const onNavItemClick = useCallback(
    (path) => {
      switch (path) {
        case LOGOUT:
          setPopup(true);
          popupParent.current.classList.add("active");
          break;
        case PROFILE:
        case NOTIFICATION:
        case FEEDBACK:
          userIconClick(path);
          break;
        default:
          redirectToRoute(path);
      }
    },
    [userIconClick, LOGOUT, PROFILE, NOTIFICATION, FEEDBACK]
  );

  const getClassnames = (name, className, path) => {

    let isActive = false;

    switch(name) {
      case TXT_PROFILE:
        isActive = query.get("panel") === MY_PROFILE_VIEW;
        break;
      case TXT_LOGOUT:
        isActive = query.get("panel") === USER_LOGOUT;
        break;
      case TXT_NOTIFICATION:
        isActive = query.get("panel") === NOTIFICATION_VIEW;
        break;
      case TXT_FEEDBACK:
        isActive = query.get("panel") === FEEDBACK_VIEW;
        break;
      default:
        isActive = pathname.search(path) > -1;
    }

    isActive = (isActive || (pathname.search(generatePath(GROUPS)) > -1 && path === generatePath(USERS)) ) && !popup && type !== MY_PROFILE_VIEW && type !== NOTIFICATION_VIEW;

    return classnames("axiom-nav-link", `${className}`, {
      // add the active class to Users icon in the sidebar when both paths /users and /groups is active
      active: isActive,
    });

  }

  const showPage = (ofType) => {
    history.replace(`${window.location.pathname}?panel=${ofType}`);
  };

  const sidebarClasses = classnames({
    "axiom-sidebar": true,
    liftUp:
      type === MY_PROFILE_VIEW ||
      type === NOTIFICATION_VIEW ||
      type === FEEDBACK_VIEW ||
      type === PROJECT_BUILDS ||
      type === PROJECT_TESTERS ||
      type === PROJECT_GROUPS ||
      type === PROJECT_CI_CD ||
      type === PROJECT_SETTINGS,
  });

  const ulClasses = classnames({
    flex:
      type === PROJECT_BUILDS ||
      type === PROJECT_TESTERS ||
      type === PROJECT_GROUPS ||
      type === PROJECT_CI_CD ||
      type === PROJECT_SETTINGS,
  });

  let content = null;

  switch (type) {
    case PROJECT_BUILDS:
    case PROJECT_TESTERS:
    case PROJECT_GROUPS:
    case PROJECT_CI_CD:
    case PROJECT_SETTINGS:
      content = projectsNavLink.map(({ className, name, iconName, ofType }) => {
        // for ci_cd change the name to CI CD
        const updatedName = name === "ci_cd" ? "CI CD": name;

        if(name === "settings" && !showProjectSettings) {
          return null;
        }

        return (
          <li
            key={iconName}
            className={classnames({ active: ofType === type })}
          >
            <ToolTip position="top" title={capitalize(updatedName)}>
              <Button
                click={() => showPage(ofType)}
                buttonType="popup"
                classes={className}
              >
                <Icon name={iconName} classname="flex-container" />
              </Button>
            </ToolTip>
          </li>
        );
      });

      break;

    default:
      content = fixture.navigationLinks.map(({ className, name, path }, i) => {
        let ref;
        let icnName = name, isNotificationTickWithView;

        if((name === "notifications" && notifications.reduce((iniVal, nt) => iniVal || !nt.viewed, false))) {
          icnName = `${name}-tick`;
        } else {
          icnName = name;
        }
        
        switch (path) {
          case LOGOUT:
            ref = popupParent;
            break;
          case PROFILE:
            ref = profileIcon;
            break;
          case NOTIFICATION:
            ref = notificationIcon;
            isNotificationTickWithView = true;
            break;
          case FEEDBACK:
            ref = feedbackIcon;
            break;
          default:
            ref = null;
        }

        return (
          <li key={i}>
            <div
              role="button"
              tabIndex={0}
              className={getClassnames(name, className, path)}
              onClick={() => onNavItemClick(path)}
              ref={ref}
            >
              <ToolTip position="top" title={capitalize(name)}>
                {path === PROFILE ? (
                  <div className="sidebar-profile-menu">
                    <ProfilePicture
                      username={usrName}
                      type="profile-image"
                      classname="small-image"
                    />
                  </div>
                ) : (
                  <Icon name={icnName} classname={classnames("axiom-icon", { "tick": isNotificationTickWithView})} />
                )}
              </ToolTip>
            </div>
          </li>
        );
      });
  }

  return (
    <div className={sidebarClasses}>
      <div className="axiom-logo">
        <img src={axiomlogo} alt="axiom-logo" />
      </div>
      <ul className={ulClasses}>{content}</ul>
      {popup ? (
        <div className="logout-confirmation">
          <ConfirmationPopup
            text={fixture.popup.text}
            acceptLabel={fixture.popup.accept}
            cancelLabel={fixture.popup.cancel}
            onAccept={logOut}
            onCancel={() => {
              setPopup(false);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export { Sidebar };
