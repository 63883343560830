// @flow

import React, { useRef, useEffect, memo } from "react";
import { Input, ToolTip } from "components";

import "./InlineEdit.scss";

type Props = {
  /** display text */
  text: string,
  /** condition for showing the text or the input box */
  showText: boolean,
  handleClick: Function,
  inputId: string,
  inputType?: string,
  inputVal: string,
  onInputChange: Function,
};

const InlineEdit = memo(({
  text,
  showText,
  handleClick,
  // options for Input component
  inputType,
  inputVal,
  inputId,
  onInputChange,
}: Props) => {
  const input = useRef(null);

  useEffect(() => {
    let ele = input.current;
    ele && ele.focus();
  }, [showText]);

  return (
    <div className="axiom-inline-edit-container">
      <div className="edit-wrapper">
        {showText ? (
          <ToolTip title="Edit Name" position="top">
            <div
              className="text-wrapper"
              onClick={handleClick}
              data-testid="test-wrapper"
            >
              {text}
            </div>
          </ToolTip>
        ) : (
          <div className="input-wrapper" data-testid="input-wrapper">
            <Input
              type={inputType}
              id={inputId}
              required={true}
              value={inputVal}
              onChange={onInputChange}
              reference={input}
            />
          </div>
        )}
      </div>
    </div>
  );
});

InlineEdit.type.displayName = "InlineEdit";

export { InlineEdit };
