// @flow

import React, { memo } from "react";
import { Project } from "components";
import { useObserver } from "hooks/useObserver";

import "./ProjectList.scss";

const ProjectList = memo((props: {}) => {
  const { data, isEmpty, observer } = useObserver({ type: "projects" });

  return (
    <div className="axiom-projectlist-container">
      <div className="project-list-wrapper">
        {data.map((project) => {
          const { id } = project;
          return (
            <Project
              key={id}
              {...project}
            />
          );
        })}
      </div>
      
      {isEmpty && <div className="empty-list">You are not part of any project</div>}

      {observer}
    </div>
  );
});

ProjectList.type.displayName = "ProjectList";

export { ProjectList };
