export const SET_ALERT_MSG = "SET_ALERT_MSG";
export const CLEAR_ALERT_MSG = "CLEAR_ALERT_MSG";
export const SET_ALERT_USERS_SELECTED = "SET_ALERT_USERS_SELECTED";
export const CLEAR_ALERT_USERS_SELECTED = "CLEAR_ALERT_USERS_SELECTED";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_ALL_USERS = "SET_ALL_USERS";
export const GETALL_USERS_SUCCESS = "GETALL_USERS_SUCCESS";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const CLEAR_ALL_USERS = "CLEAR_ALL_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";

export const ADD_GROUPS_SUCCESS = "ADD_GROUPS_SUCCESS";
export const UPDATE_GROUPS_SUCCESS = "UPDATE_GROUPS_SUCCESS";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const CLEAR_ALL_GROUPS = "CLEAR_ALL_GROUPS";

export const ADD_PROJECTS_SUCCESS = "ADD_PROJECTS_SUCCESS";
export const UPDATE_PROJECTS_SUCCESS = "UPDATE_PROJECTS_SUCCESS";
export const DELETE_PROJECTS_SUCCESS = "DELETE_PROJECTS_SUCCESS";
export const CLEAR_ALL_PROJECTS = "CLEAR_ALL_PROJECTS";

export const ADD_FOLDERS_SUCCESS = "ADD_FOLDERS_SUCCESS";
export const UPDATE_FOLDERS_SUCCESS = "UPDATE_FOLDERS_SUCCESS";
export const DELETE_FOLDERS_SUCCESS = "DELETE_FOLDERS_SUCCESS";
export const CLEAR_ALL_FOLDERS = "CLEAR_ALL_FOLDERS";

export const ADD_BUILDS_SUCCESS = "ADD_BUILDS_SUCCESS";
export const UPDATE_BUILDS_SUCCESS = "UPDATE_BUILDS_SUCCESS";
export const DELETE_BUILDS_SUCCESS = "DELETE_BUILDS_SUCCESS";
export const CLEAR_ALL_BUILDS = "CLEAR_ALL_BUILDS";

export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";

export const ADD_FEEDBACKS_SUCCESS = "ADD_FEEDBACKS_SUCCESS";
export const UPDATE_FEEDBACKS_SUCCESS = "UPDATE_FEEDBACKS_SUCCESS";
export const DELETE_FEEDBACKS_SUCCESS = "DELETE_FEEDBACKS_SUCCESS";
export const CLEAR_ALL_FEEDBACKS = "CLEAR_ALL_FEEDBACKS";

export const SET_COUNT = "SET_COUNT";
export const SET_SORT_VALUE = "SET_SORT_VALUE";
export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const CLEAR_ALL_PLATFORMS = "CLEAR_ALL_PLATFORMS";

export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";

export const SHOW_SIDEPANEL = "SHOW_SIDEPANEL";
export const CLOSING_SIDEPANEL = "CLOSING_SIDEPANEL";
export const SET_CURRENT_PANEL = "SET_CURRENT_PANEL";
export const CLEAR_CURRENT_PANEL = "CLEAR_CURRENT_PANEL";
export const HIDE_SIDEPANEL = "HIDE_SIDEPANEL";
export const CLEAR_SIDEPANEL_VALUES = "CLEAR_SIDEPANEL_VALUES";

// Info
export const UPDATE_INFO = "UPDATE_INFO";
export const CLEAR_INFO = "CLEAR_INFO";

// constants used for mobile
export const OPEN_SEARCH = "OPEN_SEARCH";
export const CLOSE_SEARCH = "CLOSE_SEARCH";
export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
export const ADD_SEARCH_DATA = "ADD_SEARCH_DATA";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const SET_PROMPT_VALUE = "SET_PROMPT_VALUE";
export const CLEAR_PROMPT_VALUE = "CLEAR_PROMPT_VALUE";