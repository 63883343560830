import React, { useReducer, createContext } from "react";

import { reducer, initialState } from "./reducer";

export const InfoContext = createContext();

export const InfoContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <InfoContext.Provider value={{
      info: state,
      infoDispatch: dispatch
    }}>
      {children}
    </InfoContext.Provider>
  );
}