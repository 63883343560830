// @flow

import React, { memo } from "react";
import {
  AddButtonWrapper,
  GlobalSearch,
  TypeCounter,
  FilterPillWrapper,
  UserIndicator,
} from "components";
import { USERS, GROUPS } from "models/routes";
import "./StaticHeader.scss";

const StaticHeader = memo((props: {}) => {

  return (
    <div
      data-testid="user-heading-tab"
      className="axiom-static-header-container"
    >
      <div className="header-wrapper">
        <div className="row-1-wrapper">
          <div className="search-bar">
            <GlobalSearch />
          </div>
          <FilterPillWrapper />
          <AddButtonWrapper />
        </div>
        <div className="row-2-wrapper">
          <div className="col-1">
            <TypeCounter type="Users" path={USERS} />
            <TypeCounter type="Groups" path={GROUPS} />
          </div>
          <div className="col-2">
            <UserIndicator />
          </div>
        </div>
      </div>
    </div>
  );
});

StaticHeader.type.displayName = "StaticHeader";

export { StaticHeader };
