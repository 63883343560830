// @flow

import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import classnames from "classnames";
import { Icon, Search, ListContainer, Loader } from "components";
import { useClickOutside } from "hooks/useClickOutside";

import "./SearchList.scss";

type Props = {
  classname?: string,
  isLoading: boolean,
  searchObj: {
    classes: string,
    value: string,
    updateValue: (string) => void,
    clearValue: () => void,
    makeAPICall: () => void,
    delay: number,
    placeholder: string,
    inputRef?: HTMLInputElement
  },
  listObj: {
    type: string,
    data: Array,
    onSelect: () => void,
    withCreateFolder?: boolean,
    createFolderObj?: Object
  }
};

const SearchList = memo(({
  classname,
  isLoading = false,
  searchObj: {
    value,
    classes = "",
    updateValue = () => null,
    clearValue = () => null,
    makeAPICall = () => null,
    delay = 500 /* in ms */,
    placeholder,
    inputRef
  },
  listObj: {
    type,
    // results from api call
    data = [],
    onSelect = () => null,
    withCreateFolder = false,
    createFolderObj = {}
  }
}: Props) => {
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const searchListContainer = useRef(null);
  const timeOutId = useRef(null);

  const onOutsideClick = useCallback(() => setIsResultsOpen(false), []);

  useClickOutside(searchListContainer, onOutsideClick);

  const onInputFocus = useCallback(() => {
    setIsResultsOpen(true);
  }, []);

  const search = useCallback((e) => {
    const val = e.target.value;
    // update the value but don't make api call
    updateValue(val);
  }, [updateValue]);

  useEffect(() => {
    const id = timeOutId.current;

    if (id) {
      clearTimeout(id);
    }

    if (value) {
      timeOutId.current = setTimeout(async () => {
        makeAPICall();
      }, delay);
    }

  }, [delay, makeAPICall, value]);

  const containerClasses = classnames({
    "axiom-search-list-container": true,
    [classname]: classname ? true : false,
  });

  return (
    <div className={containerClasses}>
      <div className="list-container" ref={searchListContainer}>
        <Search
          classname={classes}
          search={value}
          onSearch={search}
          onClear={() => {
            setIsResultsOpen(false);
            clearValue();
          }}
          onInputFocus={onInputFocus}
          placeholder={placeholder}
          inputRef={inputRef}
        />

        {withCreateFolder && (
          <span className="arrow">
            <Icon name={isResultsOpen? "arrow-up" : "arrow-down" } />
          </span>
        )}

        {isLoading ? <Loader type="inline" />: ((value.length > 0  && data.length) || withCreateFolder) && isResultsOpen ? (
          <ListContainer data={data} type={type} onSelect={onSelect} withCreateFolder={withCreateFolder} createFolderObj={createFolderObj} />
        ) : null}

      </div>
    </div>
  );
});

SearchList.type.displayName = "SearchList";

export { SearchList };
