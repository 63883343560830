//@flow

import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { ToastContext } from 'mobile/data/toast';
import { showToast } from 'mobile/data/toast/action';
import { BottomBar, MDevice, MNavBar, MobileHeader } from "mobile/components";
import { Loader } from "components";
import UserDataService from "services/usersService";
import { Device as DeviceInfo } from "models/api/device";
import { getErrorMessage } from "utils/common";
import "./MDeviceView.scss";

const initialDevices = [];

const MDeviceView = () => {
  const [devices, setDevices] = useState(initialDevices);
  const { toastDispatch } = useContext(ToastContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const deviceId = useRef(null);

  const removeDevice = useCallback(async () => {
    try {
      const { data } = await UserDataService.removeDevice({ id: deviceId.current });
      setDevices(data.map(d => new DeviceInfo(d)));
      toastDispatch(showToast({
        type: "success",
        msg: "Device removed successfully"
      }));
    } catch (err) {
      const msg = getErrorMessage(err) || "Error removing the device";
      toastDispatch(showToast({
        type: "error",
        msg: msg
      }));
    }
  }, [toastDispatch]);

  const click = useCallback((id, clearPosition) => {
    const selectedDevice = devices.find(d => d.current_device);
    if(selectedDevice?.id === id) {
      return toastDispatch(showToast({
        type: "error",
        msg: "Cannot remove the current device"
      }));
    }

    const confirm = window.confirm("Are you sure to remove this device?");
    if(confirm) {
      deviceId.current = id;
      removeDevice();
    } else {
      deviceId.current = null;
      clearPosition({});
    }
  }, [devices, removeDevice, toastDispatch]);
  

  const getDevices = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await UserDataService.getDevices();
      const modifiedData = data.devices.map(d => new DeviceInfo(d));
      setDevices(modifiedData);
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in getting devices";
      setError(msg);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  return (
    <>
      <MobileHeader
        showLogo={true}
        withSearch={false}
        centeredText=""
        lastIcon="m-notifications"
      />
      <section className="m-device-wrapper">
        <h2 className="devices-text">Devices</h2>
        {loading && (
          <div className="observer">
            <Loader className="sm" />
          </div>
        )}
        {!loading && error && <div className="m-center-content">{error}</div>}
        {!loading && !error && (devices.length === 0) && devices !== initialDevices ? (
          <div className="m-center-content">No devices</div>
        ): (
          devices.map(device => {
            return (
              <MDevice key={device.id} {...device} click={click} />
            )
          })
        )}
        <BottomBar>
          <MNavBar />
        </BottomBar>
      </section>
    </>
  )
};

export { MDeviceView };