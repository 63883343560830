import {
  SET_FILTER,
  CLEAR_FILTER,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PLATFORMS,
} from "action-types";

export const setFilter = (text) => {
  return {
    type: SET_FILTER,
    payload: text,
  };
};

export const clearFilter = (text) => {
  return {
    type: CLEAR_FILTER,
    payload: text,
  };
};

export const clearAllFilters = () => {
  return {
    type: CLEAR_ALL_FILTERS,
  };
};

export const clearAllPlatforms = () => {
  return {
    type: CLEAR_ALL_PLATFORMS,
  };
};
