import {
  ADD_FOLDERS_SUCCESS,
  UPDATE_FOLDERS_SUCCESS,
  DELETE_FOLDERS_SUCCESS,
  CLEAR_ALL_FOLDERS,
} from "action-types";

export const initialState = [];

export function reducer (state, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_FOLDERS_SUCCESS:
      return [...payload, ...state];
    case UPDATE_FOLDERS_SUCCESS:
      return state.map((folder) =>
        folder.id === payload.id ? payload : folder
      );
    case DELETE_FOLDERS_SUCCESS:
      return state.filter((folder) => folder.id !== payload.id);
    case CLEAR_ALL_FOLDERS:
      return initialState;
    default:
      return state;
  }
}
