import {
  OPEN_SEARCH,
  CLOSE_SEARCH,
  UPDATE_SEARCH_VALUE,
  ADD_SEARCH_DATA
} from "action-types";

export const initialState = {
  isOpen: false,
  value: "",
  data: []
};

export function reducer (state, action) {
  const { payload, type } = action;
  switch(type) {
    case OPEN_SEARCH:
      return {...state, isOpen: true};
    case CLOSE_SEARCH:
      return {...state, isOpen: false, value: ""};
    case UPDATE_SEARCH_VALUE:
      return {...state, value: payload};
    case ADD_SEARCH_DATA:
      return {...state, data: payload};
    default:
      return state;
  }
}