import {
  ADD_GROUPS_SUCCESS,
  UPDATE_GROUPS_SUCCESS,
  CLEAR_ALL_GROUPS,
  DELETE_GROUP_SUCCESS,
} from "action-types";

export const addToGroupList = (group) => {
  return {
    type: ADD_GROUPS_SUCCESS,
    payload: group,
  };
};

export const updateGroupList = (group) => {
  return {
    type: UPDATE_GROUPS_SUCCESS,
    payload: group,
  };
};

export const clearGroupList = () => {
  return {
    type: CLEAR_ALL_GROUPS,
  };
};

export const deleteGroupList = (group) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: group,
  };
};
