// @flow
import React, { useState, useCallback, useRef, useMemo } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from 'react-router-dom';
import { Button, Checkbox, ConfirmationPopup, Icon, ToolTip } from "components";
import { redirectToRoute, getErrorMessage, isMAdmin } from "utils/common";
import { useClickOutside } from "hooks/useClickOutside";
import { deleteFeedbackList, updateFeedbackList } from "actions/feedbackActions";
import FeedbackService from "services/feedbackService";
import { Feedback } from "models/api/feedback";
import { setAlertMsg } from "actions/alertMsg";
import { FEEDBACKS } from "models/routes";
import "./IndividualFeedback.scss";

type Props = {
  id: number,
  type: string,
  description: string,
  platform: array,
  user: string,
  date: string,
  complete: boolean
};

const IndividualFeedback = ({
  id,
  type,
  description,
  platform = [],
  user,
  date,
  complete
}: Props) => {
  const userRole = useSelector((state) => state.auth.role);
  const mAdmin = useMemo(() => isMAdmin(userRole), [userRole]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const deleteEle = useRef(null);

  const deleteOutsideClick = useCallback(() => setDeletePopup(false), []);

  useClickOutside(deleteEle, deleteOutsideClick);

  const toggleDeletePopup = useCallback((e) => {
    e.stopPropagation();
    setDeletePopup((state) => !state);
  }, []);

  const changeComplete = useCallback(async () => {
    try {
      setLoading(true);
      const res = await FeedbackService.updateFeedback(id);
      dispatch(setAlertMsg({
        type: "success",
        msg: "Feedback updated successfully"
      }));
      dispatch(updateFeedbackList(new Feedback(res.data)));
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in updating feedback";
      dispatch(setAlertMsg({ type: "error", msg }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, id]);

  const onAccept = useCallback(async (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const res = await FeedbackService.deleteFeedback(id);
      dispatch(setAlertMsg({
        type: "success",
        msg: "Feedback deleted successfully"
      }));
      dispatch(deleteFeedbackList(res.data));
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in deleting feedback";
      dispatch(setAlertMsg({ type: "error", msg }));
    } finally {
      setLoading(false);
      setDeletePopup(false);
    }
  }, [dispatch, id]);
  
  const onCancel = useCallback((e) => {
    e.stopPropagation();
    setDeletePopup(false);
  }, []);

  const wrapperClasses = classnames(
    "axiom-individual-feedback-wrapper", {
      "completed": complete
    }
  );

  const deleteIconClasses = classnames({
    "delete-icon": true,
    "highlight": deletePopup
  });
  
  const onClick = useCallback(() => {
    redirectToRoute(generatePath(FEEDBACKS, { id }));
  }, [id]);

  return (
    <div className={wrapperClasses} onClick={onClick}>
      <div className="created-by">{user}</div>
      <div className="feedback-type">{type}</div>
      <div className="platform">
        {platform.join(", ")}
      </div>
      <div className="date">{date}</div>
      <div className="description">{description}</div>
      <div className="icons-wrapper">
        <div className={classnames("checkbox-wrapper")}>
          <ToolTip title={`${!complete ? "Complete": "Incomplete" }`} position="bottom" displace={true}>
            <Checkbox
              classname="user-list-cb"
              name="feedback-complete"
              isChecked={complete}
              value="complete"
              valueChange={changeComplete}
              disable={loading}
            />
          </ToolTip>
        </div>
        {mAdmin && (
          <div className={deleteIconClasses} ref={deleteEle}>
            <ToolTip title="Delete" position="bottom" displace={true}>
              <Button
                classes="delete-btn"
                click={toggleDeletePopup}
              >
                <Icon name="delete-icon" />
              </Button>
            </ToolTip>
          </div>
        )}
        <Icon name="users-more-options" classname="more-options" />
      </div>
      {deletePopup && (
        <div className="delete-confirmation-wrapper">
          <ConfirmationPopup
            text="Are you sure to delete this feedback?"
            acceptLabel="Yes, Delete"
            cancelLabel="Cancel"
            onAccept={onAccept}
            onCancel={onCancel}
            isLoading={loading}
          />
        </div>
      )}
    </div>
  )
};

export { IndividualFeedback };