// @flow

import React, { useState, useRef, useCallback, memo } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { PROJECT_TESTERS, PROJECT_GROUPS } from "constants.js";
import { ConfirmationPopup, Icon, ListContainer, ToolTip, Button } from "components";
import { setAlertMsg } from "actions/alertMsg";
import { useClickOutside } from "hooks/useClickOutside";
import { getErrorMessage } from "utils/common";
import { onBuildDownload } from "actions/buildActions";
import fixture from "./fixture.json";
import "./Build.scss";

type Props = {
  id: number,
  name: string,
  date: string,
  /** url for the build */
  link: string,
  /** indicates if the build is deleted or not */
  deleted?: boolean,
  classname?: string,
  click?: () => void,
  handleDeleteBuild: () => void,
};

const Build = memo(({
  id,
  name,
  date,
  link,
  deleted = false,
  classname,
  click = null,
  handleDeleteBuild = null,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const popupParent = useRef();
  const dispatch = useDispatch();
  const { moreOptions, restore } = fixture;

  const onOutsideClick = useCallback(() => {
    setShowPopup(false);
    setShowDeletePopup(false);
  }, []);

  useClickOutside(popupParent, onOutsideClick);

  const togglePopup = useCallback((e) => {
    e.stopPropagation();
    setShowPopup((state) => !state);
  }, []);

  const onSelect = useCallback((selectedId) => {
    switch (selectedId) {
      case 1: click(id, PROJECT_TESTERS);
              setShowPopup(false);
        break;
      case 2: click(id, PROJECT_GROUPS);
              setShowPopup(false);
        break;
      case 3:
      case 10:
        setShowDeletePopup(true);
        break;
      default:
    }
  }, [click, id]);

  const displayText = deleted ? "restore": "delete";

  const onAccept = useCallback(async () => {
    setLoading(true);
    try {
      await handleDeleteBuild();
      dispatch(
        setAlertMsg({
          type: "success",
          msg: `Successfully ${displayText}d the build`,
        })
      );
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in deleting build";
      dispatch(
        setAlertMsg({
          type: "error",
          msg,
        })
      );
    } finally {
      setShowPopup(false);
      setLoading(false);
      setShowDeletePopup(false);
    }
  }, [dispatch, handleDeleteBuild, displayText]);

  const wrapperClasses = classnames({
    "axiom-build-wrapper folder-box": true,
    [classname]: classname ? true : false,
  });

  const moreOptionsClasses = classnames({
    "more-options": true,
    active: showPopup,
  });

  const onClick = useCallback(() => click(id), [id, click]);

  const download = useCallback(async e => {
    e.stopPropagation();
    dispatch(onBuildDownload(link));
  }, [link, dispatch]);

  return (
    <div className={wrapperClasses} onClick={onClick}>
      <div className="individual-build">
        <div className="name">{name}</div>
        <div className="date">{date}</div>
        {deleted && <div className="state"><span className="circle"></span>Deleted</div> }
        <div className="download-link">
          <Button click={download} classes="default" >
            <ToolTip position="top" title="Download">
              <Icon name="download-icon" />
            </ToolTip>
          </Button>
        </div>
        <div
          className={moreOptionsClasses}
          onClick={togglePopup}
          ref={popupParent}
        >
          <ToolTip position="top" title="More Options">
            <Icon name="more-icon" />
          </ToolTip>
          {showPopup && (
            <div className="build-popup-wrapper">
              <ListContainer
                type="dropdown-list"
                classname="build-popup"
                data={deleted ? restore: moreOptions}
                onSelect={onSelect}
              />
            </div>
          )}
          {showDeletePopup && (
            <div className="delete-confirmation">
              <ConfirmationPopup
                text={`Are you sure to ${displayText} this build?`}
                acceptLabel={`Yes, ${displayText}`}
                cancelLabel="Cancel"
                onAccept={onAccept}
                onCancel={() => setShowDeletePopup(false)}
                isLoading={loading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

Build.type.displayName = "Build";

export { Build };
