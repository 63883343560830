import { getDateFormat } from "utils/common";
import type { TList } from "./list";
import type { TDevice } from "./device";

type TGroupProject = {
  id: number,
  name: string,
  projects: Array<TList>,
};

export type TProfile = {
  id: number,
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  role: string,
  doj: string,
  projects: Array<TList>,
  groups: Array<TGroupProject>,
  devices: Array<TDevice>
};

export class Profile {
  +id: number;
  +firstName: string;
  +lastName: string;
  +username: string;
  +email: string;
  +role: string;
  +doj: string;
  +projects: Array<TList>;
  +groups: Array<TGroupProject>;
  +devices: Array<TDevice>;

  constructor(raw: Object) {
    this.id = raw.id;
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
    this.username = raw.username;
    this.email = raw.email;
    this.role = raw.role;
    this.doj = getDateFormat(raw.doj);
    this.projects = raw.projects;
    this.groups = raw.groups;
    this.devices = raw.devices;
  }
}

export type TName = {
  firstName: string,
  lastName: string,
};

export class Name {
  +firstName: string;
  +lastName: string;

  constructor(raw: Object) {
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
  }
}
