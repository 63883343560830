import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import users from "./usersReducer";
import groupsReducer from "./groupsReducer";
import projectReducer from "./projectsReducer";
import foldersReducer from "./foldersReducer";
import buildsReducer from "./buildsReducer";
import notificationsReducer from "./notificationsReducer";
import feedbacksReducer from "./feedbacksReducer";
import infoReducer from "./infoReducer";
import sortReducer from "./sortReducer";
import filterReducer from "./filterReducer";
import overlayReducer from "./overlayReducer";
import sidePanelReducer from "./sidePanelReducer";
import countReducer from "./count";

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  count: countReducer,
  users: users,
  groups: groupsReducer,
  projects: projectReducer,
  folders: foldersReducer,
  builds: buildsReducer,
  feedbacks: feedbacksReducer,
  notifications: notificationsReducer,
  info: infoReducer,
  sort: sortReducer,
  filter: filterReducer,
  overlay: overlayReducer,
  sidePanel: sidePanelReducer,
});

export default rootReducer;
