// @flow

import React, { useState, useCallback, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { Button, ToolTip } from "components";
import { useLocation } from "react-router-dom";
import { getBasePath, redirectToRoute } from "utils/common";
import { generatePath } from 'react-router-dom';
import { PROJECTS, USERS, GROUPS, FEEDBACKS } from "models/routes";
import { setSortInfo } from "actions/sort";
import fixture from "./fixture.json";
import { ADD_USER, CREATE_GROUP, CREATE_PROJECT, FEEDBACK_VIEW } from "constants.js";
import classnames from "classnames";

import "./AddButtonWrapper.scss";
import UserRole from "components/UserRole";

const AddButtonWrapper = memo(() => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { options } = fixture;
  const [value, setValue] = useState(options[2]);
  const pathProject = generatePath(PROJECTS);

  let tooltipText, type;
  switch (getBasePath(pathname)) {
    case generatePath(USERS):
      tooltipText = "User";
      type = ADD_USER;
      break;
    case generatePath(GROUPS):
      tooltipText = "Group";
      type = CREATE_GROUP;
      break;
    case pathProject:
      tooltipText = "Project";
      type = CREATE_PROJECT;
      break;
    case generatePath(FEEDBACKS):
      tooltipText = "Feedback";
      type = FEEDBACK_VIEW;
      break;
    default:
      tooltipText = "";
      type = "";
  }

  const containerClasses = classnames({
    "axiom-add-button-container": true,
    "hide": !tooltipText || !type
  });

  const clickHandler = useCallback(() => {
    redirectToRoute(generatePath(window.location.pathname), `panel=${type}`);
  }, [type]);

  const setSelectedValue = useCallback(
    (val) => {
      setValue(val);

      const { key } = val;

      let obj = {
        by: "",
        ASC: true,
      };

      switch (key) {
        case "ASC":
        case "DESC":
          // select ASC or DESC based on name
          obj.by = "name";
          obj.ASC = key === "ASC" ? true : false;
          break;
        default:
          // sort based on created or updated project by DESC
          obj.by = key;
          obj.ASC = false;
      }

      dispatch(setSortInfo(obj));
    },
    [dispatch]
  );

  useEffect(() => {
    if (pathname === pathProject) {
      setValue(options[2]);
    }
  }, [pathname, options, pathProject]);

  return (
    <div className={containerClasses}>
      {pathname === pathProject && (
        <div className="sort-wrapper">
          <UserRole
            options={options}
            value={value}
            onChange={setSelectedValue}
            classname="axiom-add-user-role-container"
            showDefaultValue={true}
          />
        </div>
      )}
      <ToolTip title={`Add ${tooltipText}`} position="left">
        <Button buttonType="add" click={clickHandler} />
      </ToolTip>
    </div>
  );
});

AddButtonWrapper.type.displayName = "AddButtonWrapper";

export { AddButtonWrapper };
