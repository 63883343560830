import { dateTime } from "utils/common";

export type TBuildList = {
  id: number,
  name: string,
  uploadedOn: string,
  link: string,
  deleted: boolean
};

export class BuildList {
  +id: number;
  +name: string;
  +uploadedOn: string;
  +link: string;
  +deleted: boolean;

  constructor(raw: Object) {
    this.id = raw.id;
    this.name = raw.name;
    this.uploadedOn = dateTime(raw.uploadedOn).split(",")[0];
    this.link = raw.link;
    this.deleted = raw.deleted;
  }
}
