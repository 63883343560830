import axios from "services/axios";
import { setAlertMsg } from "actions/alertMsg";
import { setAuthUser, removeAuthUser } from "actions/auth";
import store from "store";
import { getToken, clearToken, setToken, getPrevRoute, setPrevRoute, clearPrevRoute, getErrorMessage, redirectToRoute, isMobile } from "utils/common";
import history from "./history";
import { generatePath } from 'react-router-dom';
import { TAuthUser, AuthUser } from "models/api/authenticate";
import { PROJECTS, LOGIN } from "models/routes";
import { notify, unNotify } from "services/browser";

const mobile = isMobile();

export const tryAutoLogin = async () => {
  try {
    const authToken = getToken();

    if (!authToken) {
      if(window.location.pathname !== LOGIN) {
        setPrevRoute(window.location.pathname + window.location.search);
        // redirect to login page
        redirectToRoute(LOGIN);
      }
    } else {
      const { data } = await axios.get("/users/myself");

      const userData: TAuthUser = new AuthUser(data);

      // set authenticated user
      store.dispatch(setAuthUser({ ...userData, isTokenVerified: true }));

      // register the device browser for notifications when using mobile
      if(mobile) {
        notify().catch(() => null);
      }

      const prevRoute = getPrevRoute();

      if(window.location.pathname === LOGIN) {
        // direct to projects screen
        history.replace("/projects");
      } else if(prevRoute) {
        history.replace(prevRoute);
        clearPrevRoute();
      }
    }
  } catch (err) {
    // remove the token
    clearToken();

    // clear any previous user info (if any)
    store.dispatch(removeAuthUser());

    const msg = getErrorMessage(err) || "Error in auto signing.";

    // show error message
    store.dispatch(
      setAlertMsg({
        type: "error",
        msg,
      })
    );

    // redirect to login page
    redirectToRoute(LOGIN);
  }
};

export const logIn = async (url) => {
  try {
    const {
      data: { user, jwt },
    }: AuthUser = await axios.get(url);

    setUserInfo(user, jwt);
    
    // register the device browser for notifications when using mobile
    if(mobile) {
      notify().catch(() => null);
    }
  } catch (err) {
    const errorMsg = getErrorMessage(err) || "Error couldn't sign in.";

    // show error message
    store.dispatch(
      setAlertMsg({
        type: "error",
        msg: errorMsg,
      })
    );


    // redirect to login page
    redirectToRoute(LOGIN);
  }
};

export const setUserInfo = (user, jwt) => {
  const userData: TAuthUser = new AuthUser(user);

    // set authenticated user
    store.dispatch(setAuthUser({ ...userData, isTokenVerified: true }));

    // store the authToken
    setToken(jwt);

    const prevRoute = getPrevRoute();

    if(prevRoute) {
      history.replace(prevRoute);
      return clearPrevRoute();
    }

    // direct to projects screen
    redirectToRoute(generatePath(PROJECTS));
};

export const logOut = async ({ mobileFun }) => {
  try {
    await unNotify();
  } catch (err) {
    console.error(err);
  } finally {
    // remove the token
    clearToken();
  
    // clear any previous user info (if any)
    store.dispatch(removeAuthUser());

    let obj = {
      type: "success",
      msg: "You've logged out successfully",
    };
  
    // show user is logged out
    if(mobileFun) {
      // use the toast to display message for mobile
      mobileFun(obj);
    } else {
      // use the alertpanel to display message for desktop
      store.dispatch(setAlertMsg(obj));
    }

    clearPrevRoute();
  
    // redirect to login page
    redirectToRoute(LOGIN);
  }
};
