import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Search, ListContainer, Loader } from "components";
import { SearchContext } from "mobile/data/search";
import { useParams, generatePath } from 'react-router-dom';
import {
  addSearchData,
  closeSearch,
  updateSearch
} from "mobile/data/search/action";
import { generateProjectsURL, redirectToRoute } from "utils/common";
import { List } from "models/api/list";
import { PROJECTS } from "models/routes";
import ProjectsDataService from "services/projectsService";
import { LATEST_BUILD_QUERY } from "constants.js";
import "./MSearch.scss";

const MSearch = () => {
  const { search: { value, data, isOpen }, searchDispatch } = useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const timeOutId = useRef(null);
  const input = useRef(null);

  const params = useParams();
  const { id: projectId } = params;

  const clearValue = useCallback(() => {
    searchDispatch(updateSearch(""));
  }, [searchDispatch]);

  const updateValue = useCallback(e => {
    searchDispatch(updateSearch(e.target.value));
  }, [searchDispatch]);

  useEffect(() => {
    const classes = window.document.body.classList;
    if(isOpen) {
      // focus the input
      const ele = input.current;
      classes.add("no-scroll");
      ele && ele.focus();
    } else {
      classes.remove("no-scroll");
    }
  }, [isOpen]);

  useEffect(() => {
    const id = timeOutId.current;
    setLoading(true);

    if (id) {
      clearTimeout(id);
    }

    if (value) {
      timeOutId.current = setTimeout(async () => {
        let array;

        try {
          if(projectId) {
            array = (await ProjectsDataService.getProjectFolders(projectId, value)).data.folders || [];
          } else {
            array = (await ProjectsDataService.projectsList(
              generateProjectsURL({
                sort: {by: "name", ASC: true},
                count: 0,
                filterArr: [{
                  type: "state",
                  val: "Active Projects"
                }],
                _q: value
              })
            )).data;
          }
          const listData = array.map((item) => new List(item));
          searchDispatch(addSearchData(listData));
        } catch(err) {
          console.error("Error getting projects");
        } finally {
          setLoading(false);
        }
        // wait for 300ms before making API call
      }, 300);
    }

  }, [value, searchDispatch, projectId, setLoading]);

  const onSelect = useCallback((id) => {
    let routeObj = {};
    let queryParams = "";
    if(projectId) {
      routeObj = {id: projectId, fId: id};
      queryParams = LATEST_BUILD_QUERY;
    } else {
      routeObj = { id };
    }
    redirectToRoute(generatePath(PROJECTS, routeObj), queryParams);
    searchDispatch(closeSearch());
  }, [projectId, searchDispatch]);

  const onCancel = useCallback(() => {
    searchDispatch(closeSearch());
  }, [searchDispatch]);

  if(!isOpen) return null;

  return (
    <div className="m-search-container">
      <div className="search-wrapper">
      <Search
        classname=""
        search={value}
        placeholder="Search"
        onSearch={updateValue}
        onClear={clearValue}
        inputRef={input}
        withCancelObj={{
          showCancelBtn: true,
          click: onCancel
        }}
      />
      {
        value && (
          loading ? <Loader className="sm" />: (
            data.length ? (
              <ListContainer
              data={data}
              onSelect={onSelect}
            />
            ) : <div className="no-result-text">No results found</div>
          )
        )
      }
      </div>
    </div>
  );
};

export { MSearch };