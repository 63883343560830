import http from "http-common";

const createGroup = (data) => {
  return http.post("/groups", data);
};

const groupslist = (filters) => {
  return http.get(`/groups/${filters}`);
};

const groupdetails = (id) => {
  return http.get(`/groups/${id}`);
};

const updateGroup = (id, data) => {
  return http.put(`/groups/${id}`, data);
};

const deleteGroup = (id) => {
  return http.delete(`/groups/${id}`);
};

export default {
  createGroup,
  groupslist,
  groupdetails,
  updateGroup,
  deleteGroup,
};
