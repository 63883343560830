// @flow

import React, { memo } from "react";
import * as Icons from "./Icons";
import "./Icon.scss";

type Props = {
  name: string,
  classname: string,
  dataTestId?: string,
};

const Icon = memo(({ name, classname, dataTestId = "Icon" }: Props) => {
  return (
    <div data-testid={dataTestId} className={`${classname} name-${name}`}>
      {name === "projects" && <Icons.Projects />}
      {name === "users" && <Icons.Users />}
      {name === "notifications" && <Icons.Notifications />}
      {name === "notifications-tick" && <Icons.NotificationsWithTick />}
      {name === "logout" && <Icons.Logout />}
      {name === "search-icon" && <Icons.SearchIcon />}
      {name === "clear-icon" && <Icons.ClearIcon />}
      {name === "addbutton" && <Icons.AddButton />}
      {name === "closeicon" && <Icons.CloseIcon />}
      {name === "delete-icon" && <Icons.DeleteIcon />}
      {name === "users-more-options" && <Icons.UsersMoreOption />}
      {name === "alert-success" && <Icons.AlertSuccess />}
      {name === "alert-failure" && <Icons.AlertFailure />}
      {name === "user-checked-icon" && <Icons.UserCheckedIcon />}
      {name === "dropdown-icon" && <Icons.DropdownIcon />}
      {name === "filter-icon" && <Icons.FilterIcon />}
      {name === "arrow-down" && <Icons.ArrowDown />}
      {name === "arrow-up" && <Icons.ArrowUp />}
      {name === "upload-icon" && <Icons.UploadIcon />}
      {name === "more-icon" && <Icons.MoreOption />}
      {name === "plus-button" && <Icons.plusButton />}
      {name === "arrow-right" && <Icons.ArrowRight />}
      {name === "android-icon" && <Icons.androidIcon />}
      {name === "ios-icon" && <Icons.iosIcon />}
      {name === "web-icon" && <Icons.webIcon />}
      {name === "build-icon" && <Icons.buildIcon />}
      {name === "tester-icon" && <Icons.testerIcon />}
      {name === "groups-icon" && <Icons.groupsIcon />}
      {name === "ci_cd-icon" && <Icons.ci_cdIcon />}
      {name === "zip-icon" && <Icons.zipIcon />}
      {name === "download-icon" && <Icons.downloadIcon />}
      {name === "edit-icon" && <Icons.editIcon />}
      {name === "close-icon-inverse" && <Icons.feedbackCloseIcon />}
      {name === "feedback-upload-icon" && <Icons.feedbackUploadIcon />}
      {name === "feedbacks" && <Icons.feedbacksIcon />}
      {name === "open-eye" && <Icons.openEye />}
      {name === "closed-eye" && <Icons.closedEye />}
      {name === "x-mark" && <Icons.xMark />}
      {name === "settings" && <Icons.settings />}
      {/* mobile icons */}
      {name === "m-left-arrow" && <Icons.mLeftArrow />}
      {name === "m-device" && <Icons.device />}
      {name === "m-search" && <Icons.MobileSearch />}
      {name === "m-notifications" && <Icons.MNotifications />}
      {name === "m-previous-builds" && <Icons.MPreviousBuilds />}
      {name === "m-edit-icon" && <Icons.editIconMobile />}
      {name === "m-android-phone" && <Icons.androidPhone />}
      {name === "m-ios-phone" && <Icons.iosPhone />}
      {name === "google-icon" && <Icons.mGoogleIcon />}
    </div>
  );
});

Icon.type.displayName = "Icon";

export { Icon };
