//@flow

import React, { useState, useMemo, useCallback, useRef } from 'react';
import axios from "axios";
import parse from "html-react-parser";
import { Button, Icon } from "components";
import { MBuildDetails as BuildDetails } from "models/api/mBuildDetails";
import { downloadBuild } from "services/download";
import BuildDataService from "services/buildService";
import { ANDROID, ANDROID_MIME_TYPE } from "constants.js";
import { getBuildTypeText, downloadBuildUsingEle } from "utils/common";
import { BottomBar } from '..';

import "./MBuildDetails.scss";

const MBuildDetails = ({
  id,
  link,
  name,
  date,
  size,
  bundleID,
  uploadedBy: { name: uploadUserName = ""} = {},
  support,
  type,
  jobId = "",
  commitMessage = "",
  releaseNotes = { text: "" },
  knownIssues = { text: "" },
  moreInfo = { text: "" }

}: BuildDetails) => {

  const [downloading, setDownloading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const obj = useMemo(() => ({
    "Build": name,
    "Size": `${size} MB`,
    "Date": date,
    "Bundle ID": bundleID,
    "Uploaded By": uploadUserName,
    "Support": `${getBuildTypeText(type)} ${support} and above`,
    "Jenkins Job Id": jobId
  }), [name, size, date, bundleID, uploadUserName, support, type, jobId]);

  const obj2 = {
    "Release Notes": releaseNotes,
    "Known Issues": knownIssues,
    "More Info": moreInfo
  };

  const onProgress = useCallback((progressEvent) => {
    let percentage = parseInt(
      Math.round((progressEvent.loaded / progressEvent.total) * 100)
    );
    setUploadPercentage(percentage);
  }, []);

  const download = useCallback(async () => {
    try {
      setDownloading(true);
      const response = await BuildDataService.getBuildLink(link);
      const fileLink = response.data;
      // update the build tracking installation
      BuildDataService.updateTracking(id).catch(() => null);
      if(type !== ANDROID) {
        // TODO: show download progress and add cancel feature
        downloadBuildUsingEle(fileLink);
      } else {
        const res = await downloadBuild({
          link: fileLink,
          onProgress,
          cancelToken: cancelTokenSource.current.token
        });

        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            // for android applications, set the content-type as 'application/vnd.android.package-archive' to be directly installable 
            type: ANDROID_MIME_TYPE
          })
        );

        downloadBuildUsingEle(url);
      }
    } catch (err) {
      console.log(err)
    } finally {
      setUploadPercentage(0);
      setDownloading(false);
    }
  }, [link, type, onProgress, id]);

  const cancel = useCallback(() => {
    cancelTokenSource.current.cancel("Request cancelled by user");
    setDownloading(false);
    // generate a new source token
    cancelTokenSource.current = axios.CancelToken.source();
  }, []);

  return (
    <div className="m-build-details-container" data-testid="build-details-container">
      <h3 className="name-type">{name}</h3>
      <h2 className="text">Build Info</h2>
      <div className="basic-info">
        {Object.keys(obj).map(key => {
          if(key === "Jenkins Job Id" && !obj[key]) return null;

          return (
            <div key={key} className="basic-wrapper">
              <div className="key">{key}</div>
              <div className="value">{obj[key]}</div>
            </div>
          );
        })}
      </div>
      {commitMessage && (
        <div className="info-wrapper commit-msg-wrapper">
          <h5 className="title">Commit Message</h5>
          <div className="value">{commitMessage}</div>
        </div>
      )}
      <div className="additional-info">
        {Object.keys(obj2).map(key => {
          if(obj2[key].text) {
            return (
              <div key={key} className="info-wrapper">
                <h5 className="title">{key}</h5>
                <div className="value">{parse(obj2[key].text)}</div>
              </div>
            );
          }
          return null;
        })}
      </div>
      <BottomBar>
        <div className="install-btn-wrapper">
          {downloading ? (
            <>
              <div className="meter">
                <span style={{width: `${uploadPercentage}%`}}>{`${uploadPercentage}%`}</span>
              </div>
              <div className="close-btn-wrapper">
                <Button classes="close-btn" click={cancel}>
                  <Icon name="closeicon" classname="close-icon"/>
                </Button>
              </div>
            </>
          ) : (
            <Button
              classes="install"
              text="Install"
              click={download}
            />
          )}
        </div>
      </BottomBar>
    </div>
  );
};

export { MBuildDetails };