import React, { useState, useCallback, useEffect } from 'react';
import { BottomBar, MNavBar, MobileHeader } from 'mobile/components';
import NotificationService from "services/notification";
import { Notification, Loader } from 'components';
import { Notification as NotificationClass} from "models/api/notification";
import { getErrorMessage, redirectToRoute } from 'utils/common';
import "./MNotification.scss";

const initialNotifications = [];

const MNotification = () => {
  const [notifications, setNotifications] = useState(initialNotifications);
  const [loading, setLoading] = useState(false);
  const [customMsg, setCustomMsg] = useState("");

  const getNotifications = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await NotificationService.getNotifications();
      const notificationData = data.map(nt => new NotificationClass(nt, false));
      setNotifications(notificationData);
    }
    catch (err) {
      const msg = getErrorMessage(err) || "Error getting notifications";
      setCustomMsg(msg);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const readNotification = useCallback(async (obj) => {
    let ids = [];
    const { id, url } = obj || {};

    if(url) {
      redirectToRoute(url);
    } 
    
    if(id || url) {
      ids = [id];
    } else {
      notifications.forEach(({ id, viewed }) => {
        if(!viewed) {
          ids.push(id);
        }
      });
    }

    try {
      const { data } = await NotificationService.markAsRead({ ids });
      if(data.ok) {
        if(id || url) {
          const ntf = notifications.map(nt => nt.id === id ? ({...nt, viewed: true}): nt);
          setNotifications(ntf);
        } else {
          setNotifications([]);
        }
      }
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in updating notification";
      setCustomMsg(msg);
    }

  }, [notifications]);

  const notificationExists = notifications.length !== 0;

  const content = customMsg ? (
    <div className="m-center-content">{customMsg}</div>
  ) : (
    notificationExists || initialNotifications === notifications ? (
      <div className="notifications-wrapper"> 
        {
          notifications.map(obj => 
            <Notification
              key={obj.id}
              {...obj}
              click={readNotification}
            />
          )
        }
      </div>
    ): (
      <div className="m-center-content">You have no new notifications</div>
    )
  );

  return (
    <>
      <MobileHeader
        showLogo={false}
        withSearch={false}
        centeredText="Notifications"
        lastIcon={!loading && notificationExists ? "clear": "no-icon" }
        iconFun={readNotification}
      />
      <section className="m-notification-container">

        {loading ? (
          <div className="loading-text">
            <Loader className="sm" />
          </div>
        ) : content}

        <BottomBar>
          <MNavBar />
        </BottomBar>
      </section>
    </>
  );
};

export { MNotification };