import { generatePath } from "utils/common";
import projectIcon from "assets/images/project-icon.png";

export type TNotification = {
  id: string,
  viewed: boolean,
  userName: string,
  text: string,
  projectName: string,
  projectIcon: string,
  url: string,
  time: string
}

export class Notification {
  +id: string;
  +viewed: boolean;
  +userName: string;
  +text: string;
  +projectName: string;
  +projectIcon: string;
  +url: string;
  +time: string;

  constructor(raw: Object, withQuery: boolean = true) {
    this.id = raw.id;
    // by default it is false
    this.viewed = false;
    this.userName = raw.userName;
    this.text = raw.text;
    this.projectName = raw.projectName;
    this.projectIcon = raw.projectIcon || projectIcon;
    this.time = raw.time;
    this.url = withQuery ? generatePath(raw): generatePath(raw).split("?")[0];
  }
}
