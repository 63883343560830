// @flow

import React, { memo } from "react";
import { Device } from "components";
import "./DeviceDetails.scss";

type DeviceType = {
  id: number,
  name: string,
  model: string,
  os_version: string,
  deleteFun: (id) => void | null,
  showDeleteIcon: boolean
};

type Props = {
  title: string,
  devices: Array<DeviceType>,
  deleteFun: (id) => void | null,
  showDeleteIcon: boolean
};

const DeviceDetails = memo(({ title, devices, deleteFun, showDeleteIcon }: Props) => {
  if(!devices.length) {
    return null;
  }

  return (
    <div className="device-details-container">
      <div className="title-count-wrapper">
        <h3 className="device-title">{title}</h3>
        <h3 className="device-count">{devices && devices.length}</h3>
      </div>
      <div className="device-details-heading-wrapper">
        <div className="device-details-heading">
          <h4 className="model-label">Device Model</h4>
          <h4 className="name-label">Device Name</h4>
          <h4 className="version-label">OS Version</h4>
        </div>
      </div>
      <div className="user-device-details-wrapper">
        {devices.map((device) => {
          const { id, name, model, os_version } = device;
          return (
            <Device
              key={id}
              id={id}
              name={name}
              model={model}
              version={os_version}
              deleteFun={deleteFun}
              showDeleteIcon={showDeleteIcon}
            />
          );
        })}
      </div>
    </div>
  );
});

DeviceDetails.type.displayName = "DeviceDetails";

export { DeviceDetails };
