import ForgotPassword from "./ForgotPassword";
import MDeviceView from "./MDeviceView";
import MMain from "./MMain";
import MProfile from "./MProfile";
import MSearch from "./MSearch";
import MNotification from "./MNotification";
import MChangePassword from "./MChangePassword";
import MEditName from "./MEditName";
import MMobileObserver from "./MMobileObserver";

export {
  ForgotPassword,
  MDeviceView,
  MMain,
  MProfile,
  MSearch,
  MNotification,
  MChangePassword,
  MEditName,
  MMobileObserver
};