import {
  ADD_PROJECTS_SUCCESS,
  UPDATE_PROJECTS_SUCCESS,
  DELETE_PROJECTS_SUCCESS,
  CLEAR_ALL_PROJECTS,
} from "action-types";

export const addToProjectList = (project) => {
  return {
    type: ADD_PROJECTS_SUCCESS,
    payload: project,
  };
};

export const updateProjectList = (project) => {
  return {
    type: UPDATE_PROJECTS_SUCCESS,
    payload: project,
  };
};

export const deleteProjectList = (project) => {
  return {
    type: DELETE_PROJECTS_SUCCESS,
    payload: project,
  };
};

export const clearProjectList = () => {
  return {
    type: CLEAR_ALL_PROJECTS,
  };
};
