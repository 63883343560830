import {
  ADD_FOLDERS_SUCCESS,
  UPDATE_FOLDERS_SUCCESS,
  DELETE_FOLDERS_SUCCESS,
  CLEAR_ALL_FOLDERS,
} from "action-types";

export const addToFolderList = (folder) => {
  return {
    type: ADD_FOLDERS_SUCCESS,
    payload: folder,
  };
};

export const updateFolderList = (folder) => {
  return {
    type: UPDATE_FOLDERS_SUCCESS,
    payload: folder,
  };
};

export const deleteFolderList = (folder) => {
  return {
    type: DELETE_FOLDERS_SUCCESS,
    payload: folder,
  };
};

export const clearFolderList = () => {
  return {
    type: CLEAR_ALL_FOLDERS,
  };
};
