import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classnames from "classnames";
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components';
import { usePrevious } from "hooks/usePrevious";
import { setAlertMsg } from "actions/alertMsg";

import { addToUserList, clearUserList } from "actions/userActions";
import { addToProjectList, clearProjectList } from "actions/projectActions";
import { addToGroupList, clearGroupList } from "actions/groupActions";
import { addToBuildList, clearBuildList } from "actions/buildActions";
import { addToFeedbackList, clearFeedbackList } from "actions/feedbackActions";

import UserDataService from "services/usersService";
import GroupDataService from "services/groupsService";
import ProjectService from "services/projectsService";
import FolderDataService from "services/folder";
import FeedbackService from "services/feedbackService";

import { UsersList as UserData } from "models/api/filter";
import { Project as ProjectData } from "models/api/project";
import { Group as GroupData } from "models/api/groups";
import { BuildList as BuildListClass } from "models/api/builds";
import { Feedback } from "models/api/feedback";

import { generateUrl, USER_LIST_LIMIT, generateProjectsURL,
  PROJECT_LIST_LIMIT, generateGroupsURL,
  GROUP_LIST_LIMIT, getErrorMessage, generateBuildsURL, BUILD_LIST_LIMIT, generateFeedbackURL, FEEDBACK_LIST_LIMIT, isSortEqual, isFilterEqual } from "utils/common";
import { setCount } from 'actions/countActions';
import { setSortInfo } from 'actions/sort';
import { clearAllFilters, setFilter } from 'actions/filter';
import fixture from "components/Filter/fixture.json";

const { projectFilter } = fixture;


type Props = {
  type: "users" | "projects" | "groups" | "builds" | "feedbacks",
  folderId: number | undefined
};

export const useObserver = ({
  type = "users",
  folderId
}: Props) => {
  const data = useSelector(state => state[type]);
  const [ref, inView] = useInView({ threshold: 0 });
  const [pageCount, setPageCount] = useState(0);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const prevInView = usePrevious(inView);
  const dispatch = useDispatch();
  
  const selectedFilters = useSelector(state => state.filter, isFilterEqual);
  const sort = useSelector(state => state.sort, isSortEqual);

  const { clearFun, dataFun, dataModel, addToList, limit, isGroup, isUser, isBuild, isFeedback, isProject } = useMemo(() => {
    switch (type) {
      case "users":
        return {
          clearFun: clearUserList,
          dataFun: () => UserDataService.findWithFilters(generateUrl(sort, pageCount, selectedFilters)),
          dataModel: UserData,
          addToList: addToUserList,
          limit: USER_LIST_LIMIT,
          isUser: true
        };
      case "projects":
        return {
          clearFun: clearProjectList,
          dataFun: () => ProjectService.projectsList(generateProjectsURL({ sort, count: pageCount, filterArr: selectedFilters })),
          dataModel: ProjectData,
          addToList: addToProjectList,
          limit: PROJECT_LIST_LIMIT,
          isProject: true
        };
      case "groups":
        return {
          clearFun: clearGroupList,
          dataFun: () => GroupDataService.groupslist(generateGroupsURL(pageCount, selectedFilters)),
          dataModel: GroupData,
          addToList: addToGroupList,
          limit: GROUP_LIST_LIMIT,
          isGroup: true
        };
      case "builds":
        return {
          clearFun: clearBuildList,
          dataFun: () => FolderDataService.getBuildsList(generateBuildsURL({ count: pageCount, folderId })),
          dataModel: BuildListClass,
          addToList: addToBuildList,
          limit: BUILD_LIST_LIMIT,
          isBuild: true
        };
      case "feedbacks":
        return {
          clearFun: clearFeedbackList,
          dataFun: () => FeedbackService.getFeedbacks(generateFeedbackURL({ sort, filters: selectedFilters, count: pageCount })),
          dataModel: Feedback,
          addToList: addToFeedbackList,
          limit: FEEDBACK_LIST_LIMIT,
          isFeedback: true
        };
      default:
    }
  }, [type, pageCount, selectedFilters, sort, folderId]);

  useEffect(() => {
    setPageCount(0);
    setComplete(false);
    dispatch(clearFun());
  }, [dispatch, sort, selectedFilters, clearFun, folderId]);

  const getData = useCallback(async () => {
    let dataRes, count;
    try {
      const res = await dataFun();
      dataRes = res.data;

      if(Array.isArray(res.data)) {
        dataRes = res.data;
      } else {
        ({ [type]: dataRes = [], count } = res.data);
        if(!pageCount && "count" in res.data) {
          dispatch(setCount({ [type]: count}));
        }
      }
      
      const modelledData = dataRes.map(dt => new dataModel(dt));
      dispatch(addToList(modelledData));
      setPageCount(count => count + 1);
      if(modelledData.length < limit) {
        setComplete(true);
      }
    } catch(err) {
      const msg = getErrorMessage(err) || `Error in getting ${type} list`;
      dispatch(
        setAlertMsg({
          type: "error",
          msg,
        })
      );
      setComplete(true);
    } finally {
      setLoading(false);
    }
  }, [type, addToList, dataFun, dataModel, dispatch, limit, pageCount]);

  useEffect(() => {
    if(prevInView !== inView && inView) {
      setLoading(true);
      getData();
    }
  }, [inView, prevInView, getData]);

  useEffect(() => {
    if(!isGroup && !isBuild) {
      // set default sort value
      dispatch(setSortInfo({
        by: "name",
        ASC: true,
      }));
    }

    if(!isBuild) {
      dispatch(clearAllFilters());
    }
  }, [dispatch, isGroup, isBuild]);

  useEffect(() => {
    let newArr = [];
    
    // apply the filters 'active', 'archive' and 'deleted' to display all projects on entering the projects path
    if(isProject) {
      // index 1 refers to the key state in projectFilter fixture
      const index = 1;
      const len = projectFilter[index].data.length;

      for(let i=1; i<len; i++) {
        newArr.push((() => {
          const { key, data } = projectFilter[index];
          return {
            type: key,
            val: data[i]
          };
        })());
      }

      dispatch(setFilter(newArr));
    }
  }, [dispatch, isProject]);

  const dataLength = data.length;

  const loaderStyles = classnames("md", { "center": !dataLength && (isUser || isFeedback) });

  return {
    data,
    isEmpty: !loading && !!pageCount && !dataLength,
    observer: !complete && (
      <div className="observer" ref={ref}>
        { loading && <Loader className={loaderStyles} /> }
      </div>
    )
  }

};
