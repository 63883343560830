export type TFolder = {
  id: number,
  name: string,
  deleted: boolean
};

export class Folder {
  +id: number;
  +name: string;
  +deleted: boolean;

  constructor(raw: Object) {
    this.id = raw.id;
    this.name = raw.name;
    this.deleted = raw.deleted;
  }
}
