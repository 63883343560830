// @flow

import React, { useState, useRef, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isAdminOrMasteradmin,
  capitalize,
  getErrorMessage,
  redirectToRoute
} from "utils/common";
import { ConfirmationPopup, Button, ToolTip, Icon, Checkbox } from "components";
import { generatePath } from 'react-router-dom';
import { updateUserList } from "actions/userActions";
import { setAlertMsg } from "actions/alertMsg";
import { UsersList as UserData } from "models/api/filter";
import { EmailConfirmation } from "models/api/email";
import { USERS } from "models/routes";
import UserDataService from "services/usersService";
import classnames from "classnames";
import { useClickOutside } from "hooks/useClickOutside";
import fixture from "components/UserIndicator/fixture.json";
import "./User.scss";

type Props = {
  id: number,
  name: string,
  email: string,
  role: string,
  DOJ: string,
  blocked: boolean,
  confirmed: boolean,
  userType: boolean,
};

const User = memo(({
  id,
  name,
  email,
  role,
  DOJ,
  blocked,
  confirmed,
  userType,
  inviter,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const [deletePopup, setDeletePopup] = useState(false);
  const [pendingPopup, setPendingPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteEle = useRef(null);
  const pendingEle = useRef(null);

  const deleteOutsideClick = useCallback(() => setDeletePopup(false), []);
  const penOutsideClick = useCallback(() => setPendingPopup(false), []);

  useClickOutside(deleteEle, deleteOutsideClick);
  useClickOutside(pendingEle, penOutsideClick);

  const userRole = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();
  // check whether current user has admin roles or not
  const adminOrMadmin = isAdminOrMasteradmin(userRole);
  const wrapperClasses = classnames({
    "checkbox-wrapper": true,
    active: isChecked ? true : false,
  });
  const { internal, external } = fixture.header;
  const userWrapperClass = classnames({
    "user-details": true,
    "hide-details": blocked ? true : false,
  });
  const indicatorClasses = classnames({
    indicator: true,
    internal: capitalize(userType) === internal ? true : false,
    external: capitalize(userType) === external ? true : false,
  });

  const userClickHandler = useCallback(() => {
    redirectToRoute(generatePath(USERS, { id }));
  }, [id]);

  const deleteRestoreUser = useCallback(async (e, type) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const { data } = await UserDataService.deleteUser(id);
      const newUser = new UserData(data);
      dispatch(updateUserList(newUser));
      dispatch(
        setAlertMsg({ type: "success", msg: `User ${type}d successfully` })
      );
    } catch (err) {
      const msg = getErrorMessage(err) || "Error in performing this operation";
      dispatch(setAlertMsg({ type: "error", msg }));
    } finally {
      setLoading(false);
      setDeletePopup(false);
    }
  }, [dispatch, id]);

  const errorSendingEmail = useCallback((err) => {
    const msg = (err && getErrorMessage(err)) || "Error in sending invite";
    dispatch(
      setAlertMsg({
        type: "error",
        msg,
      })
    );
  }, [dispatch]);

  const sendInvite = useCallback(async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const { data } = await UserDataService.sendUserInvite(email);
      const responseData = new EmailConfirmation(data);
      if (responseData.sent) {
        dispatch(
          setAlertMsg({
            type: "success",
            msg: "Invite resent successfully",
          })
        );
      } else {
        errorSendingEmail();
      }
    } catch (err) {
      errorSendingEmail(err);
    } finally {
      setLoading(false);
      setPendingPopup(false);
    }
  }, [dispatch, errorSendingEmail, email]);

  const deleteIconClasses = classnames({
    "delete-icon": true,
    "highlight": deletePopup
  });

  const togglePendingPopup = useCallback((e) => {
    e.stopPropagation();
    setPendingPopup((state) => !state);
  }, []);

  const hidePendingPopup = useCallback((e) => {
    e.stopPropagation();
    setPendingPopup(false);
  }, []);

  const toggleDeletePopup = useCallback((e) => {
    e.stopPropagation();
    setDeletePopup((state) => !state);
  }, []);

  const hideDeletePopup = useCallback((e) => {
    e.stopPropagation();
    setDeletePopup(false);
  }, []);

  return (
    <div
      className="axiom-user-wrapper"
      onClick={userClickHandler}
      key={email}
      data-testid="user-wrapper"
    >
      <div className={userWrapperClass}>
        <div className={wrapperClasses}>
          {adminOrMadmin && !blocked ? (
            <Checkbox
              classname="user-list-cb"
              name={email}
              isChecked={isChecked}
              value={email}
              valueChange={() => setIsChecked((checked) => !checked)}
            />
          ) : null}
        </div>
        {/* Include other roles after API integration */}
        <div className={indicatorClasses}></div>
        <span className="name">{name}</span>
        <span className="email" title={email}>{email}</span>
        <span className="role">{role}</span>
        <span className="inviter">{inviter || "-"}</span>
        {!confirmed && !blocked ? (
          <div className="pending-invitation">
            <span className="circle"></span>
            <span className="text">Invitation Pending</span>
            <div className="resend-btn-wrapper" ref={pendingEle}>
              <Button
                text="Resend"
                click={togglePendingPopup}
                classes="save-button resend"
              />
              {pendingPopup ? (
                <div className="pending-confirmation">
                  <ConfirmationPopup
                    text="Are you sure to send invite again?"
                    acceptLabel="Yes, Send"
                    cancelLabel="Cancel"
                    onAccept={sendInvite}
                    onCancel={hidePendingPopup}
                    isLoading={loading}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <span className="doj">{DOJ}</span>
        )}
      </div>
      {/* displayed only if singed in user role is admin or master-admin */}
      {adminOrMadmin && !blocked ? (
        <div className="icons-wrapper">
          <div className={deleteIconClasses}>
            <ToolTip title="Delete" position="bottom">
              <button
                ref={deleteEle}
                onClick={toggleDeletePopup}
              >
                <Icon name="delete-icon" />
              </button>
            </ToolTip>
          </div>
          <Icon name="users-more-options" classname="more-options" />
        </div>
      ) : null}
      <div className="user-restore-wrapper">
        {adminOrMadmin && blocked ? (
          <div className="user-restore">
            <div className="delete-text">
              <span className="delete-indicator"></span> Deleted
            </div>
            <div>
              <Button
                text="Restore"
                click={(e) => deleteRestoreUser(e, "restore")}
                classes="restore-button"
                disabled={loading}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deletePopup ? (
        <div className="delete-confirmation">
          <ConfirmationPopup
            text="Are you sure to delete this user?"
            acceptLabel="Yes, Delete"
            cancelLabel="Cancel"
            onAccept={(e) => deleteRestoreUser(e, "delete")}
            onCancel={hideDeletePopup}
            isLoading={loading}
          />
        </div>
      ) : null}
    </div>
  );
});

User.type.displayName = "User";

export { User };
