import React from "react";

import { ProjectsContextProvider } from "./projects";
import { FoldersContextProvider } from "./folders";
import { BuildsContextProvider } from "./builds";
import { SearchContextProvider } from "./search";
import { InfoContextProvider } from "./info";
import { ToastContextProvider } from "./toast";
import { PromptContextProvider} from "./installprompt";


const GlobalContexts = ({ children }) => {
  return (
    <ProjectsContextProvider>
      <FoldersContextProvider>
        <BuildsContextProvider>
          <SearchContextProvider>
            <InfoContextProvider>
              <ToastContextProvider>
                <PromptContextProvider>
                  {children}
                </PromptContextProvider>
              </ToastContextProvider>
            </InfoContextProvider>
          </SearchContextProvider>
        </BuildsContextProvider>
      </FoldersContextProvider>
    </ProjectsContextProvider>
  );
};

export default GlobalContexts;