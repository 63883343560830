// @flow

import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  memo
} from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Checkbox, ListContainer, Search, ToolTip } from "components";
import { INTERNAL, EXTERNAL } from "constants.js";
import { guidGenerator, isEmailValidated, uniqueNumber, isEmpty, dateTime, isInternalUser, isTypeInternal, hasExternalMembers, sortByName } from "utils/common";
import { useClickOutside } from "hooks/useClickOutside";
import { searchUsers, searchGroups } from "services/search";

import "./ShareWith.scss";

type Props = {
  type: "users" | "groups" | "builds",
  title: string,
  usersObj?: {
    /** id of the manager: to have the manager selected by default  */
    manager?: number,
    /** id of the build uploader */
    by?: number | null,
    // show a separate row containing members of the group
    selectedGroupMembers?: Array,
  },
  classname?: string,
  /** array containing data */
  selectedData: Array<TList>,
  setSelectedData: (Array<TList>) => void,
  placeholderText: string,
  /** scroll and focus the input */
  scrollAndFocus?: boolean,
  // disables selecting external users & groups containing external users
  internalOnly?: boolean
};

const ShareWith = memo(({
  type,
  title,
  usersObj: {
    manager,
    by,
    selectedGroupMembers = [],
  } = {},
  classname,
  selectedData,
  setSelectedData,
  placeholderText = "Search",
  scrollAndFocus = false,
  internalOnly = false,
}: Props) => {
  const myId = useSelector((state) => state.auth.id);
  const [value, setValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [all, setAll] = useState(false);
  const [external, setExternal] = useState(false);
  const [internal, setInternal] = useState(false);
  const [onlyMe, setOnlyMe] = useState(false);
  const [uniqueID] = useState(guidGenerator());
  const timeOutId = useRef(null);
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const searchListContainer = useRef(null);
  const input = useRef(null);

  const onOutsideClick = useCallback(() => setIsResultsOpen(false), []);

  useClickOutside(searchListContainer, onOutsideClick);

  const isUsers = type === "users";
  const isGroups = type === "groups";
  const isBuilds = type === "builds";

  useEffect(() => {
    if(scrollAndFocus) {
      const ele = input.current;
      if(ele) {
        ele.scrollIntoView({
          behavior: "smooth"
        });
        // focus on the input after the scrolling (~500ms) has stopped
        setTimeout(() => ele.focus(), 500);
      }
    }
  }, [scrollAndFocus]);

  const onChange = useCallback((e) => {
    let val = e.target.value;
    setValue(val);
  }, []);

  const onKeyDown = useCallback(
    (e) => {
      // only applicable for users, and not for groups
      if (e.key === "Enter" && isEmailValidated(value) && isUsers) {
        const type = isInternalUser(value)? INTERNAL: EXTERNAL;

        // ignore if external members are added when internalOnly is true
        if(type === EXTERNAL && internalOnly) {
          return;
        }

        // setting a part of email as name, since there is no name and random id
        const obj = {
          id: uniqueNumber(),
          email: value,
          name: value.split("@")[0],
          selected: true,
          userType: type,
        };

        const updatedData = [...selectedData, obj];
        setSelectedData(updatedData);
        setValue("");
        setIsResultsOpen(false);
      } else {
        return;
      }
    },
    [selectedData, setSelectedData, value, isUsers, internalOnly]
  );

  const filterData = useCallback(
    (data) => {
      if (value) {
        // filter the data received with selected data

        let updatedFilteredData = [];

        for (let i = 0, j = 0; i < data.length; i++) {
          let arrayLength = selectedData.length;

          for (j = 0; j < arrayLength; j++) {
            if (data[i].id === selectedData[j].id) break;
          }

          if (j === arrayLength) {
            updatedFilteredData.push(data[i]);
          }
        }

        setFilteredData(updatedFilteredData);
      }
    },
    [value, selectedData]
  );

  const search = useCallback(() => {
    let id = timeOutId.current;
    if (id) {
      clearTimeout(id);
    }
    if (value) {
      timeOutId.current = setTimeout(async () => {
        let data;
        if (isUsers) {
          ({ data } = await searchUsers(value, { withType: true}));
        } else {
          ({ data } = await searchGroups(value, { withMembers: true }));
        }

        filterData(data);
      }, 500);
    }
  }, [value, filterData, isUsers]);

  const selectAll = useCallback(() => {
    let curState = all;
    setAll((state) => !state);

    // Update all other types
    setInternal(!curState);
    setExternal(!curState);
    setOnlyMe(!curState);

    if(internalOnly && !isUsers) {
      const updatedArray = selectedData.map(group => {
        if(!hasExternalMembers(group.members)) {
          group.selected = !curState;
        }
        return group;
      });
      setSelectedData(updatedArray);
    } else {
      setSelectedData(selectedData.map((data) => ({ ...data, selected: !curState })));
    }

  }, [all, selectedData, setSelectedData, internalOnly, isUsers]);

  const setTheState = useCallback(
    (type) => {
      let curState;
      let isMeIncluded = false;

      if (type === INTERNAL) {
        curState = internal;
        setInternal((state) => !state);
        setExternal(false);
      } else {
        curState = external;
        setExternal((state) => !state);
        setInternal(false);
      }

      const updatedArray = selectedData.map((data) => {
        if (data.userType === type) {
          data.selected = !curState;
          if (data.id === myId) isMeIncluded = true;
        } else {
          data.selected = false;
        }
        return data;
      });

      isMeIncluded && setOnlyMe(true);
      setSelectedData(updatedArray);
    },
    [selectedData, myId, internal, external, setSelectedData]
  );

  const selectExternal = useCallback(() => {
    setTheState(EXTERNAL);
  }, [setTheState]);

  const selectInternal = useCallback(() => {
    setTheState(INTERNAL);
  }, [setTheState]);

  const selectMe = useCallback(() => {
    let curState = onlyMe;
    setOnlyMe((state) => !state);

    setInternal(false);
    setExternal(false);
    setAll(false);

    const updatedArray = selectedData.map((data) => {
      if (data.id === myId) {
        data.selected = !curState;
      } else {
        data.selected = false;
      }
      return data;
    });

    setSelectedData(updatedArray);
  }, [selectedData, myId, onlyMe, setSelectedData]);

  const onValueChange = useCallback((id) => {
    let selectedObj,
      toBeChecked,
      isAllSelected = true,
      isExternalSelected = true,
      isInternalSelected = true,
      isOnlyMeSelected = false;

    const updatedArray = selectedData.map((data) => {
      if (data.id === id) {
        data.selected = !data.selected;
        toBeChecked = data.selected;
        selectedObj = data;
      }

      if (isAllSelected) {
        isAllSelected = isAllSelected && data.selected;
      }

      if (data.userType === EXTERNAL) {
        isExternalSelected = isExternalSelected && data.selected;
      }

      if (data.userType === INTERNAL) {
        isInternalSelected = isInternalSelected && data.selected;
      }

      if (data.id === myId) {
        isOnlyMeSelected = isOnlyMeSelected || data.selected;
      }

      return data;
    });

    setSelectedData(updatedArray);

    const { id: objId, selected, userType } = selectedObj;

    if (toBeChecked) {
      // check external, if all external users are selected
      if (isAllSelected) setAll(true);
      if (isExternalSelected) setExternal(true);
      if (isInternalSelected) setInternal(true);
      if (isOnlyMeSelected) setOnlyMe(true);
    } else {
      // reset selectAll to false, if any item is not selected
      setAll(false);

      //  reset external to false, if item of type external is not selected
      if (external && userType === EXTERNAL && !selected) {
        setExternal(false);
      }

      //  reset internal to false, if item of type internal is not selected
      if (internal && userType === INTERNAL && !selected) {
        setInternal(false);
      }

      // reset onlyMe to false, if item not selected is the current user id
      if (onlyMe && objId === myId) {
        setOnlyMe(false);
      }
    }
  }, [external, internal, myId, onlyMe, selectedData, setSelectedData]);

  useEffect(() => {
    search();
  }, [value, search]);

  const onClear = useCallback(() => {
    setValue("");
    setIsResultsOpen(false);
  }, []);

  const onSelect = useCallback(
    (id) => {
      const data = filteredData.find((item) => item.id === id);
      let updatedData = [
        ...selectedData,
        {
          ...data,
          selected: internalOnly && ((isUsers && !isTypeInternal(data.userType)) || (!isUsers && hasExternalMembers(data.members))) ? false: true
        }
      ];
      setSelectedData(updatedData);
      setValue("");
      setIsResultsOpen(false);
    },
    [filteredData, selectedData, setSelectedData, internalOnly, isUsers]
  );

  const containerClasses = classnames({
    "axiom-share-with-container": true,
    [classname]: classname ? true : false,
    "for-groups": isGroups,
  });

  const typeCheckboxClasses = useCallback((isChecked) =>
    classnames({
      "filter-cb": true,
      "highlight-label": isChecked,
    }), []);

  const onInputFocus = useCallback(() => {
    setIsResultsOpen(true);
  }, []);

  const wrapperFun = useCallback((content, data) => {
    const arr = [];

    if(data) {
      const { emailSent: eS, emailViewed: eV, buildInstalled: bI } = data || {};

      const jsx = (text, val) => (
        <>
          <span className="title">{text}</span><span className="separator">:</span><span className="info">{val ? dateTime(val): "-"}</span>
        </>
      );

      if(isUsers) {
        arr.push(jsx("Mail Sent", eS));
        arr.push(jsx("Email Clicked", eV));
        arr.push(jsx("Build Installed", bI));
      }
    }

    return arr.length ? <ToolTip
      title=""
      position="top"
      classes="text-left"
      displayHTML={!arr.length ? null: arr.map((text, idx) => (
        <div className="arr-name" key={idx}>{text}</div>
      ))}
      displace={true}
    >{content}</ToolTip>: content;
  }, [isUsers]);

  const listItems = [
    <div className="all-checkbox-wrapper">
      {selectedData.map(({ id, name, selected, userType, members, data }) => {
        const condition = id === by || id === manager;
        const propChecked = isUsers && condition ? true : selected;
        const valueChange = !condition ? () => onValueChange(id): () => null;

        return (
          <div className="individual-checkbox" key={id}>
            {wrapperFun(
              <Checkbox
                classname={typeCheckboxClasses(true)}
                name={id}
                isChecked={propChecked}
                value={id}
                labelText={name}
                valueChange={valueChange}
                disable={internalOnly && ((isUsers && !isTypeInternal(userType)) || (!isUsers && hasExternalMembers(members)))}
              />,
              data
            )}
          </div>
        );
      })}
    </div>
  ];
  
  const selectedMembers = {};

  if(isUsers && selectedGroupMembers.length) {
    selectedGroupMembers.map(groups => {
      const { members = [], selected } = groups;
      if(members.length && selected) {
        members.map(mem => {
          selectedMembers[mem.id] = mem;
        });
      }
    });

    if(!isEmpty(selectedMembers)) {
      listItems.push(
        <div className="heading-grp-members">Group Members</div>,
        <div className="all-checkbox-wrapper">
          {[...sortByName(Object.values(selectedMembers)).map(({ id, name, data }) => (
              <div className="individual-checkbox" key={id}>
                {wrapperFun(
                  <Checkbox
                    classname={typeCheckboxClasses(true)}
                    name={`group-mem-${id}`}
                    isChecked={true}
                    value={id}
                    labelText={name}
                    valueChange={null}
                    disable={true}
                  />,
                  data
                )}
              </div>
            ))
          ]}
        </div>
      );
    }
  }

  return (
    <div className={containerClasses}>
      <div className="share-with-wrapper">
        <div className="title-wrapper">
          <span className="title">{title}</span>
          <div className="types">
            <Checkbox
              classname={typeCheckboxClasses(all)}
              name={`all-${uniqueID}`}
              isChecked={all}
              value="all"
              labelText="All"
              valueChange={selectAll}
              boxTyped={true}
              disable={isUsers && internalOnly}
            />

            {isUsers && (
              <Fragment>
                <Checkbox
                  classname={typeCheckboxClasses(external)}
                  name={`external-${uniqueID}`}
                  isChecked={external}
                  value="external"
                  labelText="External"
                  valueChange={selectExternal}
                  boxTyped={true}
                  disable={internalOnly}
                />
                <Checkbox
                  classname={typeCheckboxClasses(internal)}
                  name={`internal-${uniqueID}`}
                  isChecked={internal}
                  value="internal"
                  labelText="Internal"
                  valueChange={selectInternal}
                  boxTyped={true}
                />
                <Checkbox
                  classname={typeCheckboxClasses(onlyMe)}
                  name={`me-${uniqueID}`}
                  isChecked={onlyMe}
                  value="me"
                  labelText="Me"
                  valueChange={selectMe}
                  boxTyped={true}
                />
              </Fragment>
            )}
          </div>
        </div>
        <div className="input-all-checkbox-wrapper">
          { !isBuilds && (
              <div className="input-checkbox-wrapper">
                <div className="search-list-container" ref={searchListContainer}>
                  <Search
                    classname=""
                    search={value}
                    placeholder={placeholderText}
                    onSearch={onChange}
                    onClear={onClear}
                    onInputFocus={onInputFocus}
                    onKeyDown={onKeyDown}
                    inputRef={scrollAndFocus ? input: null}
                  />
                  {value.length > 0 && filteredData.length && isResultsOpen ? (
                    <ListContainer
                      data={filteredData.map(dt => ({...dt, selected: dt.id in selectedMembers}))}
                      type="search-list"
                      onSelect={onSelect}
                    />
                  ) : null}
                </div>
              </div>
            )
          }
          <div className="checkbox-container">
            {listItems}
          </div>
        </div>
      </div>
    </div>
  );
});

ShareWith.type.displayName = "ShareWith";

export { ShareWith };
