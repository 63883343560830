import { SET_ALERT_MSG, CLEAR_ALERT_MSG } from "action-types";

export const setAlertMsg = ({ type, msg }) => {
  return {
    type: SET_ALERT_MSG,
    payload: {
      type,
      msg,
      // by default button text is Close
      btnText: "Close",
      // indicates that the alertpanel is open
      isOpen: true,
    },
  };
};

export const clearAlertMsg = () => {
  return {
    type: CLEAR_ALERT_MSG,
    payload: {
      type: "",
      msg: "",
      btnText: "",
      // indicates that the alertpanel is closed
      isOpen: false,
    },
  };
};
