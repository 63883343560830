// @flow

import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import ReactDOM from "react-dom";
import type { Node } from "react";
import classname from "classnames";
import { getTooltipStyles } from "utils/common";
import "./ToolTip.scss";

type props = {|
  /** tooltip text */
  title: string,
  /** indicates the position of the tooltip */
  position: string,
  /** classes for custom styling */
  classes?: string,
  children: Node,
  /** Array containing html data */
  displayHTML?: Node,
  /** used when the tooltip is clipped by any of its parent elements */
  displace: boolean,
  /** tooltip will be shown only when the content overflows the parent */
  showOnlyOnOverflow: boolean,
|};

const ToolTip = memo(({ title, position, classes = "", children, displayHTML, displace = false, showOnlyOnOverflow = false }: props) => {
  const [isVisible, setState] = useState(false);
  const tooltip = useRef(null);
  const container = useRef(null);
  const [styles, setStyles] = useState({});
  const mount = useRef(document.getElementById("portal-root"));
  const ele = useRef(document.createElement("div"));

  const pasteStyles = useCallback(() => {
    if(isVisible && displace && tooltip?.current && container?.current) {
      const toolTipEle = tooltip.current;
      const containerEle = container.current;

      const el = ele.current;
      const wrapper = mount.current;
      if(wrapper && el) {
        wrapper.appendChild(el);
      }

      const obj = getTooltipStyles({
        position,
        toolTipEle,
        containerEle
      });

      if(displayHTML) {
        // decide the number of columns based on the number of elements (limit is 10)
        obj["grid-template-columns"] = `repeat(${Math.floor(displayHTML.length / 10 + 1)}, 1fr)`;
      }

      setStyles(obj);
    }
  }, [displace, isVisible, position, displayHTML]);

  useEffect(() => {
    pasteStyles();
  }, [pasteStyles]);

  useEffect(() => {
    const el = ele.current;
    const wrapper = mount.current;
    if(el && wrapper && displace && !isVisible) {
      if(el.parentNode === wrapper) {
        wrapper.removeChild(el);
      }
    }
  }, [displace, isVisible]);

  useEffect(() => {
    if(isVisible && showOnlyOnOverflow && tooltip.current) {
      const toolTipEle = tooltip.current;
      const container = toolTipEle.parentElement;
      if(toolTipEle.offsetWidth < container.offsetWidth) {
        setState(false);
      }
    }
  }, [isVisible, showOnlyOnOverflow]);

  const wrapFun = displace ? (children) => ReactDOM.createPortal(children, ele.current) : children => children;

  return (
    <div
      className="container"
      aria-label="data"
      data-testid="tooltip"
      onMouseOver={() => setState(true)}
      onMouseLeave={() => setState(false)}
      ref={container}
    >
      <div>{children}</div>
      {isVisible && wrapFun(
        <div
          className={classname("tooltipContent", position, classes, { "displace": displace })}
          aria-label="title"
          style={styles}
          ref={tooltip}
        >
          {displayHTML ? displayHTML : title}
        </div>
      )}
    </div>
  );
});

ToolTip.type.displayName = "ToolTip";

export { ToolTip };
