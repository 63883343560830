// @flow

import React, { memo } from "react";
import classNames from "classnames";

import "./Input.scss";

type Props = {
  placeholder: string,
  value: string,
  label: string,
  className: string,
  required?: boolean,
  reference?: string,
  type: string,
  id: string,
  name: string,
  disabled: boolean,
  autoComplete: string,
  onChange: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onKeyDown?: () => void,
  onKeyUp?: () => void
};

const Input = memo(({
  placeholder,
  value,
  onChange,
  label,
  className,
  required = false,
  type,
  id,
  name,
  // optional
  disabled = false,
  onFocus,
  onBlur,
  autoComplete,
  reference = null,
  onKeyDown,
  onKeyUp
}: Props) => {
  const contentClassNames = classNames(
    {
      "axiom-input-field": true,
    },
    className
  );

  return (
    <div>
      {label ? (
        <label htmlFor={id} className="axiom-input-label">
          {label}
        </label>
      ) : null}
      <div className={contentClassNames}>
        <input
          className="axiom-input-text"
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={onChange}
          type={type}
          id={id}
          name={name}
          disabled={disabled}
          onFocus={onFocus || null}
          onBlur={onBlur || null}
          autoComplete={autoComplete || "off"}
          onKeyDown={onKeyDown || null}
          onKeyUp={onKeyUp || null}
          ref={reference}
        />
      </div>
    </div>
  );
});

Input.type.displayName = "Input";

export { Input };
