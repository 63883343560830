import React, { useReducer, createContext } from "react";

import { reducer, initialState } from "./reducer";

export const ProjectsContext = createContext();

export const ProjectsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProjectsContext.Provider value={{
      projects: state,
      projectsDispatch: dispatch
    }}>
      {children}
    </ProjectsContext.Provider>
  );
}