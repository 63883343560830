import axios from "axios";

const downloadBuild = ({ link, onProgress, cancelToken}) => {
  return axios.get(link, {
    responseType: "blob",
    onDownloadProgress: onProgress,
    headers: {"Cache-Control": "no-cache"},
    cancelToken
  });
};

export {
  downloadBuild
};