import React, { useReducer, createContext } from 'react';
import { reducer, initialState } from "./reducer";

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToastContext.Provider value={{
      toast: state,
      toastDispatch: dispatch
    }}>
      {children}
    </ToastContext.Provider>
  );
}