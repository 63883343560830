import {
  ADD_USERS_SUCCESS,
  CLEAR_ALL_USERS,
  UPDATE_USERS_SUCCESS,
} from "action-types";

export const addToUserList = (user) => {
  return { type: ADD_USERS_SUCCESS, payload: user };
};

export const updateUserList = (user) => {
  return { type: UPDATE_USERS_SUCCESS, payload: user };
};

export const clearUserList = () => {
  return { type: CLEAR_ALL_USERS };
};
