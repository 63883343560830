export type TList = {
  id: number,
  name: string,
};

export class List {
  +id: number;
  +name: string;

  constructor(raw: Object) {
    this.id = raw.id;
    this.name = raw.name;
  }
}
