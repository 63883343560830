// @flow

import React, { useState, useCallback, useRef, useEffect, memo } from "react";
import {
  Button,
  ConfirmationPopup,
  PillButton,
  ProfilePicture,
  AddTo,
  ToolTip
} from "components";
import classnames from "classnames";
import { useClickOutside } from "hooks/useClickOutside";
import "./AddToList.scss";

type Props = {
  callFunction: (any) => void,
  isEmail?: boolean,
  internalOnly?: boolean,
  noInternal?: boolean,
  dataitems: Array<Object>,
  setItemState: Function,
  title: string,
  userIcon: boolean,
  classname?: string,
  higherRoles?: boolean,
  showDeletePopup?: boolean,
  popupObj?: {
    text: string,
    acceptLabel: string,
    cancelLabel: string,
    isLoading: boolean
  },
  // allow items only to be added and not to be removed
  addOnly?: boolean,
  // on hovering on an element a tooltip with more info about it is shown (the key should be named 'arr' in the array 'dataitems')
  withAdditionalInfo?: boolean,
  // shows the toolTipText passed instead of the default open or close
  toolTipText?: string,
  // callback function is called when the + icon is clicked
  openCallback?: Function,
  // the message will be displayed above the search bar
  showMessageOnAdd ?: string
};

const AddToList = memo(({
  callFunction,
  isEmail,
  internalOnly,
  noInternal,
  dataitems,
  title,
  setItemState,
  userIcon,
  classname,
  higherRoles = false,
  showDeletePopup = false,
  addOnly = false,
  withAdditionalInfo = false,
  popObj: { text, acceptLabel, cancelLabel, isLoading } = {},
  toolTipText = "",
  openCallback = () => null,
  showMessageOnAdd = ""
}: Props) => {
  const [addItem, setAddItem] = useState(false);
  const [deletePopupID, setDeletePopupID] = useState(-1);
  const popupParent = useRef(null);
  const input = useRef(null);
  const popupWrapper = useRef(null);
  const [showMsg, setShowMsg] = useState(false);

  const onOutsideClick = useCallback(() => {
    setDeletePopupID(-1);
  }, []);

  useClickOutside(popupParent, onOutsideClick);

  const addToProjectWrapper = classnames({
    "add-to": true,
    "project-add": !addItem,
    "project-close": addItem,
  });

  const onRemove = useCallback(
    (id) => {
      const selectedItem = dataitems.filter((data) => data.id !== id);
      setItemState(selectedItem);
    },
    [dataitems, setItemState]
  );

  const getRoleBasedProps = useCallback(
    (id) => {
      const props = higherRoles
        ? {
            dataId: id,
            onRemove: !addOnly ? ( !showDeletePopup ? onRemove : () => setDeletePopupID(id) ): null,
            showTooltip: !userIcon,
            tpPos: "top",
            tpText: "Remove"
          }
        : {};
      return props;
    },
    [higherRoles, onRemove, showDeletePopup, addOnly, userIcon]
  );

  const userDetailWrapper = classnames({
    "user-details-project-wrapper": true,
    [classname]: classname ? true: false
  })

  useEffect(() => {
    input.current && input.current.focus();
    if(addItem) {
      openCallback();
      setShowMsg(true);
    } else {
      setShowMsg(false);
    }
  }, [addItem, openCallback]);

  const toolTipClasses = classnames({
    [classname]: classname ? true: false,
    "center-text": addItem
  });

  return (
    <div className={userDetailWrapper}>
      <div className="title-button-wrapper">
        <div className="title-count-wrapper">
          <div className="project-title">{title}</div>
          <div className="project-count">{dataitems && dataitems.length}</div>
        </div>
        {higherRoles || addOnly ? (
          <div className={addToProjectWrapper}>
            <ToolTip
              position="top" 
              title={`${(!addItem && toolTipText) || (!addItem ? "Open": "Close")}`}
              displace={true}
              // Prepend Add-to to the first class added to AddToList for ToolTip
              classes={`Add-to-${toolTipClasses}`}
            >
              <Button
                buttonType="add"
                click={() => setAddItem((state) => !state)}
              />
            </ToolTip>
          </div>
        ) : null}
      </div>
      {showMsg && (
        <div className="msg-wrapper" role="alert">{showMessageOnAdd}</div>
      )}
      {addItem ? (
        <AddTo
          callFunction={callFunction}
          isEmail={isEmail}
          internalOnly={internalOnly}
          noInternal={noInternal}
          selectedData={dataitems}
          setSelectedData={(item) => {
            setAddItem(false);
            setItemState(item);
          }}
          showList={false}
          inputRef={input}
        />
      ) : null}
      {!addItem ? (
        <div className="item-pill-button-wrapper">
          {dataitems.length !== 0 ? (
            dataitems.map(({ id, name, arr = [] }) => {
              const addWrapper = withAdditionalInfo && arr.length ? (children) => (
                <ToolTip
                  title=""
                  position="bottom"
                  displayHTML={!arr.length ? null: arr.map(({name: aName}) => (
                    <div className="arr-name" key={aName}>{aName}</div>
                  ))}
                  displace={true}
                >
                  {children}
                </ToolTip>
              )  :  (children) => children
              return (
                <div
                  key={id}
                  className="list-items-wrapper"
                  ref={deletePopupID === id ? popupParent : null}
                >
                  {userIcon ? (
                    <ToolTip title={name} position="top">
                      <ProfilePicture
                        key={id}
                        username={name}
                        type="profile-icon"
                        {...getRoleBasedProps(id)}
                      />
                    </ToolTip>
                  ) : (
                    addWrapper(
                      <PillButton
                        key={id}
                        text={name}
                        size="md"
                        {...getRoleBasedProps(id)}
                      />
                    )
                  )}
                  {showDeletePopup && deletePopupID === id && (
                    <div className="confirmation-wrapper" ref={popupWrapper}>
                      <ConfirmationPopup
                        text={text}
                        acceptLabel={acceptLabel}
                        cancelLabel={cancelLabel}
                        onAccept={() => onRemove(id)}
                        onCancel={() => setDeletePopupID(-1)}
                        isLoading={isLoading}
                        displaceRefObj={{
                          refEle: popupWrapper,
                          forProfileIcon: userIcon
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="empty-list">
              {userIcon ? (
                <ProfilePicture type="profile-icon" />
              ) : (
                <PillButton text={`No ${title} added`} size="md" />
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
});

AddToList.type.displayName = "AddToList";

export { AddToList };
