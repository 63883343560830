//@flow

import React, { useCallback, useEffect, useState, useContext } from 'react';
import Toggle from 'react-toggle';
import { ToastContext } from 'mobile/data/toast';
import { showToast } from 'mobile/data/toast/action';
import { generatePath, Redirect, useLocation, useParams } from 'react-router-dom';
import { BackButton, BottomBar, MNavBar, MobileHeader } from "mobile/components";
import { MobileProfile } from "models/api/mobileProfile";
import { Button, Icon, ProfilePicture, Loader } from 'components';
import UserDataService from "services/usersService";
import { logOut } from "services/authentication";
import { getErrorMessage, redirectToRoute, isOffline, isInternalUser } from "utils/common";
import { M_CHANGE_PASSWORD, M_EDIT_NAME, M_PROFILE } from "models/routes";
import "react-toggle/style.css";
import "./MProfile.scss";

const initialState = {
  email: "",
  id: -1,
  firstName: "",
  lastName: "",
  role: "",
  notification: false
};

const MProfile = () => {
  const [userInfo, setUserInfo] = useState(initialState);
  const { toastDispatch } = useContext(ToastContext);
  const [disableNotification, setDisableNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const { pathname,  } = useLocation();
  const params = useParams();
  const { type } = params;

  const changePassword = () => redirectToRoute(pathname + M_CHANGE_PASSWORD);
  const editName = () => redirectToRoute(pathname + M_EDIT_NAME);

  const updateNotification = useCallback(async() => {
    try {
      setDisableNotification(true);
      const { data } = await UserDataService.updateNotificationStatus();
      setUserInfo(st => ({...st, ...data}));
      toastDispatch(showToast({
        type: "success",
        msg: "Setting updated successfully"
      }));
    } catch (err) {
      if(!isOffline()) {
        const msg = getErrorMessage(err) || "Error in updating info";
        toastDispatch(showToast({
          type: "error",
          msg
        }));
      }
    } finally {
      setDisableNotification(false);
    }
  }, [toastDispatch]);

  const getUserProfile = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await UserDataService.profileMobile();
      const modifiedData = new MobileProfile(data);
      setUserInfo(modifiedData);
    } catch (err) {
      if(!isOffline()) {
        const msg = getErrorMessage(err) || "Error in getting profile details";
        toastDispatch(showToast({
          type: "error",
          msg
        }));
      }
    } finally {
      setLoading(false);
    }
  }, [toastDispatch]);

  const tryLogOut = useCallback(() => {
    if(!isOffline()) {
      logOut({
        mobileFun: (obj) => toastDispatch(showToast(obj))
      });
    }
  }, [toastDispatch]);

  const click = useCallback(() => {
    const confirm = window.confirm("Are you sure, you want to Log out?");
    if(confirm) {
      tryLogOut();
    }
  }, [tryLogOut]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const { firstName, lastName, email, role, notification } = userInfo;
  const userName = firstName && lastName? `${firstName} ${lastName}`: email[0];

  const content =  userInfo !== initialState ? (
    <>
      <div className="profile-info-wrapper">
        <ProfilePicture
          username={userName}
          type="profile-image"
          classname="large-image"
        />
        <div className="details-wrapper">
          <div className="name">{userName}</div>
          <div className="email">{email}</div>
          <div className="user-type">{role}</div>
        </div>
        <Button click={editName}>
          <Icon name="m-edit-icon" />
        </Button>
      </div>

      <h3 className="sub-heading-text settings">Settings</h3>
      <div className="settings-wrapper">
        <div className="setting">
          <div className="name">Notifications</div>
          <label>
            <Toggle
              icons={false}
              onChange={updateNotification}
              checked={notification}
              disabled={disableNotification}
            />
          </label>
        </div>
        {!isInternalUser(email) && (
          <div className="setting">
            <div className="name">Change Password</div>
            <BackButton click={changePassword} />
          </div>
        )}
      </div>
      <Button classes="log-out" text="Log Out" click={click} />
    </>
  ) : null;

  if(type) {
    return <Redirect to={generatePath(M_PROFILE)} />
  }

  return (
    <>
      <MobileHeader
        showLogo={true}
        withSearch={false}
        centeredText=""
        lastIcon="m-notifications"
      />
      <section className="m-profile-wrapper">
        <h2 className="profile-text">Profile</h2>
        <h3 className="sub-heading-text profile-info">Profile Info</h3>
        {loading ? (
          <div className="observer">
            <Loader className="sm" />
          </div>
        ): content}
        <BottomBar>
          <MNavBar />
        </BottomBar>
      </section>
    </>
  )
};

export { MProfile };