import { AUTH_SUCCESS, AUTH_ERROR } from "action-types";

export const setAuthUser = (obj) => {
  return {
    type: AUTH_SUCCESS,
    payload: obj,
  };
};

export const removeAuthUser = () => {
  return {
    type: AUTH_ERROR,
  };
};
