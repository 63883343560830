import {
  SET_PROMPT_VALUE,
  CLEAR_PROMPT_VALUE
} from "action-types";

export const initialState = {
  prompt: null,
  dismissed: false
};

export function reducer (state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROMPT_VALUE:
      return {...state, prompt: payload};
    case CLEAR_PROMPT_VALUE:
      return {...state, prompt: null, dismissed: true};
    default:
      return state;
  }
}