import http from "http-common";

const create = (data) => {
  return http.post("/users", data);
};

const destroy = (id) => {
  return http.delete(`/users/${id}`);
};

const destroyAll = () => {
  return http.delete(`/users`);
};

const find = () => {
  return http.get("/users");
};

const findOne = (id) => {
  return http.get(`/users/${id}`);
};

const findWithFilters = (filters) => {
  return http.get(`/users/${filters}`);
};

const deleteUser = (id) => {
  return http.put(`/users/${id}/state`);
};

const update = (id, data) => {
  return http.put(`/users/${id}`, data);
};

const getUserRoles = () => {
  return http.get("/users-permissions/custom-roles");
};

const sendUserInvite = (email) => {
  return http.post("/auth/send-email-confirmation", { email });
};

const getMyProfile = () => {
  return http.get("/users/my-profile");
};

const updateMyProfile = (data) => {
  return http.put("/users/my-profile", data);
};

const getPmList = (data) => {
  return http.get(`/users/pm?_q=${data}`);
};

const forgotPassword = (data) => {
  return http.post("/auth/forgot-password", data);
};

const userDetails = (id) => {
  return http.get(`/users/${id}`);
};

const globalSearch = (search) => {
  return http.get(`/global-search?_q=${search}`);
};

const localAuthentication = (data) => {
  return http.post(`/auth/local`, data);
};

const profileMobile = () => {
  return http.get("/users/myself?notification=true");
};

const updateNotificationStatus = () => {
  return http.put("/users/update-notification");
};

const changePassword = data => {
  return http.put("/auth/change-password", data);
};

const getDevices = () => {
  return http.get("/users/devices");
};

const removeDevice = ({id, userId}) => {
  return http.put(`/users/devices/${id}`, { userId });
};

const resetPassword = (data) => {
  return http.post("/auth/reset-password", data);
};

const setPassword = (data) => {
  return http.post("/auth/set-password", data);
};

export default {
  find,
  findOne,
  findWithFilters,
  deleteUser,
  create,
  update,
  destroy,
  destroyAll,
  getUserRoles,
  sendUserInvite,
  getMyProfile,
  updateMyProfile,
  getPmList,
  forgotPassword,
  userDetails,
  globalSearch,
  localAuthentication,
  profileMobile,
  updateNotificationStatus,
  changePassword,
  getDevices,
  removeDevice,
  resetPassword,
  setPassword
};
