// @flow

import React, { memo } from "react";
import type { Node } from "react";
import { Icon } from "components";
import classnames from "classnames";

import "./Button.scss";

type Props = {
  /** onclick function */
  click: Function,
  classes: string,
  /** text for the button */
  text: string,
  /** type of button */
  buttonType: string,
  disabled?: boolean,
  /** use children if the content of button is dom element */
  children?: Node,
};

const Button = memo(({
  click,
  classes,
  text,
  buttonType,
  disabled = false,
  children = null,
}: Props) => {
  const wrapperClasses = classnames({
    "common-button": buttonType === "popup" ? false : true,
    [classes]: true,
  });


  return (
    <div className="button" data-testid="Button">
      {buttonType === "add" ? (
        <>
          <div
            data-testid="button-add"
            className="button-add"
            onClick={click}
          >
            <Icon name="addbutton" />
          </div>
        </>
      ) : (
        <button
          type="button"
          className={wrapperClasses}
          onClick={click}
          data-testid="button"
          disabled={disabled}
        >
          {children ? children : text}
        </button>
      )}
    </div>
  );
});

Button.type.displayName = "Button";

export { Button };
