import {
  ADD_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_SUCCESS,
  MARK_ALL_AS_READ
} from "action-types";

export const addToNotificationList = (notification) => {
  return {
    type: ADD_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const updateNotificationList = (notification) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const markAllAsRead = () => {
  return {
    type: MARK_ALL_AS_READ,
  };
};